package comp

import app.Factory
import comp.input.CompInputText
import comp.input.compInputText
import ein2b.core.core.err
import ein2b.core.coroutine.eLaunch
import ein2b.core.validation.eRuleVali
import ein2b.core.view.*
import org.w3c.dom.HTMLElement
import prop.compInputSection
import prop.compInputSectionString

class CompPageTitle:Comp{
    enum class K{
        nav, wrap, title, back, edit, edit_wrap, edit_btn, edit_input, edit_cancel, edit_submit;
        override fun toString() = if ("_" in name) name.substring(name.lastIndexOf("_") + 1) else name
    }
    class Edit(val cancelLabel:String = "c@취소@", val submitLabel:String = "c@저장@", val vali: eRuleVali, val submit:(v:String)->Unit)
    companion object{
        //language=html
        private val factory = Factory.html("""
<div class="flex-start page-title">
    <div data-view="${K.back}" class="flex-shrink-0 btn image margin-right15 margin-top8">
        <div class="ic-back" style="display:block"></div>
    </div>
    <div class="flex-grow-1">
        <div data-view="${K.wrap}">
            <div data-view="${K.title}"></div>
            <div data-view="${K.edit}.${K.edit_btn}" class="margin-left15 ic-edit cursor" style='min-width:20px'></div>
        </div>        
        <div data-view="${K.edit}.${K.edit_wrap}" class="margin-top4 flex-start margin-bottom4">
            <b data-view="${K.edit}.${K.edit_input}"></b>
            <div data-view="${K.edit}.${K.edit_cancel}" class="margin-left20 btn border"></div>
            <div data-view="${K.edit}.${K.edit_submit}" class="margin-left8 btn"></div>
        </div>    
    </div>
</div>""")

        suspend operator fun invoke(root:eView<HTMLElement>, block:(CompPageTitle)->Unit):CompPageTitle{
            val comp = CompPageTitle()
            block(comp)
            comp.target = root.sub(K.nav, factory){ target->
                target.sub(K.wrap).className = comp.wrapperClass.ifEmpty { "flex-center" }
                target.sub(K.title).className = comp.titleClass.ifEmpty { "h1" }
                target.sub(K.back){ backView->
                    comp.backClick?.also{
                        backView.click = {_,_-> it() }
                        backView.displayBlock()
                    } ?:also{
                        backView.displayNone()
                    }
                }
                target.sub(K.title).html = comp.title
                target.sub(K.edit){ editView->
                    comp.edit?.also{ edit->
                        editView.compInputSectionOneSet<String>(K.edit_input, CompInputText{ comp ->
                            comp.vali = edit.vali
                            comp.inputClass = "width3-1 form-input"
                        })
                        editView.sub(K.edit_btn){
                            it.displayBlock()
                            it.click = {_,_->
                                eLaunch{
                                    target.sub(K.wrap).displayNone()
                                    editView.sub(K.edit_wrap).displayFlex()
                                    editView.sub(K.edit_input).compInputSection().inputView().compInputText().value.inputValue(comp.title)
                                }
                            }
                        }
                        editView.sub(K.edit_wrap).displayNone()
                        editView.sub(K.edit_cancel){
                            it.html = edit.cancelLabel
                            it.click = {_,_->
                                eLaunch{
                                    comp.setEditInit()
                                }
                            }
                        }
                        editView.sub(K.edit_submit){
                            it.html = edit.submitLabel
                            it.click = {_,_->
                                eLaunch{
                                    val compInputTextSection = editView.sub(K.edit_input).compInputSectionString()
                                    if(compInputTextSection.check()) edit.submit(compInputTextSection.out()).also{
                                        target.sub(K.wrap).displayFlex()
                                        editView.sub(K.edit_wrap).displayNone()
                                    }
                                }
                            }
                        }
                    }?:also{
                        editView.sub(K.edit_btn).displayNone()
                        editView.sub(K.edit_wrap).displayNone()
                        editView.sub(K.edit_input)
                        editView.sub(K.edit_cancel)
                        editView.sub(K.edit_submit)
                    }
                }
            }
            comp.baseProp(comp.target)
            return comp
        }
    }
    lateinit var target:eView<HTMLElement>
    var title = ""
    var wrapperClass = ""
    var titleClass = ""
    var backClick:(()->Unit)? = null
    var edit:Edit? = null
    var isEllipsis = false

    suspend fun setTitle(v:String, vararg attrList:Pair<String, String>){
        title = v
        target.sub(K.title){ titleView->
            attrList.forEach{ titleView.attr(it) }
            titleView.html = v
        }
    }
    suspend fun setBack(){
        target.sub(K.back){ backView->
            backClick?.also{
                backView.click = {_,_-> it() }
                backView.displayInlineBlock()
            } ?:also{
                backView.displayNone()
            }
        }
    }
    suspend fun setEditInit(){
        target.sub(K.wrap).displayFlex()
        target.sub(K.edit){ editView->
            editView.sub(K.edit_wrap).displayNone()
            editView.sub(K.edit_input).compInputSectionString().clear()
        }
    }
}

// ============================ prop ============================
inline fun eView<HTMLElement>.compPageTitle(block:(CompPageTitle)->Unit = {}):CompPageTitle{
    val comp = this["compPageTitle"] as? CompPageTitle ?: err("fail to get compPageTitle")
    block(comp)
    return comp
}