package comp.input

import app.Factory
import app.eWindow
import comp.OutType
import comp.input.CompInput.Companion.CONV
import ein2b.core.core.err
import ein2b.core.core.uuid
import ein2b.core.coroutine.eLaunch
import ein2b.core.date.eDateT
import ein2b.core.date.eDateTime
import ein2b.core.validation.eVali
import ein2b.core.view.*
import org.w3c.dom.HTMLElement

class CompInputDate:CompInput<String,String,String>{
    enum class K{
        wrapper, date_wrapper, placeholder, date,
        calendar, calendar_close, calendar_prev, calendar_ym, calendar_next,
        calendar_day_week_list, calendar_day_list
    }
    companion object{
        private val LIST = mutableListOf<CompInputDate>()
        private const val ERROR_CLASS:String = "error"
        private const val SELECTED_CLASS:String = "selected"
        private const val DISABLED_CLASS:String = "disabled"
        //language=html
        private val FACTORY = Factory.html("""
<div data-view="${K.wrapper}">
    <div data-view="${K.date_wrapper}" class="date-wrapper">
        <div class="calendar-icon"></div>
        <div data-view="${K.date}" class="date"></div>
        <div data-view="${K.placeholder}" class="placeholder"></div>
    </div>
    <div data-view="${K.calendar}" class="calendar">
        <div data-view="${K.calendar_close}" class="calendar-close"></div>
        <div class="calendar-header">
            <div data-view="${K.calendar_prev}" class="calendar-prev"></div>
            <span data-view="${K.calendar_ym}" class="h4"></span>
            <div data-view="${K.calendar_next}" class="calendar-next"></div>
        </div>
        <ul data-view="${K.calendar_day_week_list}" class="calendar-day-week-list"></ul>
        <ul data-view="${K.calendar_day_list}" class="calendar-day-list"></ul>
    </div>
</div>""")
        private val dayFactory = Factory.html("""<li data-view=""></li>""")
        private enum class DAY_TYPE{
            NONE{ override fun html(v:String) = v },
            SELECTED{ override fun html(v:String) = """<span class="selected">$v</span>""" },
            TODAY{ override fun html(v:String) = """<span class="today">$v</span>""" },
            TODAY_DISABLED{ override fun html(v:String) = """<span class="today disabled">$v</span>""" },
            DISABLED{ override fun html(v:String) = """<span class="disabled">$v</span>""" },
            OTHER{ override fun html(v:String) = """<span class="other">$v</span>""" },
            OTHER_DISABLED{ override fun html(v:String) = """<span class="other disabled">$v</span>""" };
            open fun html(v:String) = ""
        }
        private fun dateAddMonthLastDay(sDate:eDateTime, addDay:Int = 1) = eDateT.addDay(-1, eDateT.addMonth(addDay, sDate))
        private class DayValue(var value:String, var title:String, var className:String, var dayType: DAY_TYPE, var isDisabled:Boolean)
        operator fun invoke(block:(CompInputDate) -> Unit):CompInputDate{
            val comp = CompInputDate()
            LIST.add(comp)
            block(comp)
            eWindow.addClick(comp.compId){
                eLaunch{ if(comp.isOpen) comp.calendarClose() }
            }
            return comp
        }
    }
    private val compId = uuid("")
    override val outs:HashMap<OutType, suspend () -> String> = hashMapOf(OutType.DEFAULT to { value.value })
    override lateinit var value:CompValue<String, String>
    override var initValue = ""
    override var errorListener:((Boolean, String)->Unit)? = null
    override var vali:eVali? = null
    override val factory:suspend ()->HTMLElement = FACTORY
    override var placeholder = ""
    override var tabIndex = -2
    override suspend fun init(it:eView<HTMLElement>){
        if(tabIndex > -2) it.attr("tabindex", tabIndex)
        target = it
        today = eDateT.part(ymdPattern, eDateT.eDateFactory())
        target.sub(K.wrapper).className = setClassName()
        target.sub(K.date_wrapper).click = { _, _->
            eWindow.currClickId = compId
            if(!isDisabled) eLaunch{
                if(isOpen) calendarClose()
                else{
                    LIST.forEach{ it.calendarClose() }
                    calendarOpen(dateValue())
                }
            }
        }
        target.sub(K.placeholder).html = placeholder
        target.sub(K.date).html = initValue
        target.sub(K.calendar){
            if(isOnlyCurrent) it.className_ = "only-current"
            if(isReverse) it.className_ = "reverse"
            it.displayNone()
            it.click = {e, _ ->
                e.stopImmediatePropagation()
                e.stopPropagation()
            }
        }
        target.sub(K.calendar_close).click = { _, _-> eLaunch{ calendarClose() } }
        target.sub(K.calendar_prev).click = { e, _->
            e.stopImmediatePropagation()
            e.stopPropagation()
            eLaunch{ prev() }
        }
        target.sub(K.calendar_ym)
        target.sub(K.calendar_next).click = { e, _->
            e.stopImmediatePropagation()
            e.stopPropagation()
            eLaunch{ next() }
        }
        target.sub(K.calendar_day_week_list).setList{
            dayWeekList.mapIndexed{ idx, day->
                it += eView(dayFactory){
                    it.html = day
                    it.className = if(idx == 0) "weekend" else ""
                }
            }
        }
        target.sub(K.calendar_day_list)
        value = CompValue("", "", vali, errorListener, CONV){ str->
            selectDate = str
            eLaunch{
                target.sub(K.date).html = str
                target.sub(K.placeholder){ if(str.isBlank()) it.displayBlock() else it.displayNone() }
            }
        }
        if(initValue.isNotBlank()) value.inputValue(initValue)
    }
    override suspend fun error(isOk:Boolean){
        value.isOk = isOk
        target.className = setClassName(if(isOk) "" else ERROR_CLASS)
    }
    override suspend fun clear(){
        error(true)
        enable(true)
        value.inputValue(initValue)
    }

    lateinit var target:eView<HTMLElement>
    var isReverse = false
    var isDisabled = false
    private var wrapperDefaultClass = "input-date"
    var wrapperClass = ""
    var dayWeekList = listOf("일", "월", "화", "수", "목", "금", "토")
    var ymPattern = "Y/m"
    var ymdPattern = "$ymPattern/d(w)"
    var checkBlock:((v:String)->Unit)? = null
    var today = eDateT.part(ymdPattern, eDateT.eDateFactory())
    var selectDate = ""
    private var isOpen = false
    var limitDate = "" //기준 날짜, 이 날짜 이전 날짜는 선택 할수 없음
    val limitDateYm get() = eDateT.part(ymPattern, eDateT.eDateFactory(limitDate))
    var isOnlyCurrent = false //현재 달만 보이게 할껀지

    private suspend fun dateValue():String{
        val v = initValue.ifBlank{ today }
        return target.sub(K.date).html?.ifBlank{ v } ?: v
    }
    private suspend fun prevNext(addMonth:Int){
        isOpen = false
        calendarOpen(eDateT.part(
            ymdPattern,
            eDateT.addMonth(addMonth, eDateT.eDateFactory("${target.sub(K.calendar_ym).html}/01"))
        ))
    }
    private suspend fun prev() = prevNext(-1)
    private suspend fun next() = prevNext(1)
    private suspend fun calendarOpen(calendarYmd:String){
        if(isOpen) return
        isOpen = true

        val ymdDate = eDateT.eDateFactory(calendarYmd)
        val dateYmd = eDateT.part(ymdPattern, eDateT.eDateFactory(dateValue()))
        val daysArr = mutableListOf<DayValue>()

        fun daysArrAdd(sIdx:Int, eIdx:Int, oldDate:eDateTime, isCurrMonth:Boolean = false){
            for(i in sIdx..eIdx){
                val date = eDateT.addDay(i, oldDate)
                val ymd = eDateT.part(ymdPattern, date)
                val isDisabled = limitDate.isNotBlank() && ymd < eDateT.part(ymdPattern, eDateT.eDateFactory(limitDate))
                val dayType = when(ymd){
                    dateYmd -> DAY_TYPE.SELECTED
                    today -> {
                        if(isDisabled) DAY_TYPE.TODAY_DISABLED else DAY_TYPE.TODAY
                    }
                    else -> {
                        if(isDisabled) {
                            if(isCurrMonth) DAY_TYPE.DISABLED else DAY_TYPE.OTHER_DISABLED
                        } else {
                            if(isCurrMonth) DAY_TYPE.NONE else DAY_TYPE.OTHER
                        }
                    }
                }
                var className = ""
                if(isDisabled) className += " disabled"
                if(!isCurrMonth) className += " other"
                daysArr.add(DayValue(ymd, eDateT.part("j", date), className, dayType, isDisabled))
            }
        }
        val year = eDateT.part("Y", ymdDate).toInt()
        val month = eDateT.part("m", ymdDate).toInt()

        val startDate = eDateT.eDateFactory(year, month, 1)
        val sWeek = startDate.dayOfWeek
        if(sWeek > 0) daysArrAdd(sWeek*-1, -1, startDate)

        val endDate = dateAddMonthLastDay(startDate)
        val endDay = eDateT.part("j", endDate).toInt()
        daysArrAdd(0, endDay-1, startDate, true)

        val eWeek = endDate.dayOfWeek
        if(eWeek < 6) daysArrAdd(1, 6-eWeek, endDate)

        val dateYm = eDateT.part(ymPattern, ymdDate)
        target.className = setClassName(SELECTED_CLASS)
        target.sub(K.calendar_ym).html = dateYm
        target.sub(K.calendar_day_list){ view ->
            view.setClearList{ list ->
                daysArr.mapIndexed{ idx, d ->
                    list += eView(dayFactory){
                        val isActive = when {
                            d.isDisabled -> false
                            isOnlyCurrent && (d.dayType == DAY_TYPE.OTHER || d.dayType == DAY_TYPE.OTHER_DISABLED) -> false
                            else -> true
                        }
                        it.html = d.dayType.html(d.title)
                        it.className = "${d.className}${if(idx%7 == 0) " weekend" else ""}"
                        it.click = { e,_->
                            e.stopImmediatePropagation()
                            e.stopPropagation()
                            if(isActive) eLaunch{
                                value.inputValue(d.value)
                                calendarClose()
                                checkBlock?.invoke(d.value)
                            }
                        }
                    }
                }
            }
        }

        var prevIsActive = true
        if(limitDate.isNotBlank()) prevIsActive = limitDateYm < dateYm
        target.sub(K.calendar_prev){
            it.className = "calendar-prev${if(prevIsActive) "" else " disabled"}"
            it.click = { e, _->
                e.stopImmediatePropagation()
                e.stopPropagation()
                if(prevIsActive) eLaunch{ prev() }
            }
        }
        target.sub(K.calendar).displayBlock()
    }
    private suspend fun calendarClose(){
        if(!isOpen) return
        isOpen = false

        target.sub(K.calendar).displayNone()
        if(value.isOk) target.className = setClassName() else error(selectDate.isNotBlank())
    }
    private fun setClassName(cls:String = "") = "$wrapperDefaultClass${if(wrapperClass.isBlank()) "" else " $wrapperClass"}" +
        if(isDisabled) " $DISABLED_CLASS"
        else if(cls.isNotBlank()) " $cls"
        else ""

    fun enable(v:Boolean){
        isDisabled = !v
        target.className = setClassName()
    }
    override suspend fun displayNone() = target.displayNone()
    override suspend fun displayBlock() = target.displayBlock()
    override suspend fun displayInlineBlock() = target.displayInlineBlock()
    override suspend fun displayFlex() = target.displayFlex()
}

// ============================ prop ============================
inline fun eView<HTMLElement>.compInputDate(block:(CompInputDate)->Unit = {}):CompInputDate{
    val comp = this["compInputDate_value"] as? CompInputDate ?: err("fail to get compInputDate")
    block(comp)
    return comp
}