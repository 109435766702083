package ein2b.core.validation

/*
    이전JSON
    ruleset":"trim|string|a@range[1,5]-or-b@first[a]|c@range[1,4]", //mandatory
    "msg":{ //optional
        "@default":"@{cdata.error/rating}", //optional
        "a":"범위가 다름",
        "b":"첫글자가 a가 아님",
        "c":"범위가 다름"
    }

    DSL버전
    val ruleset1 = eRuleSet{
        Case {
            Trim()
            String()
            Range(1.0, 5.0, "범위가 다름")
        }
        Case {
            Rule("첫글자가 a가 아님"){
                if(it is String && it == "a") it else eRuleSet.FALSE
            }
            Range(1.0, 4.0, "범위가 다름")
        }
    }
 */
typealias f = (Any, Any?)->Any
class eRuleSet internal constructor(block:eRuleDSL.()->Unit){
    companion object{
        val FALSE = object{}
    }
    private val caseList = eRuleDSL().apply(block).list
    fun check(v: Any, target:Any?=null):Pair<Boolean, Any>{
        var r = v
        var msg:String? = null
        return if(caseList.any{case->
            r = v
            case.ruleList.all{ (rule, m)->
                r = rule(r, target)
                if(r === FALSE){
                    msg = m
                    false
                }else true
            }
        }) true to r else false to "$msg"
    }
}

class eRuleSetRoute internal constructor(block:eRuleRouteDSL.()->Unit){
    private val caseList = eRuleRouteDSL().apply(block).list
    fun check(v: Any, target:Any?=null):Pair<Boolean, Any>{
        var r = v
        var msg:String? = null
        val case = caseList.find {(breakF, _)->
            breakF(v, target)
        }?.second?:return false to "Not found rule case. target=${target}, v=$v"
        return if(case.ruleList.all{ (rule, m)->
            r = rule(r, target)
            if(r === eRuleSet.FALSE){
                msg = m
                false
            }else true
        }) true to r else false to "$msg"
    }
}