package ein2b.core.date

import kotlin.js.Date

object eDateT: eAbstractDateT() {
    override fun mylog(s: String) {
        console.log(s)
    }

    override fun eDateFactory(): eDateTime {
        return eDateJS(Date())
    }

    override fun eDateFactory(time: Long): eDateTime {
        return eDateJS(Date(time))
    }

    override fun eDateFactory(v: String): eDateTime {
        return eDateJS(Date(v))
    }

    override fun eDateFactory(
        year: Int,
        month: Int,
        date: Int,
        hour: Int,
        minute: Int,
        second: Int,
        millisecond: Int
    ): eDateTime {
        return eDateJS(Date(year, month-1, date, hour, minute, second, millisecond))
    }
}

@Suppress("UnsafeCastFromDynamic")
private class eDateJS(private val jsDate:Date):eDateTime {
    override val year: Int
        get() = jsDate.getFullYear()
    override val month: Int
        get() = jsDate.getMonth() + 1
    override val date: Int
        get() = jsDate.getDate()
    override val dayOfWeek: Int
        get() = jsDate.getDay()
    override val hour: Int
        get() = jsDate.getHours()
    override val minute: Int
        get() = jsDate.getMinutes()
    override val second: Int
        get() = jsDate.getSeconds()
    override val millisecond: Int
        get() = jsDate.getMilliseconds()
    override val time: Long
        get() = jsDate.getTime().toLong()
    override val yearUTC: Int get() = jsDate.getUTCFullYear()
    override val monthUTC: Int get() = jsDate.getUTCMonth() + 1
    override val dateUTC: Int get() = jsDate.getUTCDate()
    override val dayUTC: Int get() = jsDate.getUTCDay()
    override val hourUTC: Int get() = jsDate.getUTCHours()
    override val minuteUTC: Int get() = jsDate.getUTCMinutes()
    override val secondUTC: Int get() = jsDate.getUTCSeconds()
    override val millisecondUTC: Int get() = jsDate.getUTCMilliseconds()
    override val timezoneOffset: Int get() = jsDate.getTimezoneOffset()
    override fun withYear(v: Int): eDateTime {
        val newDate = Date(v,jsDate.getMonth(),jsDate.getDate(),jsDate.getHours(),jsDate.getMinutes(),jsDate.getSeconds())
        return eDateJS(newDate)
    }
    override fun withMonth(v: Int): eDateTime {
        val newDate = Date(jsDate.getFullYear(),v-1,jsDate.getDate(),jsDate.getHours(),jsDate.getMinutes(),jsDate.getSeconds())
        return eDateJS(newDate)
    }
    override fun withDate(v: Int): eDateTime {
        val newDate = Date(jsDate.getFullYear(),jsDate.getMonth(),v,jsDate.getHours(),jsDate.getMinutes(),jsDate.getSeconds())
        return eDateJS(newDate)
    }
    override fun withHour(v: Int): eDateTime {
        val newDate = Date(jsDate.getFullYear(),jsDate.getMonth(),jsDate.getDate(),v,jsDate.getMinutes(),jsDate.getSeconds())
        return eDateJS(newDate)
    }
    override fun withMinute(v: Int): eDateTime {
        val newDate = Date(jsDate.getFullYear(),jsDate.getMonth(),jsDate.getDate(),jsDate.getHours(),v,jsDate.getSeconds())
        return eDateJS(newDate)
    }
    override fun withSecond(v: Int): eDateTime {
        val newDate = Date(jsDate.getFullYear(),jsDate.getMonth(),jsDate.getDate(),jsDate.getHours(),jsDate.getMinutes(),v)
        return eDateJS(newDate)
    }
}