package comp.input

import app.Factory
import ein2b.core.core.err
import ein2b.core.view.*
import org.w3c.dom.HTMLElement

class CompUpDownFile:CompInputFile(){
    companion object{
        //language=html
        private val FACTORY:suspend ()-> HTMLElement = Factory.html("""
        <div data-view="${InputFileKey.wrap}">
            <b data-view="${InputFileKey.input}"></b>
            <a data-view="${InputFileKey.download}" style="display:none"></a>
            <div data-view="${InputFileKey.uploadSection}" class="flex-center btn border">
                <div data-view="${InputFileKey.btnIcon}" class="ic-upload margin-right6"></div><span data-view="${InputFileKey.btnLabel}"></span>
            </div>
            <ul data-view="${InputFileKey.fileList}"></ul>
        </div>
    """.trimIndent())
        operator fun invoke(btnLabel:String, block:(CompUpDownFile)->Unit):CompUpDownFile{
            val comp = CompUpDownFile()
            comp.btnLabel = btnLabel
            block(comp)
            return comp
        }
    }

    override val factory:suspend () -> HTMLElement = FACTORY
    override suspend fun init(it:eView<HTMLElement>){
        val isBtnBlank = btnLabel.isBlank()
        btnClass = "flex-center ${if(isBtnBlank) "cursor" else btnDefaultClass}"
        target = it
        target.sub(InputFileKey.wrap){
            it.attr("data-filekey", key)
            it.className = "${wrapperDefaultClass}${if(wrapperClass.isNotBlank()) " $wrapperClass" else ""}"
        }
        inputSet()
        target.sub(InputFileKey.download)
        target.sub(InputFileKey.uploadSection){
            if(isBtnBlank) it.displayFlex() else it.displayInlineFlex()
            it.className = setClassName()
            it.click = { _,_-> if(!btnIsDisabled) inputFile.template?.click() }
        }
        target.sub(InputFileKey.btnIcon).className = iconClassName
        target.sub(InputFileKey.btnLabel){
            it.html = btnLabel
            if(isBtnBlank) it.displayNone() else it.displayBlock()
        }
    }
    private var wrapperDefaultClass = "input-file"
    suspend fun btnEnable(v:Boolean){
        btnIsDisabled = !v
        target.sub(InputFileKey.uploadSection){
            it.disabled = btnIsDisabled
            it.className = setClassName()
        }
    }
    suspend fun btnDisplay(v:Boolean){
        uploadSectionDisplay(v)
    }
    override suspend fun uploadSectionDisplay(v:Boolean){
        target.sub(InputFileKey.uploadSection){
            if(v) it.displayInlineFlex() else it.displayNone()
        }
    }

    override suspend fun changeValue(){
        clear()
        errorListenerClear?.invoke()
        if(!isChangeSubmit){
            uploadSectionDisplay(false)
            fileSetList()
        }
        if(fileList.isEmpty()) {
            value.inputValue("")
            uploadSectionDisplay(true)
        }
        else if(uploadMaxSize == 1) value.inputValue(fileList[0])
        else value.inputValue(fileList)
    }

}
// ============================ prop ============================
inline fun eView<HTMLElement>.compUpDownFile(block:(CompUpDownFile)->Unit = {}):CompUpDownFile{
    val comp = this["compUpDownFile_value"] as? CompUpDownFile ?: err("fail to get CompUpDownFile_value")
    block(comp)
    return comp
}