package ein2b.core.validation

private val REPLACER:(String, Any)->String = {s,_->s}


class eRuleVali(private val msgReplace:(String, Any)->String = REPLACER , private val block:eRuleDSL.()->Unit): eVali(){
    private var ruleset:eRuleSet? = null
    override fun check(v:Any, target:Any?):Pair<Boolean, Any>{
        if(ruleset == null) ruleset = eRuleSet(block)
        return ruleset!!.check(v, target).run{
            if(first) this else false to msg(msgReplace("$second", v))
        }
    }
}

class eRuleRouteVali(private val msgReplace:(String, Any)->String = REPLACER, private val block:eRuleRouteDSL.()->Unit): eVali(){
    private var ruleset:eRuleSetRoute? = null
    override fun check(v:Any, target:Any?):Pair<Boolean, Any>{
        if(ruleset == null) ruleset = eRuleSetRoute(block)
        return ruleset!!.check(v, target).run{
            if(first) this else false to msg(msgReplace("$second", v))
        }
    }
}
