package comp.input

import app.Factory
import comp.OutType
import ein2b.core.coroutine.eLaunch
import ein2b.core.validation.eVali
import ein2b.core.view.*
import ein2b.js.dom.eEvent
import org.w3c.dom.HTMLElement

abstract class CompInputAddOn<V>:CompInput<String,V,V>{
    companion object{
        private const val CLASS_NAME = "CompInputAddOn"
        private const val WRAPPER = "${CLASS_NAME}_wrapper"
        private const val PREFIX = "${CLASS_NAME}_prefix"
        private const val INPUT = "${CLASS_NAME}_input"
        private const val CLEAR = "${CLASS_NAME}_clear"
        private const val ERROR_CLASS:String = "error"
        private const val SELECTED_CLASS:String = "selected"
        private const val DISABLED_CLASS:String = "disabled"
    }
    override val outs:HashMap<OutType, suspend () -> V> = hashMapOf(OutType.DEFAULT to { value.value })
    override lateinit var value:CompValue<String, V>
    final override var initValue = ""
    final override var errorListener:((Boolean, String)->Unit)? = null
    final override var vali:eVali? = null
    final override var placeholder = ""
    final override var tabIndex = -2
    override val factory:suspend ()-> HTMLElement = Factory.html("""
       <div data-view="$WRAPPER">
            <div data-view="$PREFIX" class="addon-prefix"></div>
            <input data-view="$INPUT" type="$inputType" class="addon-input">
            <div data-view="$CLEAR" class="addon-clear"></div>
       </div> 
    """)
    final override suspend fun init(it:eView<HTMLElement>){
        target = it
        target.sub(WRAPPER).className = wrapperClass
        target.sub(INPUT){
            it.value = ""
            it.placeholder = placeholder
            it.disabled = isDisabled
            it.focus = { _,_-> if(it.disabled == false) eLaunch{ focus() } }
            it.blur = { _,_-> if(it.disabled == false) eLaunch{ blur() } }
            it.keyup = { e,el->
                val ev = eEvent(e, el)
                eLaunch{ changedValue(ev.value, true) }
                if(ev.keycode() == 13) eLaunch{ enterEvent?.invoke(this) }
            }
            it.keydown = { e, el ->
                CompInput.keyDownEvent(maxLength, e, el)
            }
            it.change = { e,el->
                var v = eEvent(e, el).value.trim()
                if(maxLength > -1 && v.length > maxLength) v = v.substring(0, maxLength)
                eLaunch{ changedValue(v) }
            }
        }
        target.sub(PREFIX){
            if(prefixText.isBlank()) it.displayNone()
            else{
                it.displayBlock()
                it.html = prefixText
            }
        }
        target.sub(CLEAR){
            it.displayNone()
            it.click = { _,_->
                eLaunch{ clear() }
            }
        }
        afterTargetInited?.invoke()
    }
    final override suspend fun error(isOk:Boolean){
        value.isOk = isOk
        target.className = setClassName(if(isOk){ if(isFocus) SELECTED_CLASS else "" } else ERROR_CLASS)
    }
    final override suspend fun clear(){
        target.sub(CLEAR).displayNone()
        error(true)
        enable(true)
        value.inputValue("")
    }
    abstract val inputType:String
    abstract var prefixText:String
    abstract var wrapperClass:String
    protected lateinit var target:eView<HTMLElement>
    protected var afterTargetInited:(()->Unit)? = null
    private var isFocus = false
    var maxLength:Int = -1
    var enterEvent:(suspend (CompInputAddOn<V>)->Unit)? = null
    var isChangeCheck = false
    var isClearAddOn = true
    var isDisabled = false
    protected open suspend fun changedValue(v:String, isViewOnly:Boolean = false){
        if(v.isNotBlank() && isClearAddOn) target.sub(CLEAR).displayBlock() else target.sub(CLEAR).displayNone()
        target.value = if(isViewOnly) eViewOnly(v) else v
        value.inputValue(v)
        if(isChangeCheck) eLaunch{ value.check() }
    }
    fun enable(v:Boolean){
        isDisabled = !v
        target.disabled = isDisabled
        if(isFocus) focus() else blur()
    }
    fun focus(){
        isFocus = true
        target.className = setClassName(SELECTED_CLASS)
    }
    fun blur(){
        isFocus = false
        target.className = setClassName(if(value.isOk) "" else ERROR_CLASS)
    }
    suspend fun setInputValue(v:String){ target.sub(INPUT).value = v }
    private fun setClassName(cls:String) = "$wrapperClass ${if(isDisabled) DISABLED_CLASS else cls}"
    override suspend fun displayNone() = target.displayNone()
    override suspend fun displayBlock() = target.displayBlock()
    override suspend fun displayInlineBlock() = target.displayInlineBlock()
    override suspend fun displayFlex() = target.displayFlex()
}
class CompInputTextAddOn:CompInputAddOn<String>(){
    companion object{
        operator fun invoke(block:(CompInputTextAddOn)->Unit):CompInputTextAddOn{
            val comp = CompInputTextAddOn()
            block(comp)
            comp.afterTargetInited = {
                comp.value = CompValue("", "", comp.vali, comp.errorListener, CompInput.CONV) { eLaunch{ comp.setInputValue(it) } }
            }
            return comp
        }
    }
    override val inputType = "text"
    override var prefixText:String = ""
    override var wrapperClass:String = ""
}
class CompInputNumberAddOn:CompInputAddOn<String>(){
    companion object{
        operator fun invoke(block:(CompInputNumberAddOn)->Unit):CompInputNumberAddOn{
            val comp = CompInputNumberAddOn()
            block(comp)
            comp.afterTargetInited = {
                comp.target.attr("min", comp.minValue)
                comp.target.attr("max", comp.maxValue)
                comp.value = CompValue("", "", comp.vali, comp.errorListener, CompInput.CONV) { eLaunch{ comp.setInputValue(it) } }
            }
            return comp
        }
    }
    override val inputType = "number"
    override var prefixText:String = ""
    override var wrapperClass:String = ""
    var minValue:Int = 0
    var maxValue:Int = Int.MAX_VALUE
}