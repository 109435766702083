package comp.input

import app.Factory
import ein2b.core.core.err
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import ein2b.js.dom.eEvent
import org.w3c.dom.HTMLElement

class CompInputNumber:CompInputSingle<String>(){
    companion object{
        operator fun invoke(block:(CompInputNumber)->Unit):CompInputNumber{
            val comp = CompInputNumber()
            block(comp)

            var maxLength = comp.step.split(".").let{
                if(it.size > 1){
                    "${"${comp.maxValue}".split(".")[0]}.${it[1]}".length
                }else "${comp.maxValue}".length
            }
            val isMinus = comp.minValue.toDouble() < 0.0
            if(isMinus) maxLength += 1

            val unitIdx = comp.step.lastIndexOf('.')
            var isDecimal = false //소수점(.) 입력 여부
            val decimalCnt = comp.step.length - unitIdx - 1
            val reg = """^[-.]?[0-9]+\.?[0-9]{0,${decimalCnt}}|.$""".toRegex()

            comp.maxLength = maxLength
            comp.afterTargetInited = {
                comp.target.attr("step", comp.step)
                comp.target.attr("min", comp.minValue)
                comp.target.attr("max", comp.maxValue)
                comp.value = CompValue("", "", comp.vali, comp.errorListener, CompInput.CONV){ comp.target.value = it }
                comp.target.keydown = { e, el ->
                    val ev = eEvent(e, el)
                    val v = ev.value
                    val keyCode = ev.keycode()
                    val keyCodes = CompInput.KEYCODE.toMutableSet()
                    if(isMinus) keyCodes += hashSetOf(109, 189)
                    if(unitIdx > -1){
                        if(keyCode == 110 || keyCode == 190){
                            isDecimal = true
                            comp.maxLength = maxLength
                        }
                        keyCodes += hashSetOf(110, 190)
                    }

                    var isNumber = true
                    if(v.isNotBlank()){
                        //첫째 자리가 아닌 곳에 -가 들어왔다면 입력못하게 막기
                        if(keyCode in hashSetOf(109, 189)) isNumber = false
                        //소수점(.)을 이미 입력했다면 또 입력못하게 막기
                        if(v.indexOf('.') > -1 && keyCode in hashSetOf(110, 190)) isNumber = false
                    }
                    if(
                        !isNumber ||
                        (keyCode !in keyCodes || comp.maxLength > -1) &&
                        ev.value.length >= comp.maxLength &&
                        keyCode !in CompInput.KEYCODE_DEFAULT
                    ){
                        e.stopImmediatePropagation()
                        ev.prevent()
                    }
                    comp.keyDownBlock?.invoke(ev.value)
                }

                if(unitIdx == -1){
                    comp.target.keyup = if(isMinus) comp.minusNumberKeyUpEvent else comp.numberKeyUpEvent
                }else{ //소수점
                    comp.target.keyup = { e, el ->
                        val ev = eEvent(e, el)
                        var v = ev.value
                        //-만 입력하면 value 값이 없는걸로 들어옴. 그래서 value 값이 있을때만 처리하게 수정
                        if(v.isNotBlank()){
                            val vIdx = v.lastIndexOf('.')
                            val isViewOnly = reg.matches(v)
                            if(!isViewOnly){
                                v = "${v.substring(0, vIdx)}.${v.substring(vIdx+1, vIdx+1+decimalCnt)}"
                                if(v == ".") v = ""
                                comp.changedValue("*", false)
                            }
                            comp.changedValue(v, isViewOnly)

                            val maxLength2 = maxLength-1-decimalCnt
                            comp.maxLength = when{
                                isDecimal && v.length < maxLength2 ->{
                                    isDecimal = false
                                    maxLength2
                                }
                                !isDecimal && vIdx == -1 -> maxLength2
                                else-> maxLength
                            }
                        }
                        if(ev.keycode() == 13) eLaunch{ comp.enterEvent?.invoke(comp) }
                        comp.keyUpBlock?.invoke(v)
                    }
                }
            }
            return comp
        }
    }
    override var subKey:String = "CompInputNumber_input"
    override val factory:suspend ()-> HTMLElement = Factory.html("""<input data-view="$subKey" type="number">""")
    var minValue:Number = 0
    var maxValue:Number = Long.MAX_VALUE
    var step = ""
}

// ============================ prop ============================
inline fun eView<HTMLElement>.compInputNumber(block:(CompInputNumber)->Unit = {}):CompInputNumber{
    val comp = this["compInputNumber_value"] as? CompInputNumber ?: err("fail to get compInputNumber")
    block(comp)
    return comp
}