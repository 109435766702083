package ein2b.core.validation

import ein2b.core.core.oneline
import ein2b.core.log.log

class eRuleCase{
    internal val ruleList = mutableListOf<Pair<f, String?>>()
    private val toIntF:f ={it,_->"$it".toInt()}
    private val toLongF:f ={it,_->"$it".toLong()}
    private val toFloatF:f ={it,_->"$it".toFloat()}
    private val toDoubleF:f ={it,_->"$it".toDouble()}
    private val toCharF:f ={it,_->"$it".toCharArray()[0]}
    private val toBooleanF:f ={it,_->"$it".toBoolean()}
    fun toInt(msg:String? = null){ruleList.add(toIntF to msg)}
    fun toLong(msg:String? = null){ruleList.add(toLongF to msg)}
    fun toFloat(msg:String? = null){ruleList.add(toFloatF to msg)}
    fun toDouble(msg:String? = null){ruleList.add(toDoubleF to msg)}
    fun toChar(msg:String? = null){ruleList.add(toCharF to msg)}
    fun toBoolean(msg:String? = null){ruleList.add(toBooleanF to msg)}
    fun toString(msg:String? = null){ruleList.add(STRING to msg)}
    fun Rule(msg:String? = null, rule: f){ruleList += rule to msg}
    private val INT: f = {it,_->if(it is Int) it else eRuleSet.FALSE }
    fun Int(msg:String? = null) = ruleList.add(INT to msg)
    private val LONG: f = {it,_->if(it is Long) it else eRuleSet.FALSE }
    fun Long(msg:String? = null) = ruleList.add(LONG to msg)
    private val FLOAT: f = {it,_->if(it is Float) it else eRuleSet.FALSE }
    fun Float(msg:String? = null) = ruleList.add(FLOAT to msg)
    private val DOUBLE: f = {it,_->if(it is Double) it else eRuleSet.FALSE }
    fun Double(msg:String? = null) = ruleList.add(DOUBLE to msg)
    private val STRING: f = {it,_->if(it is String) it else eRuleSet.FALSE }
    fun String(msg:String? = null) = ruleList.add(STRING to msg)
    private val CHAR: f = {it,_->if(it is Char) it else eRuleSet.FALSE }
    fun Char(msg:String? = null) = ruleList.add(CHAR to msg)
    private val BOOLEAN: f = {it,_->if(it is Boolean) it else eRuleSet.FALSE }
    fun Boolean(msg:String? = null) = ruleList.add(BOOLEAN to msg)
    private val BROADBOOLEAN: f = {it,_->if(it is Boolean) it else if(it is Number || it is String) ("$it".toInt()==1) else eRuleSet.FALSE }
    fun BroadBoolean(msg:String? = null) = ruleList.add(BROADBOOLEAN to msg)
    private val ip = """^(?:(?:[0-9]|(?:1\d{1,2})|(?:2[0-4]\d)|(?:25[0-5]))[.]){3}(?:[0-9]|[1-9][0-9]{1,2}|2[0-4]\d|25[0-5])$""".toRegex()
    private val IP: f = {it,_->if(it is String && it.matches(ip)) it else eRuleSet.FALSE }
    fun Ip(msg:String? = null) = ruleList.add(IP to msg)
    private val url = """^https?://[a-zA-Z0-9.-]+(?:[.]+[A-Za-z]{2,4})+(?:[:]\d{2,4})?""".toRegex()
    private val URL: f = {it,_->if(it is String && it.matches(url)) it else eRuleSet.FALSE }
    fun Url(msg:String? = null) = ruleList.add(URL to msg)
    private val email = """^[0-9a-zA-Z-_.]+@[0-9a-zA-Z-.]+(?:[.]+[A-Za-z]{2,4})+$""".toRegex()
    private val EMAIL: f = {it,_->if(it is String && it.matches(email)) it else eRuleSet.FALSE }
    fun Email(msg:String? = null) = ruleList.add(EMAIL to msg)
    private val korean = """^[\u3131-\uD7A3]+$""".toRegex()
    private val KOREAN: f = {it,_->if(it is String && it.matches(korean)) it else eRuleSet.FALSE }
    fun Korean(msg:String? = null) = ruleList.add(KOREAN to msg)
    private val japanese = """^[\u3041-\u3093\u30A1-\u30F6\u30FC\u4E00-\u9FA0\uFF01-\uFF9F\u30FB\uFF5E\u300C\u300D\u201C\u201D\u2018\u2019\uFF5B\uFF5D\u301C\u2212]+$""".toRegex()
    private val JAPANESE: f = {it,_->if(it is String && it.matches(japanese)) it else eRuleSet.FALSE }
    fun Japanese(msg:String? = null) = ruleList.add(JAPANESE to msg)
    private val lower = """^[a-z]+$""".toRegex()
    private val LOWER: f = {it,_->if(it is String && it.matches(lower)) it else eRuleSet.FALSE }
    fun Lower(msg:String? = null) = ruleList.add(LOWER to msg)
    private val upper = """^[A-Z]+$""".toRegex()
    private val UPPER: f = {it,_->if(it is String && it.matches(upper)) it else eRuleSet.FALSE }
    fun Upper(msg:String? = null) = ruleList.add(UPPER to msg)
    private val num = """^(?:-?(?:0|[1-9]\d*)(?:\.\d+)(?:[eE][-+]?\d+)?)|(?:-?(?:0|[1-9]\d*))$""".toRegex()
    private val NUM: f = {it,_->if(it is String && it.matches(num)) it else eRuleSet.FALSE }
    fun Num(msg:String? = null) = ruleList.add(NUM to msg)
    private val intnum = """^(?:-?(?:0|[1-9]\d*))$""".toRegex()
    private val INTNUM: f = {it,_->if(it is String && it.matches(intnum)) it else eRuleSet.FALSE }
    fun IntNum(msg:String? = null) = ruleList.add(INTNUM to msg)
    private val doublenum = """^(?:-?(?:0|[1-9]\d*)(?:\.\d+)(?:[eE][-+]?\d+)?)$""".toRegex()
    private val DOUBLENUM: f = {it,_->if(it is String && it.matches(doublenum)) it else eRuleSet.FALSE }
    fun DoubleNum(msg:String? = null) = ruleList.add(DOUBLENUM to msg)
    private val lowernum = """^[a-z0-9]+$""".toRegex()
    private val LOWERNUM: f = {it,_->if(it is String && it.matches(lowernum)) it else eRuleSet.FALSE }
    fun LowerNum(msg:String? = null) = ruleList.add(LOWERNUM to msg)
    private val uppernum = """^[A-Z0-9]+$""".toRegex()
    private val UPPERNUM: f = {it,_->if(it is String && it.matches(uppernum)) it else eRuleSet.FALSE }
    fun UpperNum(msg:String? = null) = ruleList.add(UPPERNUM to msg)
    private val alphanum = """^[a-zA-Z0-9]+$""".toRegex()
    private val ALPHANUM: f = {it,_->if(it is String && it.matches(alphanum)) it else eRuleSet.FALSE }
    fun AlphaNum(msg:String? = null) = ruleList.add(ALPHANUM to msg)
    private val alphanumspace = """^[a-zA-Z0-9\s]+$""".toRegex()
    private val ALPHANUMSPACE: f = {it,_->if(it is String && it.matches(alphanumspace)) it else eRuleSet.FALSE }
    fun AlphaNumSpace(msg:String? = null) = ruleList.add(ALPHANUMSPACE to msg)
    private val firstlower = """^[a-z]""".toRegex()
    private val FIRSTLOWER: f = {it,_->if(it is String && it.matches(firstlower)) it else eRuleSet.FALSE }
    fun FirstLower(msg:String? = null) = ruleList.add(FIRSTLOWER to msg)
    private val firstUpper = """^[A-Z]""".toRegex()
    private val FIRSTUPPER: f = {it,_->if(it is String && it.matches(firstUpper)) it else eRuleSet.FALSE }
    fun FirstUpper(msg:String? = null) = ruleList.add(FIRSTUPPER to msg)
    private val uuid = """^[0-9a-z]+$""".toRegex()
    private val UUID: f = {it,_->if(it is String && it.matches(uuid)) it else eRuleSet.FALSE }
    fun Uuid(msg:String? = null) = ruleList.add(UUID to msg)
    private val noblank = """\s""".toRegex()
    private val NOBLANK: f = {it,_->if(it is String && it.matches(noblank)) it else eRuleSet.FALSE }
    fun NoBlank(msg:String? = null) = ruleList.add(NOBLANK to msg)
    private val BLANK: f = {it,_->if(it is String && it.isBlank()) it else eRuleSet.FALSE }
    fun Blank(msg:String? = null) = ruleList.add(BLANK to msg)
    private val EMPTY: f = {it,_->if(it is String && it.isEmpty()) it else eRuleSet.FALSE }
    fun Empty(msg:String? = null) = ruleList.add(EMPTY to msg)
    private val NORULE: f = {it,_->it}
    fun NoRule(msg:String? = null) = ruleList.add(NORULE to msg)
    private val NOTBLANK: f = {it,_->if(it is String && it.isNotBlank()) it else eRuleSet.FALSE }
    fun NotBlank(msg:String? = null) = ruleList.add(NOTBLANK to msg)
    private val TRIM: f = {it,_->if(it is String) it.trim() else eRuleSet.FALSE }
    fun Trim(msg:String? = null) = ruleList.add(TRIM to msg)
    private val ONELINE: f = {it,_->if(it is String) it.trim().oneline() else eRuleSet.FALSE }
    fun OneLine(msg:String? = null) = ruleList.add(ONELINE to msg)
    private fun FIRST(v:String):f = {it,_->if(it is String && "${it[0]}" == v) it else eRuleSet.FALSE }
    fun First(v:String, msg:String? = null) = ruleList.add(FIRST(v) to msg)
    private fun EQUAL(v:Any):f = {it,_-> if(v == it) it else eRuleSet.FALSE }
    fun Equal(v:Any, msg:String? = null) = ruleList.add(EQUAL(v) to msg)
    private fun IN(vararg arg:Any):f = {it,_->if(arg.contains(it)) it else eRuleSet.FALSE }
    fun In(msg:String?, vararg arg:Any) = ruleList.add(IN(*arg) to msg)
    private fun STARTWITH(v:String):f = {it,_->if(it is String && it.startsWith(v)) it else eRuleSet.FALSE }
    fun StartWith(v:String, msg:String? = null) = ruleList.add(STARTWITH(v) to msg)
    private fun ENDWITH(v:String):f = {it,_->if(it is String && it.endsWith(v)) it else eRuleSet.FALSE }
    fun EndWith(v:String, msg:String? = null) = ruleList.add(ENDWITH(v) to msg)
    private fun LENGTH(length:Int):f = {it,_->if(it is String && it.length == length) it else eRuleSet.FALSE }
    fun Length(length:Int, msg:String? = null) = ruleList.add(LENGTH(length) to msg)
    private fun MINLENGTH(length:Int):f = {it,_->if(it is String && it.length >= length) it else eRuleSet.FALSE }
    fun MinLength(length:Int, msg:String? = null) = ruleList.add(MINLENGTH(length) to msg)
    private fun MAXLENGTH(length:Int):f = {it,_->if(it is String && it.length <= length) it else eRuleSet.FALSE }
    fun MaxLength(length:Int, msg:String? = null) = ruleList.add(MAXLENGTH(length) to msg)
    private fun LESSTHAN(v:Double):f = {it,_->if(it is Number && it.toDouble() < v) it else eRuleSet.FALSE }
    fun LessThan(v:Double, msg:String? = null) = ruleList.add(LESSTHAN(v) to msg)
    private fun LESSTHAN(v:String):f = {it,_-> if(it is String && it.toDouble() < v.toDouble()) it else eRuleSet.FALSE }
    fun LessThan(v:String, msg:String? = null) = ruleList.add(LESSTHAN(v) to msg)
    private fun GREATERTHAN(v:Double):f = {it,_->if(it is Number && it.toDouble() > v) it else eRuleSet.FALSE }
    fun GreaterThan(v:Double, msg:String? = null) = ruleList.add(GREATERTHAN(v) to msg)
    private fun GREATERTHAN(v:String):f = {it,_->if(it is Number && it.toDouble() > v.toDouble()) it else eRuleSet.FALSE }
    fun GreaterThan(v:String, msg:String? = null) = ruleList.add(GREATERTHAN(v) to msg)
    private fun RANGE(min:Double, max:Double):f = {it,_->
        if(it is Number && min <= it.toDouble() && it.toDouble() <= max) it
        else if(it is String && min.toInt() <= it.length && it.length <= max.toInt()) it
        else eRuleSet.FALSE
    }
    fun Range(min:Double, max:Double, msg:String? = null) = ruleList.add(RANGE(min, max) to msg)
    private fun NOTZERO():f = {it,_-> if("0" != it.toString()) it else eRuleSet.FALSE }
    fun NotZero(msg:String? = null) = ruleList.add(NOTZERO() to msg)
}