package comp.input

import app.Factory
import comp.OutType
import app.eWindow
import ein2b.core.core.err
import ein2b.core.core.uuid
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import org.w3c.dom.HTMLElement
class CompSelect<V>:CompInputMulti<V, V>(){
    companion object{
        private val LIST = mutableListOf<CompSelect<*>>()
        private const val CLASS_NAME = "CompSelect"
        private const val WRAPPER = "${CLASS_NAME}_wrapper"
        private const val PLACEHOLDER = "${CLASS_NAME}_placeholder"
        private const val SELECT = "${CLASS_NAME}_select"
        private const val ARROW = "${CLASS_NAME}_arrow"
        private const val ITEM_LIST = "${CLASS_NAME}_item_list"
        private const val SELECTED_CLASS = "selected"
        private const val FOCUSED_CLASS = "focused"
        private const val DISABLED_CLASS = "disabled"
        private const val ERROR_CLASS = "error"
        private const val TEXT = "text"
        //language=html
        private val FACTORY:suspend ()-> HTMLElement = Factory.html("""
    <div data-view="$WRAPPER">        
        <div class="selectbox flex-grow-1">
            <div data-view="$WRAPPER.$PLACEHOLDER" class="placeholder"></div>
            <div data-view="$WRAPPER.$SELECT" class="ellipsis"></div>
        </div>        
        <div data-view="$WRAPPER.$ARROW" class="arrow"></div>
        <ul data-view="$WRAPPER.$ITEM_LIST" class="item-list"></ul>
    </div>""")
        private val itemFactory:suspend ()-> HTMLElement = Factory.html("""<li data-view="" class="item-item ellipsis"></li>""")
        private val itemIconFactory:suspend ()-> HTMLElement = Factory.html("""<li data-view="item" class="item-item flex-center flex-between" style="height:35px;padding:0 12px"><div data-view="$TEXT"></div><div data-view="icon" class="select-item-icon"></div></li>""")

        operator fun<V> invoke(block:(CompSelect<V>)->Unit):CompSelect<V>{
            val comp = CompSelect<V>()
            LIST.add(comp)
            block(comp)
            comp.afterTargetInited = {
                comp.target.sub(PLACEHOLDER){
                    it.html = comp.placeholder
                    if(comp.initValue.isBlank()) it.displayBlock() else it.displayNone()
                }
                comp.target.sub(SELECT).html = comp.initValue
                comp.target.sub(ARROW)
                comp.target.sub(ITEM_LIST).className = if(comp.isReverse) "item-list-reverse" else "item-list"
                comp.itemListDisplay()
                comp.target.click = { _,_->
                    eWindow.currClickId = comp.compId
                    if(!comp.isDisabled) eLaunch{
                        if(!comp.isItemListOpen) LIST.forEach{ it.itemListDisplay(false) }
                        comp.itemListDisplay(!comp.isItemListOpen)
                    }
                }
                eWindow.addClick(comp.compId){ eLaunch{ comp.itemListDisplay(false) } }
            }
            return comp
        }
    }
    private val compId = uuid("")
    override val outs:HashMap<OutType, suspend () -> V> = hashMapOf(OutType.DEFAULT to { value.value.first() })
    override var placeholder:String = ""
        set(value){
            eLaunch{ target.sub(PLACEHOLDER).html = value }
            field = value
        }
    override val factory:suspend ()-> HTMLElement = FACTORY
    override suspend fun error(isOk:Boolean){
        value.isOk = isOk
        target.className = setClassName()
    }
    override val subKey:String = WRAPPER
    override var wrapperDefaultClass = "comp-selectbox"
    private var isItemListOpen = false
    private var defaultIdx = -1
    var isDisabled = false
    var checkBlock:((idx:Int)->Unit)? = null
    var initBlock:((idx:Int)->Unit)? = null
    var isReverse = false
    var iconImageUrl = ""
    var iconClickBlock:((idx:Int)->Unit)? = null
    var itemTitleClass = ""
    private var isIcon = false

    suspend fun itemListDisplay(isOpen:Boolean = false){
        isItemListOpen = isOpen
        target.sub(ITEM_LIST){ if(isItemListOpen) it.displayBlock() else it.displayNone() }
        target.className = setClassName()
    }
    public override suspend fun setList(vararg list:Item<V>){
        isIcon = false
        itemList = list
        value.inputValue(listOf())
        if(list.all{ it.isDisabled }) isDisabled = true
        target.className = setClassName()
        target.sub(SELECT).html = initValue
        target.sub(PLACEHOLDER).displayBlock()
        target.sub(ITEM_LIST).setClearList{
            list.forEachIndexed{ idx, item ->
                it += eView(itemFactory){
                    item.view = it
                    item.idx = idx
                    it.html = item.label
                    it.className = setClassName(item)
                    it.click = { e, _ ->
                        e.stopPropagation()
                        e.stopImmediatePropagation()
                        if(!item.isDisabled) eLaunch{
                            list.forEach{ listItem->
                                listItem.isSelect = false
                                listItem.view?.className = setClassName(listItem)
                            }
                            item.isSelect = true
                            it.className = setClassName(item)
                            itemListDisplay()

                            value.inputValue(listOf(item.idx))
                            value.check()
                            checkBlock?.invoke(item.idx)
                        }
                    }
                }
                if(item.isSelect){
                    defaultIdx = item.idx
                    value.inputValue(listOf(item.idx))
                    value.check()
                    initBlock?.invoke(item.idx)
                }
            }
        }
    }
    class IconItem<V>(val item:Item<V>, val iconDisplay:Boolean = false)
    fun<V> iconItem(label:String = "", value:V, isSelect:Boolean=false, isDisabled:Boolean=false, iconDisplay: Boolean = false) = IconItem(item(label, value, isSelect, isDisabled), iconDisplay)
    suspend fun setIconList(vararg list:IconItem<V>){
        isIcon = true
        itemList = list.map { it.item }.toTypedArray()
        value.inputValue(listOf())
        if(list.all{ it.item.isDisabled }) isDisabled = true
        target.className = setClassName()
        target.sub(SELECT).html = initValue
        target.sub(PLACEHOLDER).displayBlock()
        target.sub(ITEM_LIST).setClearList{
            list.forEachIndexed{ idx, iconItem ->
                it += eView(itemIconFactory){ iv ->
                    iconItem.item.idx = idx
                    iv.sub("item") {
                        iconItem.item.view = it
                        it.className = setClassName(iconItem.item)
                        it.click = { e, _ ->
                            e.stopPropagation()
                            e.stopImmediatePropagation()
                            if(!iconItem.item.isDisabled) eLaunch{
                                list.forEach{ listItem->
                                    listItem.item.isSelect = false
                                    listItem.item.view?.className = setClassName(listItem.item)
                                }
                                iconItem.item.isSelect = true
                                it.className = setClassName(iconItem.item)
                                itemListDisplay()

                                value.inputValue(listOf(iconItem.item.idx))
                                value.check()
                                checkBlock?.invoke(iconItem.item.idx)
                            }
                        }
                    }
                    iv.sub(TEXT){
                        it.html = iconItem.item.label
                        it.className = itemTitleClass
                    }
                    iv.sub("icon") {
                        if(iconItem.iconDisplay) it.displayBlock() else it.displayNone()
                        it.lazyBackgroundImage = "" to iconImageUrl
                        it.click = { e, _ ->
                            e.stopPropagation()
                            e.stopImmediatePropagation()
                            iconClickBlock?.invoke(iconItem.item.idx)
                        }
//                        it.mouseover = {_, _ ->
//                            it.html = "삭제"
//                            it.className = "select-item-icon btn"
//                            it.lazyBackgroundImage = "" to ""
//                        }
//                        it.mouseout = {_, _ ->
//                            it.html = ""
//                            it.className = "select-item-icon"
//                            it.lazyBackgroundImage = "" to iconImageUrl
//                        }
                    }
                }
                if(iconItem.item.isSelect){
                    defaultIdx = iconItem.item.idx
                    value.inputValue(listOf(iconItem.item.idx))
                    value.check()
                    initBlock?.invoke(iconItem.item.idx)
                }
            }
        }
    }
    suspend fun setDefault(){
        target.sub(SELECT).html = initValue
        target.sub(PLACEHOLDER).displayBlock()
        itemList.forEach{ listItem->
            listItem.isSelect = defaultIdx == listItem.idx
            listItem.view?.className = setClassName(listItem)
        }
        value.inputValue(if(defaultIdx == -1) listOf() else listOf(defaultIdx))
    }
    suspend fun setInputValue(v:String) {
        target.sub(SELECT).html = v
    }
    private fun setClassName():String = "$wrapperDefaultClass${if(wrapperClass.isBlank()) "" else " $wrapperClass"}" +
            if(isDisabled) " $DISABLED_CLASS"
            else if(isItemListOpen) " $FOCUSED_CLASS"
            else if(value.isOk) ""
            else " $ERROR_CLASS"
    private fun setClassName(item:Item<V>):String{
        if(item.isSelect) eLaunch{
            target.sub(PLACEHOLDER).displayNone()
            target.sub(SELECT).html = item.label
        }
        val prefix = if(isIcon) "item-item flex-center flex-between" else "item-item ellipsis"
        return "$prefix${if(item.isDisabled) " $DISABLED_CLASS" else if(item.isSelect) " $SELECTED_CLASS" else ""}"
    }
}

// ============================ prop ============================
@Suppress("UNCHECKED_CAST")
inline fun<T> eView<HTMLElement>.compSelect(block:(CompSelect<T>)->Unit = { }):CompSelect<T>{
    val comp = this["compSelect_value"] as? CompSelect<T> ?: err("fail to get compSelect with type")
    block(comp)
    return comp
}
inline fun eView<HTMLElement>.compSelect(block:(CompSelect<*>)->Unit = { }):CompSelect<*>{
    val comp = this["compSelect_value"] as? CompSelect<*> ?: err("fail to get compSelect")
    block(comp)
    return comp
}

@Suppress("UNCHECKED_CAST")
inline fun eView<HTMLElement>.compSelectString(block:(CompSelect<String>)->Unit = { }):CompSelect<String>{
    val comp = this["compSelect_value"] as? CompSelect<String> ?: err("fail to get compSelectString")
    block(comp)
    return comp
}

@Suppress("UNCHECKED_CAST")
inline fun eView<HTMLElement>.compSelectInt(block:(CompSelect<Int>)->Unit = { }):CompSelect<Int>{
    val comp = this["compSelect_value"] as? CompSelect<Int> ?: err("fail to get compSelectString")
    block(comp)
    return comp
}
































