package home

import app.Factory
import comp.input.*
import ein2b.core.view.eView

private val factory = Factory.htmlUrl("home")
suspend fun HomeView() = eView(factory){ rootView ->
    CompSelect<Int>{ comp->
        comp.wrapperClass = "width6-1"
        comp.placeholder = "Placeholder text"
        comp.afterInited = {
            comp.setList{
                listOf(
                    comp.item("값1", 1),
                    comp.item("값2", 2)
                )
            }
        }
    }.also{
        it.comp(rootView, "selectbox")
    }
    CompInputText{ comp->
        comp.placeholder = "Placeholder text"
        comp.inputClass = "margin-top20"
    }.also{
        it.comp(rootView, "inputText")
    }
    CompInputPassword{ comp->
        comp.placeholder = "Placeholder text"
        comp.inputClass = "margin-top20"
    }.also{
        it.comp(rootView, "inputPw")
    }

    CompInputTextAddOn{ comp->
        comp.placeholder = "Placeholder text"

    }.also{
        it.comp(rootView, "inputAddOn")
    }


    CompInputDate{ comp->
        comp.wrapperClass = "width6-1"
        comp.placeholder = "Placeholder text"
    }.also{
        it.comp(rootView, "inputDate")
    }
    CompInputTime{ comp->
        comp.placeholder = "Placeholder text"
    }.also{
        it.comp(rootView, "inputTime")
    }
}