package ein2b.core.net

import ein2b.core.core.err
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.Int8Array
import org.khronos.webgl.get
import org.w3c.dom.url.URL
import org.w3c.fetch.Response
import org.w3c.files.File
import org.w3c.files.FileReader
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine

class eResponseFetch(request:eRequest, private val res:Response?, error:String?):eResponse(request, error){
    private var isOpened = false
    override val state by lazy{res?.status?.toInt() ?: 0}
    override fun header(k:String) = res?.headers?.get(k) ?: ""
    override suspend fun text():String? = suspendCoroutine {cont->
        if(isOpened) err("is opened")
        isOpened = true
        res?.text()?.then{
            cont.resume(it)
        } ?: cont.resume(null)
    }
    override suspend fun bytes():ByteArray? = suspendCoroutine { cont->
        if(isOpened) err("is opened")
        isOpened = true
        res?.blob()?.then{
            val fileReader = FileReader()
            fileReader.onload = {
                val r = Int8Array((fileReader.result as ArrayBuffer))
                var i = 0
                val j = r.byteLength
                val bytes = ByteArray(j)
                while(i < j){
                    bytes.set(i, r.get(i))
                    i++
                }
                cont.resume(bytes)
                1
            }
            fileReader.readAsArrayBuffer(it)
        } ?: cont.resume(null)
    }

    override suspend fun any(type: String): Any?  = suspendCoroutine { cont->
        if(isOpened) err("is opened")
        isOpened = true
        when(type){
            "block"->res?.blob()?.then{cont.resume(it)}
            "url"->res?.blob()?.then{cont.resume(URL.createObjectURL(it))}
            "file"->res?.blob()?.then{cont.resume(File(arrayOf(it), ""))}
            else->cont.resume(null)
        }
    }
}