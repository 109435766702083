package app

import ein2b.core.core.err
import ein2b.core.view.router.eDomBase
import kotlinx.browser.document
import org.w3c.dom.HTMLElement

object CompRouter{
    val menuRouterEl = document.querySelector("#menuRouter") as? HTMLElement ?: err("invalid #menuRouter")
    val staticRouterEl = document.querySelector("#staticRouter") as? HTMLElement ?: err("invalid #staticRouter")
    val baseRouterEl = document.querySelector("#baseRouter") as? HTMLElement ?: err("invalid #baseRouter")
    val uiRouterEl = document.querySelector("#uiRouter") as? HTMLElement ?: err("invalid #uiRouter")
    val viewEL = document.querySelector("#view") as? HTMLElement ?: err("invalid #view")

    class CompRouter(el:HTMLElement):eDomBase(el){
        override suspend fun select(){
            menuRouterEl.style.display = "block"
            staticRouterEl.style.display = "none"
            baseRouterEl.style.display = "none"
            uiRouterEl.style.display = "none"
            el.style.display = "block"
            el.style.minWidth = "calc(800px - 200px - 34px)"
        }
        override suspend fun deselect(){
            el.style.display = "none"
        }
    }
    var staticRouter:()->eDomBase = { CompRouter(staticRouterEl) }
    var baseRouter:()->eDomBase = { CompRouter(baseRouterEl) }
    var uiRouter = object:eDomBase(uiRouterEl){
        override suspend fun select(){
            menuRouterEl.style.display = "none"
            uiRouterEl.style.display = "none"
            el.style.display = "block"
        }
        override suspend fun deselect(){
            menuRouterEl.style.display = "block"
            uiRouterEl.style.display = "none"
            el.style.display = "none"
        }
    }
    fun setStaticRouterMinWidth(v:Int){ staticRouterEl.style.minWidth = "${v}px" }
    fun setBaseRouterMinWidth(v:Int){ baseRouterEl.style.minWidth = "${v}px" }
}