package inputDate

import app.PageBase
import ein2b.core.cdata.eCdataLoader
import ein2b.core.entity.eEntity
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import org.w3c.dom.HTMLElement

class InputDateP(router:eRouter<HTMLElement>):PageBase(::InputDateView, router){
    override suspend fun shown(data:eRouterData){
        //language=json
        val cdataText = """{
   "data":{
      "inputDate/A001":{
         "default":"ko",
         "cat":"ln",
         "data":{
            "ko":{
               "value":"타임존 @v0@"
            }
         }
      }
   },
   "cat":{
      "co":"sg",
      "cu":"SGD",
      "ln":"ko"
   },
   "default":{
      "co":"sg",
      "cu":"SGD",
      "ln":"ko"
   }
}""".trimIndent()
        eEntity.parse(eCdataLoader(), cdataText)?.also{
            //log("rsc parse", it.data)
            it.load()
        }
    }
}