package app

import ein2b.core.view.displayNone
import ein2b.core.view.eView
import ein2b.core.view.router.eDomPage
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import ein2b.core.view.scrollY
import entity.EntHook
import m42.model.HcInit
import org.w3c.dom.HTMLElement

abstract class PageBase(factory:suspend ()-> eView<HTMLElement>, router:eRouter<HTMLElement>):eDomPage(factory, router){
    override suspend fun adding(data:eRouterData){}
    override suspend fun restoring(data:eRouterData){}
    override suspend fun renew(){}
    override suspend fun shown(data:eRouterData){
        view?.entity(EntHook)
    }
    override suspend fun hided(){
        view?.apply{
            scrollY = -1
            scrollY = 0.0
            entity(HcInit)
            displayNone()
        }
    }
}