package ein2b.core.entity.field.value

import ein2b.core.entity.Report
import ein2b.core.entity.eEntity

class StringValue(override val entity: eEntity):ValueField<String>(){
    companion object{
        fun v2s(v:Any):String =  "\"${(v as String)
            .replace("\"", "\\\"")
            .replace("\n", "\\n")
            .replace("\r", "\\\\r")}\""
        fun s2v(v:Any):String = (v as String)
            .replace("\\\"", "\"")
            .replace("\\n", "\n")
            .replace("\\r", "\r")
    }
    override fun value(vStr:String, str: String, report: Report): String?{
        return if(vStr.isNotEmpty()){
            report.add("invalid json value(${this.fieldName}):is not string", "field" to this, "vStr" to vStr, "str" to str, "json" to str)
            null
        } else s2v(str)
    }
    override fun toJSON(): String  = v2s(v!!)

    override val useVstr: Boolean get() = false
}