package inputDate

import app.CompApp
import app.Factory
import comp.CompPageTitle
import comp.input.CompInputTimezoneDate
import comp.input.compInputTimezoneDate
import comp.input.compUpDownFile
import comp.input.timeZone
import ein2b.core.coroutine.eLaunch
import ein2b.core.date.einDayJs.timezone
import ein2b.core.view.click
import ein2b.core.view.eEntityHook
import ein2b.core.view.eView
import entity.EntHook
import m42.model.HcInit
import org.w3c.dom.HTMLElement
import prop.compInputSection
import prop.compLabelInputSection

private enum class K{
    input, error, submit,
    view1, view2, view3, view4, view5
}
private val factory = Factory.htmlUrl("inputDate")
suspend fun InputDateView() = eView(factory){ rootView ->
    CompPageTitle(rootView){ compTitle ->
        compTitle.title = "날짜"
    }
    rootView.sub(K.view1){ viewView->
        CompInputTimezoneDate(viewView, K.input){
            it.label = "날짜"
            it.dateBlock = {
                it.ymdPattern = "Y-m-d (w)"
                it.isOnlyCurrent = true
                it.placeholder = "선택해주세요."
            }
            it.infoMsg = "c@타임존@inputDate/A001"
        }
    }
    rootView.sub(K.view2){ viewView->
        CompInputTimezoneDate(viewView, K.input){
            it.label = "날짜/시간"
            it.hasTime = true
            it.dateBlock = {
                it.ymdPattern = "Y-m-d (w)"
                it.isOnlyCurrent = true
                it.placeholder = "선택해주세요."
            }
            it.infoMsg = "c@타임존@inputDate/A001"
        }
    }
    rootView.sub(K.submit).click = {_,_->
        eLaunch{
            console.log(timeZone())
            val comp1 = rootView.sub("${K.view1}.${K.input}").compInputTimezoneDate()
            val comp2 = rootView.sub("${K.view2}.${K.input}").compInputTimezoneDate()
            if(CompApp.checkAll(comp1, comp2)){
                console.log("== 1 ==")
                console.log(comp1.out())
                console.log("== 2 ==")
                console.log(comp2.out())
                console.log("== 3 ==")
            }
        }
    }
    rootView.addEntityHook(EntHook::class, object:eEntityHook<HTMLElement, EntHook>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntHook){

        }
    })
    rootView.addEntityHook(HcInit::class, object:eEntityHook<HTMLElement, HcInit>{
        override suspend fun invoke(view:eView<HTMLElement>, entity: HcInit){

        }
    })
}
