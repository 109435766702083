package app

import ein2b.js.dom.ElementCacheType
import ein2b.js.dom.elementFromHTML
import ein2b.js.dom.elementFromUrl
import org.w3c.dom.HTMLElement

external fun factoryPath():String
object Factory{
    fun html(v:String):suspend()-> HTMLElement = { elementFromHTML(v) }
    fun htmlUrl(url:String, cacheType:ElementCacheType = ElementCacheType.IN_MEMORY):suspend()-> HTMLElement = { elementFromUrl("${factoryPath()}/template/$url.html", cacheType) }
}