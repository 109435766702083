package file

import app.CompApp
import app.Factory
import comp.CompPageTitle
import comp.compInputSectionOneSet
import comp.input.*
import compLabelInputSectionUpDownFileDragSet
import compLabelInputSectionUpDownFileSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.click
import ein2b.core.view.eEntityHook
import ein2b.core.view.eView
import ein2b.core.view.html
import entity.EntHook
import m42.model.HcInit
import org.w3c.dom.HTMLElement
import prop.compInputSection
import prop.compLabelInputSection

private enum class K{
    input, error, submit,
    view1, view2, view3, view4, view5,
    view11, view12
}
private val factory = Factory.htmlUrl("file")
suspend fun FileView() = eView(factory){ rootView ->
    CompPageTitle(rootView){ compTitle ->
        compTitle.title = "파일 업로드/다운로드"
    }
    rootView.sub(K.view1){ viewView->
        CompUpDownFile("파일 업로드"){ comp->
            comp.btnIsDisabled = true
        }.also{
            it.comp(viewView, K.input)
        }
        viewView.sub(K.submit){
            it.html = "버튼 활성/비활성"
            it.click = {_,_->
                eLaunch{
                    rootView.sub("${K.view1}.${K.input}").compUpDownFile{ comp->
                        comp.btnEnable(comp.btnIsDisabled)
                    }
                }
            }
        }
    }
    rootView.sub(K.view2){ viewView->
        val compError = CompError("form-error").also{
            it.comp(viewView, K.error)
        }
        CompUpDownFile("파일 업로드"){ comp->
            comp.wrapperClass = "width2-1"
            comp.acceptExtList = listOf(CompInputFile.AcceptExt.XLSX)
            comp.vali = comp.singleRule("업로드 오류", "용량 오류", 1000000)
            comp.errorListener = { ok,msg->
                eLaunch{
                    comp.error(ok)
                    compError.update(ok, msg)
                }
            }
            comp.errorListenerClear = {
                eLaunch{
                    compError.update(true, "")
                }
            }
        }.also{
            it.comp(viewView, K.input)
        }
    }
    rootView.sub(K.view3){ viewView->
        viewView.compInputSectionOneSet<Any>(
            K.input, CompUpDownFile("파일 업로드"){ comp->
                comp.wrapperClass = "width2-1"
                comp.acceptExtList = comp.acceptExtImage
                comp.errorListenerClear = {
                    eLaunch{
                        rootView.sub("${K.view3}.${K.input}").compInputSection().changeError(true, "")
                    }
                }
                comp.vali = comp.singleRule("업로드 오류", "용량 오류", 1000000)
            }
        )
    }
    rootView.sub(K.view4){ viewView->
        viewView.compLabelInputSectionUpDownFileSet(K.input, "LabelInputSection", "파일 업로드(5장)", sectionWrapperClass = "width2-1"){ comp->
            comp.uploadMaxSize = 5
            comp.errorListenerClear = {
                eLaunch{
                    rootView.sub("${K.view4}.${K.input}").compInputSection().changeError(true, "")
                }
            }
            comp.vali = comp.multiRule("업로드 오류", "갯수 오류", "용량 오류", 1000000)
        }
    }
    rootView.sub(K.view5){ viewView->
        viewView.compLabelInputSectionUpDownFileSet(K.input, "바로 업로드", "파일 업로드", sectionWrapperClass = "width2-1"){ comp->
            comp.btnClass = "link-btn middle"
            comp.changeBlock = {
                eLaunch{
                    if(comp.check()){
                        comp.out()
                        //uploadMaxSize = 1개일 때는 File이 넘어옴
                        //uploadMaxSize > 1개 이상일때는 list(upfile1 to File, upfile2 to File, ...)로 넘어옴
                    }
                }
            }
            comp.errorListenerClear = {
                eLaunch{
                    rootView.sub("${K.view5}.${K.input}").compLabelInputSection().inputSection.changeError(true, "")
                }
            }
            comp.vali = comp.singleRule("업로드 오류", "용량 오류", 1000000)
        }
    }
    rootView.sub(K.submit){
        it.html = "확인"
        it.click = {_,_->
            eLaunch{
                val comp1 = rootView.sub("${K.view1}.${K.input}").compUpDownFile()
                val comp2 = rootView.sub("${K.view2}.${K.input}").compUpDownFile()
                val comp3 = rootView.sub("${K.view3}.${K.input}").compInputSection()
                val comp4 = rootView.sub("${K.view4}.${K.input}").compLabelInputSection()
                if(CompApp.checkAll(comp1, comp2, comp3, comp4)){
                    console.log("== compUpDownFile1.out() ==")
                    console.log(comp1.out())
                    console.log("== compUpDownFile2.out() ==")
                    console.log(comp2.out())
                    console.log("== compUpDownFile3.out() ==")
                    console.log(comp3.inputView().compUpDownFile().out())
                    console.log("== compUpDownFile4.out() ==")
                    console.log(comp4.inputSection.inputView().compUpDownFile().out())
                }
            }
        }
    }
    rootView.sub(K.view11){ viewView->
        CompUpDownFileDrag("업로드할 파일을 드래그해 넣으세요.", "파일 선택"){ comp->
            comp.deleteIcon = "btn image ic-delete"
        }.also{
            it.comp(viewView, K.input)
        }
    }
    rootView.sub(K.view12){ viewView->
        viewView.compLabelInputSectionUpDownFileDragSet(K.input, "드레그 업로드(5개)", "업로드할 파일을 드래그해 넣으세요.", "파일 선택"){ comp->
            comp.uploadMaxSize = 5
            comp.wrapperClass = "flex-grow-1"
        }
    }
    rootView.addEntityHook(EntHook::class, object:eEntityHook<HTMLElement, EntHook>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntHook){

        }
    })
    rootView.addEntityHook(HcInit::class, object:eEntityHook<HTMLElement, HcInit>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:HcInit){

        }
    })
}
