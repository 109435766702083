package app

import ein2b.core.coroutine.eLaunch
import ein2b.core.view.router.eHashManager
import ein2b.core.view.router.eStaticRouter
import file.FileP
import home.HomeP
import inputData.InputDataP
import inputDate.InputDateP
import m42.app.M42App
import number.NumberP
import price.PriceP
import radio.RadioP
import select.SelectP
import text.TextP
import view.CompViewLnb

object RouterKey{
    const val HOME = "home"
    const val INPUT_TEXT = "input_text"
    const val INPUT_FILE = "input_file"
    const val INPUT_PW = "input_pw"
    const val INPUT_NUMBER = "input_number"
    const val INPUT_PRICE = "input_price"
    const val INPUT_SELECT = "input_select"
    const val INPUT_RADIO = "input_radio"
    const val INPUT_CHECKBOX = "input_checkbox"
    const val INPUT_DATA = "input_data"
    const val INPUT_DATE = "input_date"
    const val INPUT_TIME = "input_time"
    const val INPUT_ADD_ON_TEXT = "input_add_on_text"
    const val INPUT_ADD_ON_NUMBER = "input_add_on_number"
    const val VIEW_CALENDAR = "view_calendar"
}
val hashManager = eHashManager(M42App.homeRouterKey, {
    eLaunch{
        CompViewLnb.addHook(it)
    }
}).apply{
    addRouters(
        eStaticRouter(CompRouter.staticRouter()).apply{
            addFactories(
                RouterKey.HOME to { router -> HomeP(router) },
                RouterKey.INPUT_TEXT to { router -> TextP(router) },
                //RouterKey.INPUT_PW to { router -> PwP(router) },
                RouterKey.INPUT_NUMBER to { router -> NumberP(router) },
                RouterKey.INPUT_PRICE to { router -> PriceP(router) },
                RouterKey.INPUT_SELECT to { router -> SelectP(router) },
                RouterKey.INPUT_RADIO to { router -> RadioP(router) },
                //INPUT_CHECKBOX to { router -> HomeP(router) },
                RouterKey.INPUT_DATA to { router -> InputDataP(router) },
                RouterKey.INPUT_DATE to { router -> InputDateP(router) },
                //INPUT_DATE to { router -> HomeP(router) },
                //INPUT_TIME to { router -> HomeP(router) },
                RouterKey.INPUT_FILE to { router -> FileP(router) }
            )
        }
    )
}