package ein2b.core.entity.field.map

import ein2b.core.entity.Report
import ein2b.core.entity.eEntity
import ein2b.core.entity.field.value.StringValue


class StringMap(override val entity: eEntity): MapField<String>() {
    override fun value(vStr:String, str: String, report: Report): String?{
        return if(vStr.isNotEmpty()){
            report.add("invalid json map value:is not string", "field" to this, "vStr" to vStr, "str" to str, "json" to str)
            null
        }else StringValue.s2v(str)
    }
    override fun string(el:Any):String = StringValue.v2s(el)
}