package view

import app.Factory
import ein2b.core.view.*
import org.w3c.dom.HTMLElement

object CompViewAlert{
    lateinit var target:eView<HTMLElement>
    suspend fun init(rootEl:HTMLElement){
        //language=html
        val f = Factory.html("""
<section id="alert" class="fixed-block" style='z-index:1000'>
    <div class="contents">
        <div data-view="msg" class="msg"></div>
        <div class="btn-wrap">
            <div data-view="confirm" class="btn">OK</div>
        </div>
    </div>
</section>
""")
        target = eView(f){
            it.displayNone()
            it.sub("msg")
            it.sub("confirm")
        }
        target.setTemplate()
        rootEl.appendChild(target.template!!)
    }

    private const val CONFIRM_LABEL = "확인"
    suspend fun open(msg:String, confirmLabel:String = "", block:(()->Unit)? = null){
        if(msg.isBlank()) return

        target.displayBlock()
        target.sub("msg").html = msg
        target.sub("confirm"){
            it.html = confirmLabel.ifBlank{ CONFIRM_LABEL }
            it.click = {_,_->
                target.displayNone()
                block?.invoke()
            }
        }
    }
}