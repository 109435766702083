package comp.input

import CompLabelInputSection2
import app.CompApp
import app.Factory
import comp.Comp
import comp.CompValueOut
import comp.OutType
import compLabelInputSection2Set
import ein2b.core.core.err
import ein2b.core.coroutine.eLaunch
import ein2b.core.date.eLocalDateTime
import ein2b.core.date.eUtc
import ein2b.core.date.toUtc
import ein2b.core.validation.eRuleVali
import ein2b.core.view.*
import org.w3c.dom.HTMLElement

class CompInputTimezoneDate:Comp, CompValueOut<eUtc>{
    enum class K{ inputs, info }
    companion object{
        //language=html
        private val FACTORY = Factory.html("""
            <div>
                <b data-view="${K.inputs}"></b>
                <div data-view="${K.info}" class="info-blue"></div>
            </div>
        """.trimIndent())
        suspend operator fun invoke(root:eView<HTMLElement>, subKey:Any, block:(CompInputTimezoneDate) -> Unit):CompInputTimezoneDate{
            val comp = CompInputTimezoneDate()
            block(comp)
            root.sub(subKey, FACTORY){
                comp.target = it

                val compDate = CompInputDate{ compDate->
                    compDate.checkBlock = { eLaunch{ comp.check() } }
                    comp.dateBlock?.invoke(compDate)
                }
                val compTime = CompInputTime{ compTime->
                    compTime.wrapperClass = "margin-left8"
                    compTime.checkBlock = { eLaunch{ comp.check() } }
                    comp.timeBlock?.invoke(compTime)
                }
                comp.compDate = compDate
                comp.compTime = compTime
                comp.compInputSection = it.compLabelInputSection2Set<String>(K.inputs, comp.label, sectionWrapperClass = "", isOneError = true){
                    //"flex margin-top4"
                    if(comp.hasTime) listOf(compDate, compTime) else listOf(compDate)
                }.also{ c->
                    c.out{
                        if(comp.hasTime){
                            val v0 = compDate.out()
                            val v1 = compTime.out()
                            if(v0.isNotBlank() && v1.isNotBlank()) "${v0.replace(".", "-").substring(0, 10)} $v1:00" else ""
                        }else{
                            val v = compDate.out()
                            if(v.isNotBlank()) "${v.replace(".", "-").substring(0, 10)} 00:00:00" else v
                        }
                    }
                }

                it.sub(K.info){
                    it.attr("v0" to comp.timezone)
                    it.html = comp.infoMsg
                }
                comp.baseProp(it, true)
            }
            return comp
        }
    }
    override val outs:HashMap<OutType, suspend () -> eUtc> = hashMapOf(OutType.DEFAULT to {
        val v = compInputSection.out()
        if(v.isNotBlank()){
            val ymd_his = v.split(" ")
            val ymd = ymd_his[0].split("-")
            val his = ymd_his[1].split(":")
            eLocalDateTime(ymd[0].toInt(), ymd[1].toInt(), ymd[2].toInt(), his[0].toInt(), his[1].toInt(), his[2].toInt()).toUtc(timezone)
        }else eUtc.default()
    })
    override suspend fun clear() = compInputSection.clear()
    override fun check() = CompApp.checkAll(compInputSection)

    lateinit var target:eView<HTMLElement>
    fun displayNone() = target.displayNone()
    fun displayBlock() = target.displayBlock()
    fun displayInlineBlock() = target.displayInlineBlock()
    fun displayFlex() = target.displayFlex()

    lateinit var compInputSection:CompLabelInputSection2<String>
    lateinit var compDate:CompInputDate
    lateinit var compTime:CompInputTime
    var label:String = ""
    var hasTime = false
    var vali:eRuleVali? = null
    var dateBlock:((CompInputDate)->Unit)? = null
    var timeBlock:((CompInputTime)->Unit)? = null
    var infoMsg = ""
    var timezone:String = timeZone()
}

// ============================ prop ============================
inline fun eView<HTMLElement>.compInputTimezoneDate(block:(CompInputTimezoneDate)->Unit = {}):CompInputTimezoneDate{
    val comp = this["compInputTimezoneDate_value"] as? CompInputTimezoneDate ?: err("fail to get compInputTimezoneDate")
    block(comp)
    return comp
}
external fun timeZone():String