package ein2b.core.core

val Int.size: Int get() = when {
    this < 0 -> throw IllegalArgumentException("Only positive integer is allowed")
    this < 10 -> 1
    this < 100 -> 2
    this < 1000 -> 3
    this < 10000 -> 4
    this < 100000 -> 5
    this < 1000000 -> 6
    this < 10000000 -> 7
    this < 100000000 -> 8
    this < 1000000000 -> 9
    else -> 10  // Int.MAX = 2147483647 => length 10
}

fun Int.pad0(n:Int):String {
    require(n>0){"This function is useless when n <= 0"}
    require(this>=0){"Only positive integer is allowed"}
    val nDigit = this.size
    return if(n<=nDigit) this.toString() else "${"0".repeat(n-nDigit)}${this}"
}

inline fun Int.pad0_2() = this.toString().let {
    when {
        this < 0 -> it
        this < 10 -> "0"+it
        else -> it
    }
}

inline fun Int.pad0_3() = this.toString().let {
    when {
        this < 0 -> it
        this < 10 -> "00"+it
        this < 100 -> "0"+it
        else -> it
    }
}

inline fun Int.pad0_4() = this.toString().let {
    when {
        this < 0 -> it
        this < 10 -> "000"+it
        this < 100 -> "00"+it
        this < 1000 -> "0"+it
        else -> it
    }
}

val Long.size: Int get() = when {
    this < 0L -> throw IllegalArgumentException("Only positive integer is allowed")
    this < 10L -> 1
    this < 100L -> 2
    this < 1000L -> 3
    this < 10000L -> 4
    this < 100000L -> 5
    this < 1000000L -> 6
    this < 10000000L -> 7
    this < 100000000L -> 8
    this < 1000000000L -> 9
    this < 10000000000L -> 10
    this < 100000000000L -> 11
    this < 1000000000000L -> 12
    this < 10000000000000L -> 13
    this < 100000000000000L -> 14
    this < 1000000000000000L -> 15
    this < 10000000000000000L -> 16
    this < 100000000000000000L -> 17
    this < 1000000000000000000L -> 18
    else -> 19  // Int.MAX = 2147483647 => length 10
}

fun Long.pad0(n:Int):String {
    require(n>0){"This function is useless when n <= 0"}
    require(this>=0L){"Only positive integer is allowed"}
    val nDigit = this.size
    return if(n<=nDigit) this.toString() else "${"0".repeat(n-nDigit)}${this}"
}

inline fun Long.pad0_2() = this.toString().let {
    when {
        this < 0 -> it
        this < 10 -> "0"+it
        else -> it
    }
}

inline fun Long.pad0_3() = this.toString().let {
    when {
        this < 0 -> it
        this < 10 -> "00"+it
        this < 100 -> "0"+it
        else -> it
    }
}

inline fun Long.pad0_4() = this.toString().let {
    when {
        this < 0 -> it
        this < 10 -> "000"+it
        this < 100 -> "00"+it
        this < 1000 -> "0"+it
        else -> it
    }
}
