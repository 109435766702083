package view

import app.Factory
import ein2b.core.coroutine.eLaunch
import ein2b.core.coroutine.eScheduler
import ein2b.core.view.*
import ein2b.js.browser.eLocalStorage
import org.w3c.dom.HTMLElement

object CompViewToast{
    enum class TOAST_TYPE(val className:String){
        DEFAULT("toast"), ERROR("toast error-toast"), SUCCESS("toast success-toast")
    }
    lateinit var ani:eScheduler
    lateinit var target:eView<HTMLElement>
    suspend fun init(rootEl:HTMLElement){
        ani = eScheduler()
        ani.start(15)

        //language=html
        val f = Factory.html("""
<section id="toast" class="toast">
    <div data-view="icon" class="toast-badge"></div>
    <span data-view="msg"></span>
</section>
""")
        target = eView(f){
            it.displayNone()
            it.sub("icon")
            it.sub("msg")
        }
        target.setTemplate()
        rootEl.appendChild(target.template!!)
    }

    suspend fun open(msg:String, iconUrl:String = "", type:TOAST_TYPE = TOAST_TYPE.DEFAULT, vararg attrList:Pair<String, String>){
        if(msg.isBlank()) return

        target.displayFlex()
        target.sub("msg"){t ->
            attrList.forEach { t.attr(it) }
            t.html = msg
        }
        target.sub("icon"){
            if(iconUrl.isBlank()) it.displayNone() else it.displayBlock()
            it.className = "toast-badge $iconUrl"
        }

        target.className = type.className
        target.opacity = 0.8
        ani.add{
            delay = 1000
            once{
                target.opacity = 1.0
                target.transitionEaseOutCirc(0.5)
            }
        } + {
            once{
                target.opacity = 0.0
            }
        } + {
            delay = 500
            once{
                target.transition = ""
                target.displayNone()
            }
        }
    }

    private const val separator = "＆＆"
    private const val localStorageKey = "toastMsg"
    fun setLocalStorage(msg:String? = null, iconUrl:String = "", type:TOAST_TYPE = TOAST_TYPE.DEFAULT, vararg attrList:Pair<String, String>){
        msg?.also{
            eLocalStorage[localStorageKey] = "${it}${separator}${iconUrl}${separator}${type.name}${separator}${attrList.joinToString(separator){ attr-> "${attr.first},${attr.second}" }}"
        } ?: eLocalStorage.remove(localStorageKey)
    }
    fun openLocalStorage() = eLocalStorage[localStorageKey]?.also{
        if(it.isNotBlank()) eLaunch{
            val arr = it.split(separator)
            val size = arr.size
            val msg = arr[0]
            val iconUrl = if(size > 1) arr[1] else ""
            var type = TOAST_TYPE.DEFAULT
            if(size > 2) type = when(arr[2]){
                TOAST_TYPE.ERROR.className-> TOAST_TYPE.ERROR
                TOAST_TYPE.SUCCESS.className-> TOAST_TYPE.SUCCESS
                else-> TOAST_TYPE.DEFAULT
            }
            val attrList = mutableListOf<Pair<String, String>>()
            arr.forEachIndexed{ idx,s->
                if(idx > 2 && s.isNotBlank()){
                    val pair = s.split(',')
                    attrList += pair[0] to pair[1]
                }
            }
            open(msg, iconUrl, type, *attrList.toTypedArray())
            setLocalStorage()
        }
    } ?: ""
}