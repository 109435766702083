package comp.input

import ein2b.core.core.err
import ein2b.core.validation.eVali

class CompValue<IN:Any, V>(
    private var inputValue:IN,
    private var validValue:V? = null,
    private val vali:eVali? = null,
    private var errorListener:((Boolean, String)->Unit)? = null,
    private val converter:(IN)->V,
    private val changedValue:(IN)->Unit
){
    private var isChecked = false
    var isOk = true
    var errorMsg = ""
    fun check():Boolean{
        val result = if(vali == null){
            isChecked = true
            isOk = true
            validValue = converter(inputValue)
            true
        }else{
            isChecked = true
            val (ok, v) = vali.check(inputValue)
            isOk = ok
            if(isOk){
                @Suppress("UNCHECKED_CAST")
                (v as? IN)?.let{
                    validValue = converter(it)
                } ?: err("CompValue:invalid export vali value $v, expected $inputValue")
                true
            }else{
                validValue = null
                errorMsg = "$v"
                false
            }
        }
        errorListener?.invoke(isOk, errorMsg)
        return result
    }
    val value:V get(){
        if(!isChecked) err("CompValue:is Not Checked")
        else if(!isOk) err("CompValue:is Not Ok")
        return validValue!!
    }
    fun inputValue(v:IN){
        isChecked = false
        inputValue = v
        changedValue(inputValue)
    }
}