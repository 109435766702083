package comp.input

import app.Factory
import app.eWindow
import comp.OutType
import ein2b.core.core.uuid
import ein2b.core.coroutine.eLaunch
import ein2b.core.validation.eVali
import ein2b.core.view.*
import ein2b.js.dom.eEvent
import ein2b.js.js.isTruthy
import kotlinx.browser.window
import org.w3c.dom.HTMLElement
import org.w3c.dom.events.Event

external fun matchList(search:String, arr:String, tag:String, attr:String, sortType:String):dynamic
external fun matchWordList(search:String, arr:String, tag:String, attr:String, sortType:String):dynamic

abstract class CompInputDataBase<T>:CompInput<String, String, String>{
    enum class InputDataKey{
        inputWrap, input, clear,
        value,
        listWrap, listEmpty,
        list, list_item, list_search, list_deleteBtn;
        override fun toString() = if ("_" in name) name.substring(name.lastIndexOf("_") + 1) else name
    }
    enum class SortType{ NONE, UNICODE }
    enum class MatchType{ CHAR, WORD }
    open class InputData<T>(val data:T, val value:String, val searchTag:List<Pair<String,Boolean>>, val selectedValue:String = ""){
        val title:String get() = searchTag.joinToString(""){ it.first }
        var searchResultHtml:String = ""
    }
    companion object{
        private const val ERROR_CLASS:String = " error"
        private const val SELECTED_CLASS:String = " selected"
        private const val DISABLED_CLASS:String = " disabled"
        //language=html
        private val dataFactory = Factory.html("""
<li data-view="${InputDataKey.list_item}" class="flex-center" style="position:relative">
    <div data-view="${InputDataKey.list_search}" class="flex-grow-1 ellipsis"></div>            
    <div data-view="${InputDataKey.list_deleteBtn}" class="flex-shrink-0 margin-left6 margin-right10 ic-delete ic-btn"></div>
</li>""".trimIndent())
        class ViewMdl<T>(val comp:CompInputDataBase<T>){
            var className = ""
            var inputValue = ""
            var selectedData:InputData<T>? = null
            
            //하위 클래스의 mdlRender 에서 설정함
            var isSelected = false
            var isListOpen = false

            val html get() = selectedData?.let{ it.selectedValue.ifBlank{ it.title } } ?: ""
            suspend fun init(){
                className = comp.setClassName(SELECTED_CLASS)
                inputValue = ""
                selectedData = null

                //입력창 포커스
                //입력창 초기화
                comp.target.sub(InputDataKey.input){
                    it.value = "-"
                    it.value = ""

                    it.runFocus = false
                    it.runFocus = true
                }
            }
        }
    }
    final override val outs:HashMap<OutType, suspend () -> String> = hashMapOf(OutType.DEFAULT to{ value.value })
    final override lateinit var value:CompValue<String, String>
    final override var initValue = ""
    final override var errorListener:((Boolean, String)->Unit)? = null
    final override var vali:eVali? = null
    final override var placeholder:String = ""
    final override var tabIndex = -2

    override suspend fun error(isOk:Boolean){
        //에러 여부 설정
        value.isOk = isOk
        //에러 여부에 따라 className 변경
        mdl.className = setClassName(if(isOk) "" else ERROR_CLASS)
        mdlRender()
    }
    override suspend fun clear(){
        //view 초기화
        mdl.init()
        value.inputValue("")
        //에러 여부 초기화
        error(true)
    }
    override suspend fun displayNone(){
        target.displayNone()
        clear()
    }
    override suspend fun displayBlock(){ target.displayBlock() }
    override suspend fun displayInlineBlock(){ target.displayInlineBlock() }
    override suspend fun displayFlex(){ target.displayFlex() }

    private val compId = uuid("")
    protected fun addClick(){
        //컴퍼넌트 영역외에 영역을 클릭했을때 포커스 아웃되는 것 처럼 보이게 하기 위해 설정
        eWindow.addClick(compId){ eLaunch{ close() } }
    }
    private suspend fun close(){
        target.sub(InputDataKey.listWrap){
            //컴포넌트		컴포넌트 외 영역 클릭시	리스트 영역 스크롤 최상위로 올리기
            it.scrollY = -1
            it.scrollY = 0.0
            //컴포넌트		컴포넌트 외 영역 클릭시	리스트 영역 displayNone 시키기
            it.displayNone()
        }
    }

    lateinit var target:eView<HTMLElement>
    private var wrapperDefaultClass = "input-data"
    private var inputClass:String = "input"
    private var isFocus = false
    private var isDisabled = false
    var dataList = mutableListOf<InputData<T>>()
    private val stringList:String get() = dataList.fold(""){ acc, curr ->
        acc + """{"value":"${curr.value}", "searchList":[${curr.searchTag.joinToString{ """["${it.first}", ${it.second}]""" }}]}, """
    }.let{
        "[${it.substring(0, it.length - 2)}]"
    }
    protected fun keyUpDebounce(d:Int, f:((String)->Unit)? = null):(Event, HTMLElement)->Unit{
        var id = 0
        return { e, el->
            window.clearTimeout(id)
            id = window.setTimeout({
                eLaunch{
                    val ev = eEvent(e, el)
                    val v = ev.value.trim()
                    //입력 영역	입력창	입력시	mdl.inputValue = {입력값}
                    mdl.inputValue = v
                    changeBlock?.invoke(v)
                    f?.invoke(v)
                    //입력 영역	입력창	입력시	리렌더
                    mdlRender()
                }
            }, d)
        }
    }

    var wrapperClass = ""
    var maxLength:Int = -1
    var sortType = SortType.UNICODE
    var matchType = MatchType.CHAR
    var emptyMsg:String = ""
    var isClickAllDataOpen = false
    var isReverse = false
    var clearClick:(()->Unit)? = null
    var changeBlock:((String)->Unit)? = null
    var itemClickBlock:(suspend (T)->Unit)? = null
    var itemDeleteBlock:(suspend (T)->Unit)? = null

    suspend fun baseInit(it:eView<HTMLElement>){
        isDisabled = false
        mdl.className = setClassName()

        target = it
        target.className = mdl.className

        target.sub(InputDataKey.inputWrap).displayBlock()
        target.sub(InputDataKey.input){ inputView ->
            inputView.className = inputClass
            inputView.placeholder = placeholder
            inputView.click = { _, _->
                eWindow.currClickId = compId
                if(!isDisabled) eLaunch{
                    //입력 영역	입력창	클릭시	mdl.className = 선택(포커스) 색상으로 설정
                    mdl.className = setClassName(SELECTED_CLASS)
                    mdlRender()
                }
            }
            inputView.blur = { _, _->
                if(!isDisabled) eLaunch{
                    mdl.className = setClassName()
                    mdlRender()
                }
            }
            inputView.keyup = keyUpDebounce(300)
            inputView.keydown = { e,el -> CompInput.keyDownEvent(maxLength, e, el) }
        }
        target.sub(InputDataKey.clear){ clearView->
            clearView.displayNone()
            clearView.click = { e, el ->
                eEvent(e,el).prevent()
                e.stopImmediatePropagation()
                eLaunch{
                    //입력 영역	클리어 버튼	클릭시	mdl 초기화
                    //입력 영역	클리어 버튼	클릭시	리렌더
                    mdl.init()
                    clearClick?.invoke()
                    mdlRender()
                }
            }
        }

        target.sub(InputDataKey.listWrap){
            it.className = if(isReverse) "list-wrap-reverse" else "list-wrap"
            it.displayNone()
        }
        target.sub(InputDataKey.list)
        target.sub(InputDataKey.listEmpty).displayNone()
        value = CompValue("", "", vali, errorListener, CompInput.CONV){ target.value = it }
    }
    val mdl = ViewMdl(this)
    protected suspend fun _mdlRender(){
        mdl.isListOpen = false
        val v = mdl.inputValue
        val itemList = mutableListOf<InputData<T>>()
        //리스트 영역	아이템 리스트	모델에 선택값이 있고 dataList가 있는 경우	아래 내용 실행
        if(!mdl.isSelected && dataList.isNotEmpty()){
            if(v.isNotBlank()){
                val matchList = when(matchType){
                    MatchType.CHAR-> matchList(v, stringList, "span", """class="mark"""", sortType.name)
                    MatchType.WORD-> matchWordList(v, stringList, "span", """class="mark"""", sortType.name)
                }
                //리스트 영역	아이템 리스트	데이터 - mdl.inputValue != null	matchList가 있다면 아래 내용 설정
                if(isTruthy(matchList)){
                    val j = (matchList.length as Int) - 1
                    for(i in 0..j){
                        val obj = matchList[i]
                        if(isTruthy(obj) && v.isNotBlank()){
                            val objValue = obj.value as String
                            dataList.find{ d-> d.value == objValue }?.also{ d->
                                //리스트 영역	아이템 리스트	데이터 - mdl.inputValue != null	mdl.dataList.value == matchList.value에 해당하는 데이터를 mdl.itemList에 추가
                                d.searchResultHtml = obj.html as String
                                //리스트 영역	아이템 리스트	데이터 - mdl.inputValue != null	mdl.itemList.searchResultHtml = matchList.html로 설정
                                itemList += d
                            }
                        }
                    }
                }
            }else if(isClickAllDataOpen){
                //리스트 영역	아이템 리스트	데이터 - isClickAllDataOpen == true	mdl.itemList = mdl.dataList로 설정
                itemList.addAll(dataList.map{
                    //리스트 영역	아이템 리스트	데이터 - isClickAllDataOpen == true	mdl.dataList에 searchResultHtml = title로 설정
                    it.searchResultHtml = it.title
                    it
                })
            }
        }
        if(itemList.isNotEmpty()) mdl.isListOpen = true
        //입력 영역	클리어 버튼	표시 조건	mdl.inputValue가 빈값이 아닌 경우
        target.sub(InputDataKey.clear){ if(v.isNotBlank()) it.displayBlock() else it.displayNone() }
        if(emptyMsg.isNotBlank()){
            //리스트 영역	검색 결과 없음	표시 조건	emptyMsg가 빈값이 아닌 경우 && mdl.itemList가 없는 경우
            target.sub(InputDataKey.listEmpty).also{ et ->
                if(itemList.isEmpty()){
                    //리스트 영역	검색 결과 없음	내용	emptyMsg
                    et.attr("v0" to mdl.inputValue)
                    et.html = emptyMsg
                    et.displayBlock()
                    mdl.isListOpen = true
                }else et.displayNone()
            }
        }
        target.sub(InputDataKey.list).setClearList{ listView ->
            itemList.forEach{ d ->
                listView += eView(dataFactory){ dataView ->
                    dataView.sub(InputDataKey.list_item){
                        it.click = {e,_->
                            e.stopPropagation()
                            e.stopImmediatePropagation()
                            eLaunch{
                                listItemSelected(d)
                                itemClickBlock?.invoke(d.data)
                                mdlRender()
                            }
                        }
                    }
                    dataView.sub(InputDataKey.list_search){
                        //리스트 영역	아이템 리스트 - 아이템	내용	searchResultHtml
                        it.value = d.data
                        it.html = d.searchResultHtml
                    }
                    dataView.sub(InputDataKey.list_deleteBtn){ deleteView->
                        //리스트 영역	아이템 리스트 - 아이템	X 버튼 - 표시 조건	itemDeleteBlock가 설정된 경우
                        itemDeleteBlock?.also{
                            deleteView.displayBlock()
                            deleteView.click = { e,_->
                                e.stopPropagation()
                                e.stopImmediatePropagation()
                                eLaunch{
                                    //리스트 영역	아이템 리스트 - 아이템	X 버튼 - 클릭시	itemDeleteBlock를 호출 후 에러가 없을 경우 아래 내용 실행
                                    it(d.data).also{
                                        //리스트 영역	아이템 리스트 - 아이템	X 버튼 - 클릭시	mdl.dataList에서 제거
                                        dataList.find{ it.data == d.data }?.also{ dataList.remove(it) }
                                        //리스트 영역	아이템 리스트 - 아이템	X 버튼 - 클릭시	리렌더
                                        mdlRender()
                                    }
                                }
                            }
                        }?:also{
                            deleteView.displayNone()
                        }
                    }
                }
            }
        }
        target.className = mdl.className
    }
    abstract suspend fun listItemSelected(d:InputData<T>)
    abstract suspend fun mdlRender()
    private fun setClassName(cls:String = "") = "${wrapperDefaultClass}${if(wrapperClass.isNotBlank()) " $wrapperClass" else ""}${if(isDisabled) " $DISABLED_CLASS" else if(cls.isNotBlank()) " $cls" else ""}"
}