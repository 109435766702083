package m42.common.api

import ein2b.core.entity.eEntity
import ein2b.core.entity.eEntityUnion

sealed class EntM42ApiResMember: eEntity(true){
    companion object: eEntityUnion<EntM42ApiResMember>(EntM42ApiResMember::Login, EntM42ApiResMember::Logout)
    class Login: EntM42ApiResMember(){
        //memberRowid, userId, data 외에는 건드리면 안됩니다. (필요한 변수가 생기면 data을 이용해야 합니다)
        var memberRowid:Long = 0L
        var userId by string() //userId
        var data by stringMap{ default(mutableMapOf(), false) }
    }
    class Logout: EntM42ApiResMember(){
        var none by string{ default("", false) }
    }
}