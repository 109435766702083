package comp.input

import CompLabelInputSection
import app.Factory
import ein2b.core.core.err
import ein2b.core.coroutine.eLaunch
import ein2b.core.validation.eVali
import ein2b.core.view.*
import ein2b.js.dom.eEvent
import org.w3c.dom.HTMLElement

class CompInputDataMandatory<T>:CompInputDataBase<T>(){
    enum class K{
        listAdd, selectedValue, refresh;
        override fun toString() = if ("_" in name) name.substring(name.lastIndexOf("_") + 1) else name
    }
    companion object{
        //language=html
        private val FACTORY = Factory.html("""
<div class="input-data">
    <div data-view="${InputDataKey.inputWrap}" class="input-value input-clear">
        <input data-view="${InputDataKey.input}" class="input" type="text">
        <div data-view="${InputDataKey.clear}" class="btn-clear"></div>
    </div>
    <div data-view="${K.selectedValue}" class="input-value value-refresh">
        <div data-view="${InputDataKey.value}" class="value ellipsis"></div>
        <div data-view="${K.refresh}" class="btn-refresh"></div>
    </div>
    <div data-view="${InputDataKey.listWrap}" class="list-wrap">
        <div data-view="${K.listAdd}" class="link-btn-text add-btn"></div>
        <div data-view="${InputDataKey.listEmpty}" style="padding:10px 10px;font-size:12px"></div>
        <ul data-view="${InputDataKey.list}" class="data-list"></ul>
    </div>
</div>""")
        operator fun <T> invoke(block:(CompInputDataMandatory<T>)->Unit):CompInputDataMandatory<T>{
            val comp = CompInputDataMandatory<T>()
            block(comp)
            comp.addClick()
            return comp
        }
    }
    override val factory = FACTORY
    override suspend fun init(it:eView<HTMLElement>){
        baseInit(it)
        target.sub(K.selectedValue).displayNone()
        target.sub(InputDataKey.value).html = ""
        target.sub(K.refresh).click = { e, el ->
            eEvent(e, el).prevent()
            e.stopImmediatePropagation()
            eLaunch{
                //선택 영역	리프레쉬 버튼	아이콘	동그란 화살표
                //선택 영역	리프레쉬 버튼	클릭시	초기화
                mdl.init()
                refreshClick?.invoke()
                //선택 영역	리프레쉬 버튼	클릭시	리렌더
                mdlRender()

                //input 창이 displayBlock 된 다음에 포커스를 줘야해서 아래에서 한번 더 하는것임
                target.sub(InputDataKey.input){
                    it.runFocus = false
                    it.runFocus = true
                }
            }
        }
        target.sub(K.listAdd){ addView ->
            addView.displayNone()
            addView.click = { e, el ->
                eEvent(e, el).prevent()
                e.stopImmediatePropagation()
                addEvent?.also{
                    addView.value?.also{ v->
                        eLaunch{
                            //리스트 영역	추가 버튼 - addEvent가 설정된 경우	클릭시	addEvent를 호출 후 에러가 없을 경우 아래 내용 실행
                            it("$v").also{
                                //리스트 영역	추가 버튼 - addEvent가 설정된 경우	클릭시	mdl.dataList에 추가
                                dataList += it
                                //리스트 영역	추가 버튼 - addEvent가 설정된 경우	클릭시	리렌더
                                mdlRender()
                            }
                        }
                    }
                }
            }
        }
        if(initValue.isNotBlank()) dataList.find{ it.value == initValue }?.also{
            mdl.selectedData = it
            value.inputValue(it.value)
            mdlRender()
        }
    }
    var refreshClick:(()->Unit)? = null
    //addEvent가 null이 아니면 add 표시
    var addEvent:(suspend (String)->InputData<T>)? = null
    var addMsg:String = ""
    override suspend fun listItemSelected(d:InputData<T>){
        //리스트 영역	아이템 리스트 - 아이템	클릭시	mdl.inputValue 빈값으로 설정
        mdl.inputValue = ""
        //리스트 영역	아이템 리스트 - 아이템	클릭시	mdl.selectedData에 해당 아이템으로 설정
        mdl.selectedData = d
        //errorListener?.invoke(true, "")
        value.inputValue(d.value)
    }
    override suspend fun mdlRender(){
        mdl.isSelected = mdl.selectedData != null
        _mdlRender()
        //입력 영역		표시 조건	mdl.selectedData가 설정 안 된 경우
        target.sub(InputDataKey.inputWrap){
            if(mdl.isSelected) it.displayNone() else it.displayBlock()
        }
        //선택 영역	선택값	내용	mdl.selectedData.selectedValue가 있으면 selectedValue 없으면 title
        target.sub(InputDataKey.value).html = mdl.html

        if(addEvent != null){
            val v = mdl.inputValue
            val isExist = dataList.any{ it.title == v }
            //리스트 영역	추가 버튼 - addEvent가 설정된 경우	표시 조건	입력값이 있고 mdl.dataList.title == 입력값이 없는 경우
            target.sub(K.listAdd).also{
                if(!isExist && v.isNotBlank()){
                    //리스트 영역	추가 버튼 - addEvent가 설정된 경우	내용 - addMsg가 없는 경우	+ {입력값}
                    //리스트 영역	추가 버튼 - addEvent가 설정된 경우	내용 - addMsg가 있는 경우	addMsg와 입력값을 추가해서 표시
                    it.value = v
                    it.attr("v0" to v)
                    it.html = addMsg.ifBlank{ "+ $v" }
                    it.displayBlock()
                    mdl.isListOpen = true
                }else{
                    it.value = ""
                    it.displayNone()
                }
            }
        }
        //선택 영역		표시 조건	mdl.selectedData가 설정된 경우
        target.sub(K.selectedValue){
            if(mdl.isSelected) it.displayBlock() else it.displayNone()
        }
        //리스트 영역		표시 조건	추가 버튼/검색 결과 없음/아이템 리스트가 하나라도 있다면
        target.sub(InputDataKey.listWrap){
            if(mdl.isListOpen) it.displayBlock() else it.displayNone()
        }
    }
}

suspend fun <T> eView<HTMLElement>.compLabelInputSectionDataMandatorySet(
    subKey:Any, label:String, vali:eVali? = null, isInline:Boolean = false, width:Int = 100, sectionClass: String = "input-section", wrapperClass: String = "input-data",
    sectionWrapperClass:String = "margin-top20",
    block:((CompInputDataMandatory<T>)->Unit)? = null
) = CompLabelInputSection(this, subKey, label, CompInputDataMandatory{
    it.vali = vali
    block?.invoke(it)
    it.wrapperClass = wrapperClass
}, isInline, width, sectionClass, sectionWrapperClass)
// ============================ prop ============================
@Suppress("UNCHECKED_CAST")
inline fun <T> eView<HTMLElement>.compInputDataMandatory(block:(CompInputDataMandatory<T>)->Unit = {}):CompInputDataMandatory<T>{
    val comp = this["compInputDataMandatory_value"] as? CompInputDataMandatory<T> ?: err("fail to get CompInputDataMandatory")
    block(comp)
    return comp
}