package select

import app.CompApp
import app.Factory
import comp.CompPageTitle
import comp.compInputSectionOneSet
import comp.input.CompError
import comp.input.CompSelect
import comp.input.compSelect
import compLabelInputSectionSelectSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.click
import ein2b.core.view.eEntityHook
import ein2b.core.view.eView
import ein2b.core.view.html
import entity.EntHook
import m42.model.HcInit
import org.w3c.dom.HTMLElement
import prop.compInputSectionString
import prop.compLabelInputSectionSelectString

private enum class K{
    input, error, submit,
    placeholder, view1, view2, view3
}
private val factory = Factory.htmlUrl("select")
suspend fun SelectView() = eView(factory){ rootView ->

    CompPageTitle(rootView){ compTitle ->
        compTitle.title = "Select"
    }
    rootView.sub(K.view1){ viewView->
        val compError = CompError("form-error").also{
            it.comp(viewView, K.error)
        }
        CompSelect{ comp->
            comp.wrapperClass = "width6-1"
            comp.placeholder = "주소를 선택해주세요."
            comp.afterInited = {
                comp.setList{
                    listOf(
                        comp.item("값1", 1),
                        comp.item("값2", 2)
                    )
                }
            }
            comp.errorListener = { ok, msg->
                eLaunch{
                    comp.error(ok)
                    compError.update(ok, msg)
                }
            }
            comp.vali = comp.singleRule("주소를 선택해주세요.")
        }.also{
            it.comp(viewView, K.input)
        }
        viewView.sub(K.placeholder).click = {_,_->
            eLaunch{
                viewView.sub(K.input).compSelect{
                    it.placeholder = "선택해주세요."
                }
            }
        }
    }

    rootView.sub(K.view2) { viewView ->
        viewView.compInputSectionOneSet<Int>(K.input, CompSelect { comp ->
            comp.wrapperClass = "width6-1"
            comp.placeholder = "주소를 선택해주세요."
            comp.afterInited = {
                comp.setList {
                    listOf(
                        comp.item("수원시", 1),
                        comp.item("용인시", 2),
                        comp.item("안산시", 3)
                    )
                }
            }
            comp.vali = comp.singleRule("주소를 선택해주세요.")
        })
//        viewView.sub(K.placeholder).click = { _, _ ->
//            eLaunch {
//                viewView.sub(K.input).compInputSectionOneSet<>()
//            }
//        }
    }


    rootView.sub(K.view3){ viewView->
        viewView.compLabelInputSectionSelectSet(K.input, "LabelInputSection",isInline = true) {comp ->
            comp.wrapperClass = "width6-1"
            comp.placeholder = "주소를 선택해주세요."
            comp.afterInited = {
                comp.setList{
                    listOf(
                        comp.item("수원시", 1),
                        comp.item("용인시", 2),
                        comp.item("안산시", 3),
                        comp.item("이천시", 4),
                        comp.item("화성시", 5),
                    )
                }
            }
            comp.vali = comp.singleRule("주소를 선택해주세요.")
        }
//        viewView.sub(K.placeholder).click = {_,_->
//            eLaunch{
//                viewView.sub(K.input).compSelect{
//                    it.placeholder = "선택해주세요."
//                }
//            }
//        }
    }

    rootView.sub(K.submit){
        it.html = "밸리 확인"
        it.click = {_,_->
            eLaunch{
                CompApp.checkAll(
                    rootView.sub("${K.view1}.${K.input}").compSelect(),
                    rootView.sub("${K.view2}.${K.input}").compInputSectionString(),
                    rootView.sub("${K.view3}.${K.input}").compLabelInputSectionSelectString()
                )
            }
        }
    }

    rootView.addEntityHook(EntHook::class, object:eEntityHook<HTMLElement, EntHook>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntHook){

        }
    })
    rootView.addEntityHook(HcInit::class, object:eEntityHook<HTMLElement, HcInit>{
        override suspend fun invoke(view:eView<HTMLElement>, entity: HcInit){

        }
    })
}