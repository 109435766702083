package comp.input

import CompLabelInputSection
import app.Factory
import ein2b.core.core.err
import ein2b.core.validation.eVali
import ein2b.core.view.*
import org.w3c.dom.HTMLElement

class CompInputDataOptional<T>:CompInputDataBase<T>(){
    companion object{
        //language=html
        private val FACTORY = Factory.html("""
<div class="input-data">
    <div data-view="${InputDataKey.inputWrap}" class="input-value input-clear">
        <input data-view="${InputDataKey.input}" class="input" type="text">
        <div data-view="${InputDataKey.clear}" class="btn-clear"></div>
    </div>
    <div data-view="${InputDataKey.listWrap}" class="list-wrap">
        <div data-view="${InputDataKey.listEmpty}" style="padding:10px 10px;font-size:12px"></div>
        <ul data-view="${InputDataKey.list}" class="data-list"></ul>
    </div>
</div>""")
        operator fun <T> invoke(block:(CompInputDataOptional<T>)->Unit):CompInputDataOptional<T>{
            val comp = CompInputDataOptional<T>()
            block(comp)
            comp.addClick()
            return comp
        }
    }
    override val factory = FACTORY
    override suspend fun init(it:eView<HTMLElement>){
        baseInit(it)
        target.sub(InputDataKey.input).keyup = keyUpDebounce(300){ v:String ->
            mdl.isSelected = false
            value.inputValue(v)
        }
        if(initValue.isNotBlank()){
            mdl.isSelected = true
            dataList.find{ it.value == initValue }?.also{
                mdl.selectedData = it
                mdl.inputValue = it.value
            }?:also{
                mdl.inputValue = initValue
            }
            target.sub(InputDataKey.input).value = initValue
            value.inputValue(initValue)
            mdlRender()
        }
    }
    override suspend fun listItemSelected(d:InputData<T>){
        mdl.isSelected = true
        mdl.inputValue = d.value
        target.sub(InputDataKey.input).value = d.value
        value.inputValue(d.value)
    }
    override suspend fun mdlRender(){
        _mdlRender()
        //리스트 영역		표시 조건	검색 결과 없음/아이템 리스트가 하나라도 있다면
        target.sub(InputDataKey.listWrap){
            if(mdl.isListOpen) it.displayBlock() else it.displayNone()
        }
    }
}

suspend fun <T> eView<HTMLElement>.compLabelInputSectionOptionalSet(
    subKey:Any, label:String, vali: eVali? = null, isInline:Boolean = false, width:Int = 100, sectionClass: String = "input-section", wrapperClass: String = "input-data",
    sectionWrapperClass:String = "margin-top20",
    block:((CompInputDataOptional<T>)->Unit)? = null
) = CompLabelInputSection(this, subKey, label, CompInputDataOptional{
    it.vali = vali
    block?.invoke(it)
    it.wrapperClass = wrapperClass
}, isInline, width, sectionClass, sectionWrapperClass)
// ============================ prop ============================
@Suppress("UNCHECKED_CAST")
inline fun <T> eView<HTMLElement>.compInputDataOptional(block:(CompInputDataOptional<T>)->Unit = {}):CompInputDataOptional<T>{
    val comp = this["compInputDataOptional_value"] as? CompInputDataOptional<T> ?: err("fail to get CompInputDataOptional")
    block(comp)
    return comp
}