package comp.input

import app.Factory
import comp.OutType
import ein2b.core.core.err
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import org.w3c.dom.HTMLElement
/* ***************** CompRadio 사용법 **************
CompRadio<String>{
    it.wrapperClass = "input-multi2-list"       // 라디오버튼 wrapper class 변경
    it.afterInited = {                    // 라디오버튼 상태 초기화하기 (isSelect가 다 false일 수 있음)
        it.setList(                             // it.item("라벨", "value", isSelect, isDisabled)
            it.item("라디오1", "1", true, false),
            it.item("라디오2", "2", false, true),
            it.item("라디오3", "3", false, false)
        )
    }
    it.vali = it.singleRule("라디오 벨리 에러.")
    it.checkBlock = {                           // 라디오를 체크 시 로직 (it은 클릭된 radio index)
        console.log("radio2::", it)
    }
}

<style>
    .input-multi2-vertical li{display:inline-block;padding:5px 8px;margin-right:10px;cursor:pointer}
    .input-multi2-vertical li .radio{width:16px;height:16px;display:inline-block;vertical-align:middle;background:url("/image/ic-radio.svg");background-size:16px 16px}
    .input-multi2-vertical li label{vertical-align:middle}
    .input-multi2-vertical li.selected .radio{width:16px;height:16px;background:url("/image/ic-radio-checked.svg");background-size:16px 16px}
    .input-multi2-vertical li.disabled{cursor:default}
    .input-multi2-vertical li.disabled .radio{width:16px;height:16px;background:url("/image/ic-radio-disabled.svg");cursor:default;background-size:16px 16px}
    .input-multi2-list li{display:inline-block;padding:5px 8px;margin-right:10px;cursor:pointer}
    .input-multi2-list li .radio{width:16px;height:16px;display:inline-block;vertical-align:middle;background:url("/image/ic-radio.svg");background-size:16px 16px}
    .input-multi2-list li label{vertical-align:middle}
    .input-multi2-list li.selected .radio{width:16px;height:16px;background:url("/image/ic-radio-checked.svg");background-size:16px 16px}
    .input-multi2-list li.disabled{cursor:default}
    .input-multi2-list li.disabled .radio{width:16px;height:16px;background:url("/image/ic-radio-disabled.svg");cursor:default;background-size:16px 16px}
</style>
*/
class CompRadio<V>:CompInputMulti<V, V>(){
    companion object{
        private const val ITEM = "CompRadio_item"
        private const val ITEM_SELECTED_CLASS = "selected"
        private const val ITEM_DISABLED_CLASS = "disabled"
        private const val LABEL = "CompRadio_label"
        private val itemFactory:suspend ()-> HTMLElement = Factory.html("""<li data-view="$ITEM"><div class="radio"></div> <label data-view="$LABEL" class="input-inline-label padding-left6 cursor"></label></li>""")
        operator fun<V> invoke(block:(CompRadio<V>)->Unit):CompRadio<V>{
            val comp = CompRadio<V>()
            block(comp)
            return comp
        }
    }
    override val outs:HashMap<OutType, suspend () -> V> = hashMapOf(OutType.DEFAULT to { value.value.first() })
    override val subKey:String = "CompRadio_wrapper"
    override val factory:suspend ()-> HTMLElement = Factory.html("""<div><ul data-view="$subKey"></ul></div>""")
    var checkBlock:((idx:Int)->Unit)? = null
    override suspend fun setList(vararg list:Item<V>){
        itemList = list
        target.setClearList{
            list.forEachIndexed{ idx, item ->
                it += eView(itemFactory){
                    item.view = it
                    it.sub(ITEM){ itemView->
                        itemView.className = setClassName(item)
                        itemView.click = { _, _ ->
                            if(!item.isDisabled) eLaunch{
                                list.forEach{ listItem->
                                    listItem.isSelect = false
                                    listItem.view?.sub(ITEM)?.className = setClassName(listItem)
                                }
                                item.isSelect = true
                                itemView.className = setClassName(item)

                                value.inputValue(listOf(idx))
                                checkBlock?.invoke(idx)
                            }
                        }
                    }
                    it.sub(LABEL).html = item.label
                    if(item.isSelect) value.inputValue(listOf(idx))
                }
            }
        }
    }
    private fun setClassName(item:Item<V>) = if(item.isDisabled) ITEM_DISABLED_CLASS else if(item.isSelect) ITEM_SELECTED_CLASS else ""
}

class CompToggleRadio<V>:CompInputMulti<V, V>(){
    enum class K{
        ITEM, ITEM_DATA
    }
    companion object{
        private const val ITEM_SELECTED_CLASS = "selected"
        private const val ITEM_DISABLED_CLASS = "disabled"
        private val itemFactory:suspend ()-> HTMLElement = Factory.html("""<li data-view="${K.ITEM}" class="toggle-list-item"></li>""")
        private val itemDataFactory:suspend ()-> HTMLElement = Factory.html("""<li data-view="${K.ITEM}" class="toggle-list-item"><b data-view="${K.ITEM_DATA}"></b></li>""")
        operator fun<V> invoke(block:(CompToggleRadio<V>)->Unit):CompToggleRadio<V>{
            val comp = CompToggleRadio<V>()
            block(comp)
            return comp
        }
    }
    override val outs:HashMap<OutType, suspend () -> V> = hashMapOf(OutType.DEFAULT to { value.value.first() })
    override val subKey:String = "CompToggleRadio_wrapper"
    override val factory:suspend ()-> HTMLElement = Factory.html("""<div><ul data-view="$subKey" class="toggle-list"></ul></div>""")
    var itemClass = "toggle-list-item"
    var checkBlock:((idx:Int)->Unit)? = null

    private suspend fun itemView(itemView:eView<HTMLElement>, idx:Int, item:Item<V>, isDataView:Boolean = false){
        if(!isDataView) itemView.html = item.label
        itemView.className = setClassName(item)
        itemView.click = { _, _ ->
            if(!item.isDisabled) eLaunch{
                itemList.forEach{ listItem->
                    listItem.isSelect = false
                    listItem.view?.sub(K.ITEM)?.className = setClassName(listItem)
                }
                item.isSelect = true
                itemView.className = setClassName(item)

                value.inputValue(listOf(idx))
                checkBlock?.invoke(idx)
            }
        }
        if(item.isSelect) value.inputValue(listOf(idx))
    }
    override suspend fun setList(vararg list:Item<V>){
        itemList = list
        target.setClearList{
            list.forEachIndexed{ idx, item ->
                it += eView(itemFactory){ v->
                    item.view = v
                    v.sub(K.ITEM){ itemView(it, idx, item) }
                }
            }
        }
    }
    suspend fun setList(list:List<Item<V>>, factory:suspend ()->HTMLElement, block:suspend (view:eView<HTMLElement>, item:Item<V>)->Unit){
        itemList = list.toTypedArray()
        target.setClearList{ lv ->
            list.forEachIndexed{ idx, item ->
                lv += eView(itemDataFactory){ v ->
                    item.view = v
                    v.sub(K.ITEM){ itemView(it, idx, item, true) }
                    v.sub(K.ITEM_DATA, factory){ dataView-> block(dataView, item) }
                }
            }
        }
    }
    private fun setClassName(item:Item<V>) = "$itemClass ${if(item.isDisabled) ITEM_DISABLED_CLASS else if(item.isSelect) ITEM_SELECTED_CLASS else ""}"
}

// ============================ prop ============================
@Suppress("UNCHECKED_CAST")
inline fun <T> eView<HTMLElement>.compToggleRadio(block:(CompToggleRadio<T>)->Unit = { }):CompToggleRadio<T>{
    val comp = this["compToggleRadio_value"] as? CompToggleRadio<T> ?: err("fail to get compToggleRadio")
    block(comp)
    return comp
}
@Suppress("UNCHECKED_CAST")
inline fun <T> eView<HTMLElement>.compRadio(block:(CompRadio<T>)->Unit = { }):CompRadio<T>{
    val comp = this["compRadio_value"] as? CompRadio<T> ?: err("fail to get compRadio")
    block(comp)
    return comp
}
@Suppress("UNCHECKED_CAST")
inline fun eView<HTMLElement>.compRadioString(block:(CompRadio<String>)->Unit = { }):CompRadio<String>{
    val comp = this["compRadio_value"] as? CompRadio<String> ?: err("fail to get compRadioString")
    block(comp)
    return comp
}
@Suppress("UNCHECKED_CAST")
inline fun eView<HTMLElement>.compRadioInt(block:(CompRadio<Int>)->Unit = { }):CompRadio<Int>{
    val comp = this["compRadio_value"] as? CompRadio<Int> ?: err("fail to get compRadioInt")
    block(comp)
    return comp
}