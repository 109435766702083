package ein2b.core.date

import ein2b.core.core.err

class eZonedDateTime(val local: eLocalDateTime, val zone: String): Comparable<eZonedDateTime> {

    init {
        if(!eZonedDateTime.checkZone(zone)) throw IllegalArgumentException("Invalid time zone string: ${zone}")
    }
    override fun compareTo(other: eZonedDateTime): Int = local.toUtc(zone).compareTo(other.local.toUtc(other.zone))

    override fun toString(): String = "${local.toString()} (${zone})"

    companion object {
    }

    fun toLocalWithSameUtc(targetZone:String) = this.toUtc().toLocalDateTime(targetZone)

    fun toUtc() = local.toUtc(zone)
}

// zone을 체크해서 zone이 올바르지 않으면 IllegalArgumentException 예외를 발생시켜라
expect fun eZonedDateTime.Companion.checkZone(zone: String): Boolean
expect fun eZonedDateTime.SYSTEM_TIMEZONE():String

// 시스템이 지원하는 존이면 true, 아니면 false를 반환한다.
// 이 값과 checkZone을 함께 봐야 한다. checkZone이 true인데 이 값이 false면
// 시스템에서는 아직 지원하지 않는 타임존 문자열이므로 특별한 처리가 필요하다.
expect fun eZonedDateTime.Companion.supportedBySystem(zone: String): Boolean

expect fun eZonedDateTime.Companion.altZone(zone: String): String?
