package number

import app.CompApp
import app.Factory
import comp.CompPageTitle
import comp.compInputSectionOneSet
import comp.input.CompError
import comp.input.CompInputNumber
import comp.input.compInputNumber
import compLabelInputSectionNumberSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.validation.eRuleSet
import ein2b.core.validation.eRuleVali
import ein2b.core.view.click
import ein2b.core.view.eEntityHook
import ein2b.core.view.eView
import ein2b.core.view.html
import entity.EntHook
import m42.model.HcInit
import org.w3c.dom.HTMLElement
import prop.compInputSectionString
import prop.compLabelInputSectionNumber

private enum class K{
    input, error, submit,
    view1, view2, view3
}
private val factory = Factory.htmlUrl("number")
suspend fun NumberView() = eView(factory){ rootView ->

    val numberVali = eRuleVali { //밸리데이션 체크 로직
        Case {
            String()
            Trim()
            Rule("0~10000숫자 입력해주세요.") { v,_ -> //밸리메시지 설정
                try {
                    if ("$v".toInt() in 0..10000) v else eRuleSet.FALSE //밸리 조건 설정
                } catch (e: Throwable) {
                    eRuleSet.FALSE
                }
            }
        }
    }
    CompPageTitle(rootView){ compTitle ->
        compTitle.title = "Number"
    }
    rootView.sub(K.view1){ viewView->
        val compError = CompError("form-error").also{
            it.comp(viewView, K.error)
        }
        CompInputNumber {comp ->
            comp.placeholder = "플레이스 홀더"
            comp.vali = numberVali
            comp.minValue = -100.0
            comp.maxValue = 100.0
            comp.step = "0.1"
            comp.errorListener = { ok, msg->
                eLaunch{
                    comp.error(ok)
                    compError.update(ok, msg)
                }
            }
        }.also{
            it.comp(viewView, K.input)
        }
    }
    rootView.sub(K.view2){ viewView->
        viewView.compInputSectionOneSet<String>(
            K.input, CompInputNumber{ comp->
                comp.placeholder = "플레이스 홀더"
                comp.vali = numberVali
                comp.minValue = -10000
                comp.maxValue = 10000
            }
        )
    }
    rootView.sub(K.view3){ viewView->
        viewView.compLabelInputSectionNumberSet(K.input, "LabelInputSection"){ comp->
            comp.placeholder = "플레이스 홀더"
            comp.vali = numberVali
            comp.minValue = 1
            comp.maxValue = 10000
        }
    }
    rootView.sub(K.submit){
        it.html = "밸리 확인"
        it.click = {_,_->
            eLaunch{
                CompApp.checkAll(
                    rootView.sub("${K.view1}.${K.input}").compInputNumber(),
                    rootView.sub("${K.view2}.${K.input}").compInputSectionString(),
                    rootView.sub("${K.view3}.${K.input}").compLabelInputSectionNumber()
                )
            }
        }
    }

    rootView.addEntityHook(EntHook::class, object:eEntityHook<HTMLElement, EntHook>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntHook){

        }
    })
    rootView.addEntityHook(HcInit::class, object:eEntityHook<HTMLElement, HcInit>{
        override suspend fun invoke(view:eView<HTMLElement>, entity: HcInit){

        }
    })
}