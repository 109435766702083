package comp.input

import app.Factory
import app.eWindow
import comp.OutType
import comp.input.CompInput.Companion.CONV
import ein2b.core.core.err
import ein2b.core.core.uuid
import ein2b.core.coroutine.eLaunch
import ein2b.core.validation.eVali
import ein2b.core.view.*
import m42.app.M42App
import org.w3c.dom.HTMLElement

class CompInputTime:CompInput<String,String,String>{
    companion object{
        private val LIST = mutableListOf<CompInputTime>()
        private const val CLASS_NAME = "CompInputTime"
        private const val WRAPPER = "${CLASS_NAME}_wrapper"
        private const val TIME_WRAPPER = "${CLASS_NAME}_time_wrapper"
        private const val TIME = "${CLASS_NAME}_time"
        private const val HOUR_MIN = "${CLASS_NAME}_hour_min"
        private const val HOUR_MIN_HOUR_LIST = "${HOUR_MIN}_hour_list"
        private const val HOUR_MIN_MIN_LIST = "${HOUR_MIN}_min_list"
        private const val ERROR_CLASS:String = "error"
        private const val DISABLED_CLASS:String = "disabled"
        private const val SELECTED_CLASS:String = "selected"
        private val FACTORY:suspend ()->HTMLElement = Factory.html("""
<div data-view="$WRAPPER">
    <div data-view="$TIME_WRAPPER" class="time-wrapper">
        <div class="time-icon"></div>
        <div data-view="$TIME" class="time"></div>
    </div>
    <div data-view="$HOUR_MIN" class="hour-min">
        <ul data-view="$HOUR_MIN_HOUR_LIST" class="scroll3 hour-min-list"></ul>
        <ul data-view="$HOUR_MIN_MIN_LIST" class="scroll3 hour-min-list"></ul>
    </div>
</div>""")
        private val itemFactory = Factory.html("""<li data-view=""></li>""")

        operator fun invoke(block:(CompInputTime) -> Unit):CompInputTime{
            val comp = CompInputTime()
            LIST.add(comp)
            block(comp)
            comp.setListHour()
            comp.setListMin()
            eWindow.addClick(comp.compId){ eLaunch{ comp.close() } }
            return comp
        }
    }
    private val compId = uuid("")
    override val outs:HashMap<OutType, suspend () -> String> = hashMapOf(OutType.DEFAULT to{ value.value })
    override lateinit var value:CompValue<String, String>
    override var initValue = ""
    override var errorListener:((Boolean, String)->Unit)? = null
    override var vali:eVali? = null
    override var placeholder = ""
    override var tabIndex = -2
    override val factory:suspend ()->HTMLElement = FACTORY
    override suspend fun init(it:eView<HTMLElement>){
        if(tabIndex > -2) it.attr("tabindex", tabIndex)
        target = it
        target.sub(WRAPPER){
            if(!isDisplay) it.displayNone()
            it.className = setClassName()
        }
        target.sub(TIME_WRAPPER).click = { _,_->
            eWindow.currClickId = compId
            if(!isDisabled) eLaunch{
                if(isOpen) close()
                else{
                    LIST.forEach{ it.close() }
                    open()
                }
            }
        }
        target.sub(TIME).html = initValue
        target.sub(HOUR_MIN).displayNone()
        target.sub(HOUR_MIN_HOUR_LIST)
        target.sub(HOUR_MIN_MIN_LIST)
        value = CompValue("", "", vali, errorListener, CONV){ str-> eLaunch{ target.sub(TIME).html = str } }
        inputValueAddOn(initValue)
    }
    override suspend fun error(isOk:Boolean){
        value.isOk = isOk
        target.className = setClassName(if(isOk) "" else ERROR_CLASS)
    }
    override suspend fun clear(){
        error(true)
        enable(true)
        inputValueAddOn(initValue)
    }

    lateinit var target:eView<HTMLElement>
    private var itemHeight = 27.0
    private var isOpen = false
    private var hourValue = ""
    private var minValue = ""
    private var hourScrollY = 0.0
    private var minScrollY = 0.0

    class Item(var value:String = "", var isSelected:Boolean = false, var isActive:Boolean = true)
    var hourTerm = 1
    var hourMin = 0
    var hourMax = 23
    var minTerm = 1
    var minMin = 0
    var minMax = 59
    private var hourList = listOf<Item>()
    private var minList = listOf<Item>()
    private var wrapperDefaultClass = "input-time"

    var isDisplay = true
    var isDisabled = false
    var wrapperClass = ""
    var checkBlock:((v:String)->Unit)? = null

    fun setListHour(block:(()->List<Item>)? = null){
        hourList = block?.invoke() ?: ((hourMin..hourMax).filter{
            it % hourTerm == 0
        }.map{
            Item(M42App.dateFormat(it))
        })
    }
    fun setListMin(block:(()->List<Item>)? = null){
        minList = block?.invoke() ?: ((minMin..minMax).filter{
            it % minTerm == 0
        }.map{
            Item(M42App.dateFormat(it))
        })
    }
    suspend fun inputValueAddOn(v:String){
        var hourMin = listOf("", "")
        value.inputValue(v)
        if(v.isNotBlank()){
            hourMin = v.split(":")
        }else{
            target.sub(TIME).html = "--:--"
        }
        hourValue = hourMin[0]
        minValue = hourMin[1]
        hourList.forEachIndexed{ idx, item->
            item.isSelected = item.value == hourValue
            if(item.isSelected) hourScrollY = idx * itemHeight
        }
        minList.forEachIndexed{ idx, item->
            item.isSelected = item.value == minValue
            if(item.isSelected) minScrollY = idx * itemHeight
        }
    }
    private suspend fun hourSetList(){
        target.sub(HOUR_MIN_HOUR_LIST).setClearList{ vList ->
            hourList.forEach{ item ->
                vList += eView(itemFactory){
                    it.html = item.value
                    it.className = if(!item.isActive) DISABLED_CLASS else if(item.isSelected) SELECTED_CLASS else ""
                    it.click = { e,_ ->
                        e.stopImmediatePropagation()
                        e.stopPropagation()
                        if(item.isActive) eLaunch{
                            hourValue = item.value
                            hourList.forEach{ d-> d.isSelected = d.value == hourValue }
                            hourSetList()
                        }
                    }
                }
            }
        }
    }
    private suspend fun minSetList(){
        target.sub(HOUR_MIN_MIN_LIST).setClearList{ vList ->
            minList.forEach{ item->
                vList += eView(itemFactory){
                    it.html = item.value
                    it.className = if(!item.isActive) DISABLED_CLASS else if(item.isSelected) SELECTED_CLASS else ""
                    it.click = { e,_ ->
                        e.stopImmediatePropagation()
                        e.stopPropagation()
                        if(item.isActive) eLaunch{
                            minValue = item.value
                            minList.forEach{ d-> d.isSelected = d.value == minValue }
                            minSetList()
                        }
                    }
                }
            }
        }
    }
    private suspend fun open(){
        if(isOpen) return
        isOpen = true

        hourSetList()
        minSetList()
        target.className = setClassName(SELECTED_CLASS)
        target.sub(HOUR_MIN).displayFlex()
        target.sub(HOUR_MIN_HOUR_LIST).also{
            it.scrollY = -1.0
            it.scrollY = hourScrollY
        }
        target.sub(HOUR_MIN_MIN_LIST).also{
            it.scrollY = -1.0
            it.scrollY = minScrollY
        }
    }
    private suspend fun close(){
        if(isOpen){
            isOpen = false

            val v = if(hourValue.isNotBlank() && minValue.isNotBlank()) "$hourValue:$minValue" else ""
            inputValueAddOn(v)
            target.sub(HOUR_MIN).displayNone()
            if(value.isOk) target.className = setClassName() else error(v.isNotBlank())
            checkBlock?.invoke(v)
        }
    }
    private fun setClassName(cls:String = "") = "$wrapperDefaultClass${if(wrapperClass.isBlank()) "" else " $wrapperClass"}" +
            if(isDisabled) " $DISABLED_CLASS"
            else if(cls.isNotBlank()) " $cls"
            else ""

    fun enable(v:Boolean){
        isDisabled = !v
        target.className = setClassName()
    }
    override suspend fun displayNone() = target.displayNone()
    override suspend fun displayBlock() = target.displayBlock()
    override suspend fun displayInlineBlock() = target.displayInlineBlock()
    override suspend fun displayFlex() = target.displayFlex()
}

// ============================ prop ============================
inline fun eView<HTMLElement>.compInputTime(block:(CompInputTime)->Unit = { }):CompInputTime{
    val comp = this["compInputTime_value"] as? CompInputTime ?: err("fail to get compInputTime")
    block(comp)
    return comp
}