import app.CompRouter
import app.RouterKey
import app.RouterKey.INPUT_DATA
import app.RouterKey.INPUT_DATE
import app.RouterKey.INPUT_FILE
import app.RouterKey.INPUT_NUMBER
import app.RouterKey.INPUT_PRICE
import app.RouterKey.INPUT_RADIO
import app.RouterKey.INPUT_SELECT
import app.RouterKey.INPUT_TEXT
import app.hashManager
import ein2b.core.core.Now
import ein2b.core.core.jsNow
import ein2b.core.view.eDomBinder
import ein2b.core.view.eView
import kotlinx.browser.window
import m42.app.M42App
import m42.common.M42Api
import view.*

suspend fun main(){
    Now.instance = jsNow
    eView.binder = eDomBinder().apply{ start() }

    CompViewBlocking.init(CompRouter.viewEL)
    CompViewAlert.init(CompRouter.viewEL)
    CompViewConfirm.init(CompRouter.viewEL)
    CompViewToast.init(CompRouter.viewEL)

    CompViewLnb.init(
        CompRouter.menuRouterEl,
        "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_1_0/2022/08/18/d1e84c10f6a65bda7fa8a76f20b236fd.png",
        true
    ){ M42App.goUrl(M42App.homeRouterKey) }
    CompViewLnb.menuList = mutableListOf(
        CompViewLnb.MenuItem("Input").apply{ subList = mutableListOf(
            CompViewLnb.MenuItem.SubItem(INPUT_TEXT, "Text").also{ it.clickBlock = { M42App.goUrl(INPUT_TEXT) }}, //text, textDash
            CompViewLnb.MenuItem.SubItem("", "Password"),
            CompViewLnb.MenuItem.SubItem(INPUT_NUMBER, "Number").also{ it.clickBlock = { M42App.goUrl(INPUT_NUMBER) }}, //number, textDash
            CompViewLnb.MenuItem.SubItem(INPUT_PRICE, "Price").also{ it.clickBlock = { M42App.goUrl(INPUT_PRICE) }},
            CompViewLnb.MenuItem.SubItem("", "Textarea"),
            CompViewLnb.MenuItem.SubItem(INPUT_SELECT, "Select").also{ it.clickBlock = { M42App.goUrl(INPUT_SELECT) }}, //select, groupSelect, selectAddOn
            CompViewLnb.MenuItem.SubItem(INPUT_RADIO, "Radio").also{ it.clickBlock = { M42App.goUrl(INPUT_RADIO) }},
            CompViewLnb.MenuItem.SubItem("", "Checkbox"),
            CompViewLnb.MenuItem.SubItem(INPUT_DATA, "Data").also{ it.clickBlock = { M42App.goUrl(INPUT_DATA) }},
            CompViewLnb.MenuItem.SubItem(INPUT_DATE, "Date").also{ it.clickBlock = { M42App.goUrl(INPUT_DATE) }},
            CompViewLnb.MenuItem.SubItem("", "Time"),
            CompViewLnb.MenuItem.SubItem(INPUT_FILE, "File").also{ it.clickBlock = { M42App.goUrl(INPUT_FILE) }}, //number, textDash
        )},
        CompViewLnb.MenuItem("InputAddOn").apply{ subList = mutableListOf(
            CompViewLnb.MenuItem.SubItem("", "Text"),
            CompViewLnb.MenuItem.SubItem("", "Number").also{ it.clickBlock = { M42App.goUrl("") }}
        )},
        CompViewLnb.MenuItem("View").apply{ subList = mutableListOf(
            CompViewLnb.MenuItem.SubItem("", "Calendar"),
            CompViewLnb.MenuItem.SubItem("", "Confirm"),
            CompViewLnb.MenuItem.SubItem("", "FilterList"),
            CompViewLnb.MenuItem.SubItem("", "InputSection"),
            CompViewLnb.MenuItem.SubItem("", "LabelInputSection"),
            CompViewLnb.MenuItem.SubItem("", "PageTitle"),
            CompViewLnb.MenuItem.SubItem("", "ProfileImageList"),
            CompViewLnb.MenuItem.SubItem("", "Tabs"),
            CompViewLnb.MenuItem.SubItem("", "ViewList")
        )},
        CompViewLnb.MenuItem("Member").apply{ subList = mutableListOf(
            CompViewLnb.MenuItem.SubItem("", "Login")
        )},
        CompViewLnb.MenuItem("Element view").apply{ subList = mutableListOf(
            CompViewLnb.MenuItem.SubItem("", "Alert"),
            CompViewLnb.MenuItem.SubItem("", "Confirm"),
            CompViewLnb.MenuItem.SubItem("", "Blocking"),
            CompViewLnb.MenuItem.SubItem("", "Toast")
        )}
    )

    /*val userMenu = mutableListOf<CompViewLnb.MenuUser.UserMenu>()
    var userIconType = CompMember.UserIconType.MY_INFO
    userMenu += CompViewLnb.MenuUser.UserMenu(CompMemberRouterKey.MYINFO_MASTER, userIconType.title, userIconType.urlIcon){ M42App.goUrl(
        CompMemberRouterKey.MYINFO_MASTER
    ) }
    userIconType = CompMember.UserIconType.CHANGE_PW
    userMenu += CompViewLnb.MenuUser.UserMenu(CompMemberRouterKey.CHANGE_PW, userIconType.title, userIconType.urlIcon){ M42App.goUrl(
        CompMemberRouterKey.CHANGE_PW
    ) }
    userIconType = CompMember.UserIconType.SUB_MANAGEMENT
    userMenu += CompViewLnb.MenuUser.UserMenu(CompMemberRouterKey.SUB_MANAGEMENT, userIconType.title, userIconType.urlIcon){ M42App.goUrl(
        CompMemberRouterKey.SUB_MANAGEMENT
    ) }
    userIconType = CompMember.UserIconType.LOGOUT
    userMenu += CompViewLnb.MenuUser.UserMenu(CompMemberRouterKey.LOGIN, userIconType.title, userIconType.urlIcon){
        eLaunch{ CompMember.ApiMemberLogout.net{}?.also{ M42App.goUrl(CompMemberRouterKey.LOGIN) } }
    }
    CompViewLnb.menuUser = CompViewLnb.MenuUser(
        "//s3.ap-northeast-2.amazonaws.com/m42upload/m42medi_dev/profile/2022/03/08/e485effeb3728feddb8e51542a7dc0d9.png",
        "//s3.ap-northeast-2.amazonaws.com/m42resource/m42_medi/2022/01/24/ae7e3257ca3727c0ceb97d6e50a0f50b.svg",
        "세훈",
        ""
    ).apply{ menuList = userMenu }
    CompViewLnb.setUser()*/

    CompViewLnb.setList()
    CompViewLnb.setUserHide()


    M42Api.midKey = "fmid"
    M42Api.storageKey = "userid"
    M42Api.domain = "http://${window.location.host}"
    M42Api.blockingOpen = { CompViewBlocking.open() }
    M42Api.blockingClose = { CompViewBlocking.close() }
    M42Api

    M42Api.loginPage = false
    M42App.homeRouterKey = RouterKey.INPUT_DATA
    M42App.hashManager = hashManager
    hashManager.init()
}