package ein2b.core.entity.field.value

import ein2b.core.entity.Report
import ein2b.core.entity.field.eField
import ein2b.core.log.log

abstract class ValueField<T>: eField<T>() {
    override fun fromJSON(k: String?, vStr:String, str: String, report: Report): Boolean {
        v = value(vStr, str, report)
        return v != null
    }
    override fun toJSON(): String  = "$v"
    abstract fun value(vStr:String, str:String, report: Report):T?

    abstract val useVstr: Boolean
}