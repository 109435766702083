package ein2b.core.date

import ein2b.core.core.*

class eLocalDateTime(
    val year:Int=0,
    val month:Int=0,
    val date:Int=0,
    val hour:Int=0,
    val minute:Int=0,
    val second:Int=0,
    val millisecond:Int=0
): Comparable<eLocalDateTime> {
    private fun toYymmdd():Int =
            date + month * 100 + year * 1_0000
    private fun toHhmmss():Int =
            second + minute * 100 + hour * 1_0000
    override operator fun compareTo(other: eLocalDateTime): Int =
            (this.toYymmdd() - other.toYymmdd()).let { ymddiff ->
                if(ymddiff == 0) {
                    (this.toHhmmss() - other.toHhmmss()).let {
                        if(it==0)
                            this.millisecond - other.millisecond
                        else
                            it
                    }
                } else
                  ymddiff
            }

    override fun equals(other: Any?): Boolean = when(other) {
        null -> false
        !is eLocalDateTime -> false
        else ->
            this.compareTo(other) == 0
    }
    
    override fun toString() = "${year.pad0_4()}-${(month).pad0_2()}-${date.pad0_2()}T${hour.pad0_2()}:${minute.pad0_2()}:${second.pad0_2()}.${millisecond.pad0_3()}"

    companion object {
        // yyyy-mm-ddThh:mm, yyyy-mm-ddThh:mm:ss, yyyy-mm-ddThh:mm.ss.{f,fff,fff} 유형만 허용
        val rISO = """(\d{1,4})-(1[0-2]|0?\d)-(3[01]|[12]\d|0?\d)T(2[0-3]|1\d|0?\d):([1-5]\d|0?\d)(?::([1-5]\d|0?\d)(?:.(\d{1,3}))?)?""".toRegex()
        val rISOMicro = """(\d{1,4})-(1[0-2]|0?\d)-(3[01]|[12]\d|0?\d)T(2[0-3]|1\d|0?\d):([1-5]\d|0?\d)(?::([1-5]\d|0?\d)(?:.(\d{4,6}))?)?""".toRegex()
        private fun milliSecParse(s:String) =  when(s.length) {
            1 -> s.toInt()*100
            2 -> s.toInt()*10
            3 -> s.toInt()
            0 -> 0
            else -> s.substring(0,3).toInt()
        }

        private fun microSecParse(s:String) =  when(s.length) {
            1 -> s.toInt()*100000
            2 -> s.toInt()*10000
            3 -> s.toInt()*1000
            4 -> s.toInt()*100
            5 -> s.toInt()*10
            6 -> s.toInt()
            0 -> 0
            else -> s.substring(0,6).toInt()
        }

        private fun groupToELocalDateTime(match: MatchResult, subFractionConv:(String)->Int):eLocalDateTime {
            val m = match.groupValues.drop(1)
            val year = m[0].toInt()
            val month = m[1].toInt()
            val day = m[2].toInt()
            val hourStr = m[3]
            val minStr = m[4]
            val secStr = m[5]
            val milliStr = m[6]

            return if(hourStr.isBlank()) eLocalDateTime(year, month, day)
            else if(minStr.isBlank()) eLocalDateTime(year, month, day, hourStr.toInt())
            else if(secStr.isBlank()) eLocalDateTime(year, month, day, hourStr.toInt(), minStr.toInt())
            else if(milliStr.isBlank()) eLocalDateTime(year, month, day, hourStr.toInt(), minStr.toInt())
            else eLocalDateTime(year, month, day, hourStr.toInt(), minStr.toInt(), secStr.toInt(), subFractionConv(milliStr))
        }

        private val DEFAULT = of("1900-01-01T00:00:00:000")!!
        fun default():eLocalDateTime = DEFAULT
        fun isDefault(target:eLocalDateTime):Boolean = target == DEFAULT

        fun of(dayStr:String):eLocalDateTime? =
            (rISO.matchEntire(dayStr))?.let {
                groupToELocalDateTime(it, ::milliSecParse)
            } ?: (rISOMicro.matchEntire(dayStr))?.let {
                groupToELocalDateTime(it, ::microSecParse)
            }

        private fun diff(interval:String, d1:eLocalDateTime, d2:eLocalDateTime, zone: String):Long =
            when(val ii=interval.lowercase()){
                "y"->(d2.year - d1.year).toLong()
                "m"->((d2.year - d1.year) * 12 + d2.month - d1.month).toLong()
                else -> {
                    val time1 = d1.toUtc(zone).timeInMilli()
                    val time2 = d2.toUtc(zone).timeInMilli()
                    when (ii) {
                        "h" -> (time2 - time1) / 3600000L
                        "i" -> (time2 - time1) / 60000L
                        "s" -> (time2 - time1) / 1000L
                        "ms" -> time2 - time1
                        "d" -> {
                            val order = if (time2 > time1) 1 else -1
                            val date1 = if (order == 1) d1 else d2
                            val date2 = if (order == 1) d2 else d1
                            val d1Year = date1.year
                            val d1Month = date1.month
                            val d1Date = date1.date
                            val d2Year = date2.year
                            val d2Month = date2.month
                            val d2Date = date2.date
                            val j = d2Year - d1Year
                            var d = 0L
                            if (j > 0) {
                                d += diff("d", eLocalDateTime(d1Year, d1Month, d1Date), eLocalDateTime(d1Year, 12, 31), zone)
                                d += diff("d", eLocalDateTime(d2Year, 1, 1), eLocalDateTime(d2Year, d2Month, d2Date), zone)
                                var year = d1Year + 1
                                var i = 1
                                while (i < j) {
                                    d += if (_leapYear(year)) 366 else 365
                                    i++
                                    year++
                                }
                            } else {
                                val temp = arrayOf(0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31)
                                if (_leapYear(d1Year)) temp[2]++
                                val j = d2Month - d1Month
                                if (j > 0) {
                                    d += diff(
                                        "d",
                                        eLocalDateTime(d1Year, d1Month, d1Date),
                                        eLocalDateTime(d1Year, d1Month, temp[d1Month]),
                                        zone
                                    ) + 1
                                    d += diff(
                                        "d",
                                        eLocalDateTime(d2Year, d2Month, 1),
                                        eLocalDateTime(d2Year, d2Month, d2Date),
                                        zone
                                    )
                                    var month = d1Month
                                    var i = 1
                                    while (i < j) {
                                        d += temp[month++]
                                        i++
                                    }
                                } else d += d2Date - d1Date
                            }
                            d * order
                        }

                        else -> err("invalid interval $interval")
                    }
                }
            }

        private fun _leapYear(v:Int)= (v% 4 == 0 && v % 100 != 0) || v % 400 == 0
    }
}

expect fun eLocalDateTime.Companion.now(): eLocalDateTime

expect fun eLocalDateTime.toUtc(zone: String): eUtc


