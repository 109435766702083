package price

import app.CompApp
import app.Factory
import comp.CompPageTitle
import comp.compInputSectionOneSet
import comp.input.CompError
import comp.input.CompInputPrice
import comp.input.compInputPrice
import compLabelInputSectionPriceSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.validation.eRuleSet
import ein2b.core.validation.eRuleVali
import ein2b.core.view.click
import ein2b.core.view.eEntityHook
import ein2b.core.view.eView
import ein2b.core.view.html
import entity.EntHook
import m42.model.HcInit
import org.w3c.dom.HTMLElement
import prop.compInputSectionString
import prop.compLabelInputSectionPrice

private enum class K{
    input, error, submit,
    view1, view2, view3
}
private val factory = Factory.htmlUrl("price")
suspend fun PriceView() = eView(factory){ rootView ->
    val vali2 = eRuleVali{
        Case{
            String()
            Trim()
            MinLength(1, "값을 입력해 주세요.")
            Rule("1 ~ 100000000내에 값을 입력해 주세요."){ v, _ ->
                try{
                    val num = "$v".replace(",", "").toLong()
                    if(num in 1..100000000) v else eRuleSet.FALSE
                }catch(e:Throwable){
                    eRuleSet.FALSE
                }
            }
        }
    }
    CompPageTitle(rootView){ compTitle ->
        compTitle.title = "Price"
    }
    rootView.sub(K.view1){ viewView->
        val compError = CompError("form-error").also{
            it.comp(viewView, K.error)
        }
        CompInputPrice{ comp->
            comp.placeholder = "플레이스 홀더"
            comp.vali = vali2
            comp.minValue = 1
            comp.maxValue = 100000000
            comp.errorListener = { ok, msg->
                eLaunch{
                    comp.error(ok)
                    compError.update(ok, msg)
                }
            }
        }.also{
            it.comp(viewView, K.input)
        }
    }
    rootView.sub(K.view2){ viewView->
        viewView.compInputSectionOneSet<String>(
            K.input, CompInputPrice{ comp->
                comp.placeholder = "플레이스 홀더"
                comp.vali = vali2
                comp.minValue = 1
                comp.maxValue = 100000000
            }
        )
    }
    rootView.sub(K.view3){ viewView->
        viewView.compLabelInputSectionPriceSet(K.input, "LabelInputSection"){ comp->
            comp.placeholder = "플레이스 홀더"
            comp.vali = vali2
            comp.minValue = 1
            comp.maxValue = 100000000
        }
    }
    rootView.sub(K.submit){
        it.html = "밸리 확인"
        it.click = {_,_->
            eLaunch{
                CompApp.checkAll(
                    rootView.sub("${K.view1}.${K.input}").compInputPrice(),
                    rootView.sub("${K.view2}.${K.input}").compInputSectionString(),
                    rootView.sub("${K.view3}.${K.input}").compLabelInputSectionPrice()
                )
            }
        }
    }

    rootView.addEntityHook(EntHook::class, object:eEntityHook<HTMLElement, EntHook>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntHook){

        }
    })
    rootView.addEntityHook(HcInit::class, object:eEntityHook<HTMLElement, HcInit>{
        override suspend fun invoke(view:eView<HTMLElement>, entity: HcInit){

        }
    })
}