package ein2b.core.date

import ein2b.core.core.*


class eUtc(
    val yearUTC:Int=0,
    val monthUTC:Int=0,
    val dateUTC:Int=0,
    val hourUTC:Int=0,
    val minuteUTC:Int=0,
    val secondUTC:Int=0,
    val millisecondUTC:Int=0
): Comparable<eUtc> {

    private fun toYymmdd():Int =
            dateUTC + monthUTC * 100 + yearUTC * 1_0000
    private fun toHhmmss():Int =
            secondUTC + minuteUTC * 100 + hourUTC * 1_0000
    override operator fun compareTo(other: eUtc): Int =
            (this.toYymmdd() - other.toYymmdd()).let { ymddiff ->
                if(ymddiff == 0) {
                    (this.toHhmmss() - other.toHhmmss()).let {
                        if(it==0)
                            this.millisecondUTC - other.millisecondUTC
                        else
                            it
                    }
                } else
                  ymddiff
            }

    override fun equals(other: Any?): Boolean = when(other) {
        null -> false
        !is eUtc -> false
        else ->
            this.compareTo(other) == 0
    }
    
    override fun toString() = "${yearUTC.pad0_4()}-${(monthUTC).pad0_2()}-${dateUTC.pad0_2()}T${hourUTC.pad0_2()}:${minuteUTC.pad0_2()}:${secondUTC.pad0_2()}.${millisecondUTC.pad0_3()}Z"

    // mysql 입력시 필요한 문자열 형태
    fun toDbString() = "${yearUTC.pad0_4()}-${(monthUTC).pad0_2()}-${dateUTC.pad0_2()} ${hourUTC.pad0_2()}:${minuteUTC.pad0_2()}:${secondUTC.pad0_2()}.${millisecondUTC.pad0_3()}"

    companion object {
        // yyyy-mm-ddThh:mmZ, yyyy-mm-ddThh:mm:ssZ, yyyy-mm-ddThh:mm.ss.{f,fff,fff}Z 유형 허용
        val rISO = """(\d{1,4})-(1[0-2]|0?\d)-(3[01]|[12]\d|0?\d)T(2[0-3]|1\d|0?\d):([1-5]\d|0?\d)(?::([1-5]\d|0?\d)(?:.(\d{1,3}))?)?Z""".toRegex()
        val rISOMicro = """(\d{1,4})-(1[0-2]|0?\d)-(3[01]|[12]\d|0?\d)T(2[0-3]|1\d|0?\d):([1-5]\d|0?\d)(?::([1-5]\d|0?\d)(?:.(\d{4,6}))?)?Z""".toRegex()

        val rDbLocal = """(\d{1,4})-(1[0-2]|0?\d)-(3[01]|[12]\d|0?\d) (2[0-3]|1\d|0?\d):([1-5]\d|0?\d)(?::([1-5]\d|0?\d)(?:.(\d{1,3}))?)?""".toRegex()
        val rDbLocalMicro = """(\d{1,4})-(1[0-2]|0?\d)-(3[01]|[12]\d|0?\d) (2[0-3]|1\d|0?\d):([1-5]\d|0?\d)(?::([1-5]\d|0?\d)(?:.(\d{1,3}))?)?""".toRegex()

        val ZERO_VAL = eUtc()
        val DB_ZERO_VAL = eUtc(1900,1,1,0,0,0)

        private fun milliSecParse(s:String) =  when(s.length) {
            1 -> s.toInt()*100
            2 -> s.toInt()*10
            3 -> s.toInt()
            0 -> 0
            else -> s.substring(0,3).toInt()
        }

        private fun microSecParse(s:String) =  when(s.length) {
            1 -> s.toInt()*100000
            2 -> s.toInt()*10000
            3 -> s.toInt()*1000
            4 -> s.toInt()*100
            5 -> s.toInt()*10
            6 -> s.toInt()
            0 -> 0
            else -> s.substring(0,6).toInt()
        }

        private fun groupToEUtc(match: MatchResult, subFractionConv:(String)->Int):eUtc {
            val m = match.groupValues.drop(1)
            val year = m[0].toInt()
            val month = m[1].toInt()
            val day = m[2].toInt()
            val hourStr = m[3]
            val minStr = m[4]
            val secStr = m[5]
            val milliStr = m[6]

            return if(hourStr.isBlank()) eUtc(year, month, day)
            else if(minStr.isBlank()) eUtc(year, month, day, hourStr.toInt())
            else if(secStr.isBlank()) eUtc(year, month, day, hourStr.toInt(), minStr.toInt())
            else if(milliStr.isBlank()) eUtc(year, month, day, hourStr.toInt(), minStr.toInt())
            else eUtc(year, month, day, hourStr.toInt(), minStr.toInt(), secStr.toInt(), subFractionConv(milliStr))
        }

        private val DEFAULT = of("1900-01-01T00:00:00:000Z")!!
        fun default():eUtc = DEFAULT
        fun isDefault(target:eUtc):Boolean = target == DEFAULT

        fun of(dateStr:String):eUtc? =
            (rISO.matchEntire(dateStr)?:rDbLocal.matchEntire(dateStr))?.let {
                groupToEUtc(it, eUtc::milliSecParse)
            } ?: (rISOMicro.matchEntire(dateStr)?: rDbLocalMicro.matchEntire(dateStr))?.let {
                groupToEUtc(it, eUtc::microSecParse)
            }

        fun diff(interval:String, d1:eUtc, d2:eUtc):Long =
            when(val ii=interval.lowercase()){
                "y"->(d2.yearUTC - d1.yearUTC).toLong()
                "m"->((d2.yearUTC - d1.yearUTC) * 12 + d2.monthUTC - d1.monthUTC).toLong()
                else -> {
                    val time1 = d1.timeInMilli()
                    val time2 = d2.timeInMilli()
                    when (ii) {
                        "h" -> (time2 - time1) / 3600000L
                        "i" -> (time2 - time1) / 60000L
                        "s" -> (time2 - time1) / 1000L
                        "ms" -> time2 - time1
                        "d" -> {
                            val order = if (time2 > time1) 1 else -1
                            val date1 = if (order == 1) d1 else d2
                            val date2 = if (order == 1) d2 else d1
                            val d1Year = date1.yearUTC
                            val d1Month = date1.monthUTC
                            val d1Date = date1.dateUTC
                            val d2Year = date2.yearUTC
                            val d2Month = date2.monthUTC
                            val d2Date = date2.dateUTC
                            val j = d2Year - d1Year
                            var d = 0L
                            if (j > 0) {
                                d += diff("d", eUtc(d1Year, d1Month, d1Date), eUtc(d1Year, 12, 31))
                                d += diff("d", eUtc(d2Year, 1, 1), eUtc(d2Year, d2Month, d2Date))
                                var year = d1Year + 1
                                var i = 1
                                while (i < j) {
                                    d += if (_leapYear(year)) 366 else 365
                                    i++
                                    year++
                                }
                            } else {
                                val temp = arrayOf(0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31)
                                if (_leapYear(d1Year)) temp[2]++
                                val j = d2Month - d1Month
                                if (j > 0) {
                                    d += diff(
                                        "d",
                                        eUtc(d1Year, d1Month, d1Date),
                                        eUtc(d1Year, d1Month, temp[d1Month])
                                    ) + 1
                                    d += diff(
                                        "d",
                                        eUtc(d2Year, d2Month, 1),
                                        eUtc(d2Year, d2Month, d2Date)
                                    )
                                    var month = d1Month
                                    var i = 1
                                    while (i < j) {
                                        d += temp[month++]
                                        i++
                                    }
                                } else d += d2Date - d1Date
                            }
                            d * order
                        }

                        else -> err("invalid interval $interval")
                    }
                }
            }

        private fun _leapYear(v:Int)= (v% 4 == 0 && v % 100 != 0) || v % 400 == 0
    }
}

expect fun eUtc.Companion.now(): eUtc

expect fun eUtc.toLocalDateTime(zone: String): eLocalDateTime

expect fun eUtc.timeInMilli(): Long
