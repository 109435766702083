package radio

import app.CompApp
import app.Factory
import comp.CompPageTitle
import comp.input.CompRadio
import comp.input.compRadioInt
import compLabelInputSectionToggleRadioSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.click
import ein2b.core.view.eEntityHook
import ein2b.core.view.eView
import ein2b.core.view.html
import m42.model.HcInit
import org.w3c.dom.HTMLElement
import prop.compLabelInputSection

private enum class K{
    view1, view2, view3,
    input, error, submit, result
}
private val factory = Factory.htmlUrl("radio")
suspend fun RadioView() = eView(factory){ rootView ->

    CompPageTitle(rootView){ compTitle ->
        compTitle.title = "Radio"
    }
    rootView.sub(K.view1){ subView->
        //===================== 주소 선택(라디오)
        CompRadio<Int>{ comp ->
            comp.wrapperClass = "flex"
        }.also{
            it.comp(subView, K.input)
            it.setList{ comp ->
                listOf(
                    comp.item("주소록에서 선택", 1, isSelect = true, isDisabled = false),
                    comp.item("직접 입력", 2, isSelect = false, isDisabled = false)
                )
            }
            it.checkBlock = { idx ->
                eLaunch{
                   console.log("idx = $idx")
                }
            }
        }
    }


    rootView.sub(K.view2) {subView ->
        subView.compLabelInputSectionToggleRadioSet(K.input, "InputSectionToggle"){ comp ->
            comp.wrapperClass = "toggle-border"
            comp.itemClass = "toggle-border-item width7-1"
            comp.vali = comp.singleRule("오류다 오류")
            comp.afterInited = {
                comp.setList{
                    listOf(
                        comp.item("주소록에서 선택", 1, isSelect = false, isDisabled = false),
                        comp.item("직접 입력", 2, isSelect = false, isDisabled = false)
                    )
                }
            }
            comp.vali = comp.singleRule("버튼을 선택해 주세요.")
        }
    }
    rootView.sub(K.result)
    rootView.sub(K.submit){
        it.html = "밸리 확인"
        it.click = {_,_->
            eLaunch{
                CompApp.checkAll(
                    rootView.sub("${K.view1}.${K.input}").compRadioInt(),
                    rootView.sub("${K.view2}.${K.input}").compLabelInputSection{}.input
                )
            }
        }
    }




    rootView.addEntityHook(HcInit::class, object:eEntityHook<HTMLElement, HcInit>{
        override suspend fun invoke(view:eView<HTMLElement>, entity: HcInit){

        }
    })
}