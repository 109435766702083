package text

import app.Factory
import comp.CompPageTitle
import comp.compInputSectionOneSet
import comp.input.CompInputText
import comp.input.compInputText
import ein2b.core.coroutine.eLaunch
import ein2b.core.validation.eRuleVali
import ein2b.core.view.click
import ein2b.core.view.eEntityHook
import ein2b.core.view.eView
import entity.EntHook
import m42.model.HcInit
import org.w3c.dom.HTMLElement
import prop.compInputSection

private enum class K{
    input, submit,
    view1, view2
}
private val factory = Factory.htmlUrl("text")
suspend fun TextView() = eView(factory){ rootView ->
    val ValiText = eRuleVali{
        Case{
            String()
            Trim()
            MinLength(1, "값을 입력해 주세요.")
            MaxLength(64, "값을 64자 이내로 입력해 주세요.")
        }
    }
    CompPageTitle(rootView){ compTitle ->
        compTitle.title = "Text"
    }
    rootView.sub(K.view1){ viewView->
        CompInputText{ comp->
            comp.placeholder = "플레이스 홀더"
            comp.vali = ValiText
        }.also{
            it.comp(viewView, K.input)
        }
        viewView.sub(K.submit).click = { _, _->
            eLaunch{
                viewView.sub(K.input).compInputText{ comp->
                    comp.error(comp.check())
                }
            }
        }
    }
    rootView.sub(K.view2){ viewView->
        viewView.compInputSectionOneSet<Int>(K.input, CompInputText{ comp->
            comp.placeholder = "플레이스 홀더"
            comp.vali = ValiText
        })
        viewView.sub(K.submit).click = { _, _->
            eLaunch{
                viewView.sub(K.input).compInputSection<Int>{ comp->
                    comp.check()
                }
            }
        }
    }

    rootView.addEntityHook(EntHook::class, object:eEntityHook<HTMLElement, EntHook>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntHook){

        }
    })
    rootView.addEntityHook(HcInit::class, object:eEntityHook<HTMLElement, HcInit>{
        override suspend fun invoke(view:eView<HTMLElement>, entity: HcInit){

        }
    })
}