package ein2b.core.entity.field.entity

import ein2b.core.entity.Report
import ein2b.core.entity.eEntity
import ein2b.core.entity.field.eField


class EntityMap<T: eEntity>(override val entity: eEntity, internal val factory:()->T): eField<MutableMap<String, T>>() {
    override fun init():String {
        v = hashMapOf<String, Any>()
        return "emap"
    }
    override fun fromJSON(k:String?, vStr:String, str:String, report: Report): Boolean = true
    override fun toJSON():String {
        val it = v as MutableMap<*, *>
        return "{${
            if(it.isEmpty()) "" else{
                var acc = ""
                it.forEach {(k, v)->acc = "$acc,\"$k\":${(v as? eEntity)?.stringify()}"}
                acc.substring(1)
            }
        }}"
    }
}