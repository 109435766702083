package ein2b.core.entity

import ein2b.core.core.err
import ein2b.core.date.eUtc
import ein2b.core.entity.field.eField
import ein2b.core.entity.field.entity.*
import ein2b.core.entity.field.list.*
import ein2b.core.entity.field.map.*
import ein2b.core.entity.field.value.*
import ein2b.core.entity.eEntity
import ein2b.core.validation.eVali
import kotlin.properties.PropertyDelegateProvider
import kotlin.reflect.KProperty


abstract class eEntity(isOrderedMap:Boolean = false){
    companion object:eEntityParser
    open var notifyChange:((field:KProperty<*>, new:Any, old:Any?)->Unit)? = null
    val props:MutableMap<KProperty<*>, eField<*>> = if(isOrderedMap) mutableMapOf() else hashMapOf()
    fun stringify():String{
        var acc = ""
        for((k, v) in props) {
            if (v.isActive && v.ic?.invoke(v.v!!) != false) acc = "$acc,\"${k.name}\":${v.toJSON()}"
        }
        return "{${if(acc.isNotEmpty()) acc.substring(1) else acc}}"
    }
    inline fun forEachEx(vararg exKey:String, block:(String,Any)->Unit) {
        for((k, v) in props) {
            if(k.name !in exKey) {
                if (v.isActive) block(k.name, v.v!!) else err("not initialized ${k.name}")
            }
        }
    }
    inline fun forEach(vararg keys:String, block:(String,Any)->Unit) {
        for((k, v) in props) {
            if(k.name in keys) {
                if (v.isActive) block(k.name, v.v!!) else err("not initialized ${k.name}")
            }
        }
    }
    fun getVali(key:KProperty<*>):eVali{
        for((k, v) in props){
            if(k.name == key.name) return v.rule ?: err("exist field but no rule :${key.name}")
        }
        err("no field :${key.name}")
    }
    fun setVali(key:KProperty<*>, vali:eVali){
        for((k, v) in props){
            if(k.name == key.name) return v.validator(vali)
        }
    }

    protected inline fun <reified T, reified FieldValueType: eField<T>> delegateProvide(crossinline factory: (eEntity)->FieldValueType, crossinline block: FieldValueType.()->Unit = {}) =
        PropertyDelegateProvider<eEntity, FieldValueType> { thisRef, prop ->
            if (prop in props) err("exists field, ${FieldValueType::class.simpleName}:${prop.name}")
            val f = factory(thisRef)
            props[prop] = f
            f.fieldName = prop.name
            f.block()
            f
        }

    protected inline fun bool(crossinline block: BooleanValue.()->Unit = {}) = delegateProvide(::BooleanValue,block)
    protected inline fun double(crossinline block: DoubleValue.()->Unit = {}) = delegateProvide(::DoubleValue,block)
    protected inline fun float(crossinline block: FloatValue.()->Unit = {}) = delegateProvide(::FloatValue,block)
    protected inline fun int(crossinline block: IntValue.()->Unit = {}) = delegateProvide(::IntValue,block)
    protected inline fun ulong(crossinline block: ULongValue.()->Unit = {}) = delegateProvide(::ULongValue,block)
    protected inline fun utc(crossinline block: UtcValue.()->Unit = {}) = delegateProvide(::UtcValue,block)
    protected inline fun long(crossinline block: LongValue.()->Unit = {}) = delegateProvide(::LongValue,block)
    protected inline fun short(crossinline block: ShortValue.()->Unit = {}) = delegateProvide(::ShortValue,block)
    protected inline fun string(crossinline block: StringValue.()->Unit = {}) = delegateProvide(::StringValue,block)
    protected inline fun boolList(crossinline block:BooleanList.()->Unit = {}) = delegateProvide(::BooleanList,block)
    protected inline fun doubleList(crossinline block:DoubleList.()->Unit = {}) = delegateProvide(::DoubleList,block)
    protected inline fun floatList(crossinline block: FloatList.()->Unit = {}) = delegateProvide(::FloatList,block)
    protected inline fun intList(crossinline block: IntList.()->Unit = {}) = delegateProvide(::IntList,block)
    protected inline fun longList(crossinline block: LongList.()->Unit = {}) = delegateProvide(::LongList,block)
    protected inline fun shortList(crossinline block: ShortList.()->Unit = {}) = delegateProvide(::ShortList,block)
    protected inline fun stringList(crossinline block: StringList.()->Unit = {}) = delegateProvide(::StringList,block)
    protected inline fun boolMap(crossinline block: BooleanMap.()->Unit = {}) = delegateProvide(::BooleanMap,block)
    protected inline fun doubleMap(crossinline block: DoubleMap.()->Unit = {}) = delegateProvide(::DoubleMap,block)
    protected inline fun floatMap(crossinline block: FloatMap.()->Unit = {}) = delegateProvide(::FloatMap,block)
    protected inline fun intMap(crossinline block: IntMap.()->Unit = {}) = delegateProvide(::IntMap,block)
    protected inline fun longMap(crossinline block: LongMap.()->Unit = {}) = delegateProvide(::LongMap,block)
    protected inline fun shortMap(crossinline block: ShortMap.()->Unit = {}) = delegateProvide(::ShortMap,block)
    protected inline fun stringMap(crossinline block: StringMap.()->Unit = {}) = delegateProvide(::StringMap,block)

    protected inline fun <T: eEntity> entity(noinline factory:()->T, crossinline block: EntityField<T>.()->Unit = { }) =
        PropertyDelegateProvider<eEntity, EntityField<T>> { thisRef, prop ->
            if (prop in props) err("exists field, EntityField<>:${prop.name}")
            val f = EntityField(thisRef, factory)
            props[prop] = f
            f.block()
            f
        }

    protected inline fun <T: eEntity> entityList(noinline factory:()->T, crossinline block: EntityList<T>.()->Unit = { }) =
        PropertyDelegateProvider<eEntity, EntityList<T>> { thisRef, prop ->
            if (prop in props) err("exists field, EntityList<>:${prop.name}")
            val f = EntityList(thisRef, factory)
            props[prop] = f
            f.block()
            f
        }

    protected inline fun <T: eEntity> entityMap(noinline factory:()->T, crossinline block: EntityMap<T>.()->Unit = { }) =
        PropertyDelegateProvider<eEntity, EntityMap<T>> { thisRef, prop ->
            if (prop in props) err("exists field, EntityMap<>:${prop.name}")
            val f = EntityMap(thisRef, factory)
            props[prop] = f
            f.block()
            f
        }


    protected inline fun <R:eEntity, T: eEntityUnion<R>> entity(union:T, crossinline block: EntityUnion<T, R>.()->Unit = { }) =
        PropertyDelegateProvider<eEntity, EntityUnion<T,R>> { thisRef, prop ->
            if(prop in props) err("exists field, union:${prop.name}, union:$union")
            val field = EntityUnion(thisRef, union)
            props[prop] = field
            field.block()
            field
        }

    protected inline fun <R: eEntity, T: eEntityUnion<R>> entityList(union:T, crossinline block: EntityUnionList<T, R>.()->Unit = { }) =
        PropertyDelegateProvider<eEntity, EntityUnionList<T,R>> { thisRef, prop ->
            if (prop in props) err("exists field, unionList:${prop.name}, union:$union")
            val field = EntityUnionList(thisRef, union)
            props[prop] = field
            field.block()
            field
        }

    protected inline fun <R: eEntity, T: eEntityUnion<R>> entityMap(union:T, crossinline block: EntityUnionMap<T, R>.()->Unit = { }) =
        PropertyDelegateProvider<eEntity, EntityUnionMap<T,R>> { thisRef, prop ->
            if (prop in props) err("exists field, unionMap:${prop.name}, union:$union")
            val field = EntityUnionMap(thisRef, union)
            props[prop] = field
            field.block()
            field
        }
}

interface eEntityValidator {
    // warning: DO NOT OVERRIDE
    fun validate(callback: (String)->Unit) = validateEntity()?.let(callback)
    fun validateEntity(): String?
}