package ein2b.core.date

import ein2b.core.core.err
import ein2b.core.core.pad0
import ein2b.core.log.log


interface eDateTime {
    val year:Int
    val month:Int  // 1..12
    val date:Int
    val dayOfWeek:Int
    val hour:Int
    val minute:Int
    val second:Int
    val millisecond:Int
    val time:Long

    val yearUTC:Int
    val monthUTC:Int  // 1..12
    val dateUTC:Int
    val dayUTC:Int
    val hourUTC:Int
    val minuteUTC:Int
    val secondUTC:Int
    val millisecondUTC:Int

    val timezoneOffset:Int

    fun toUtcString() = "${yearUTC.pad0(4)}-${monthUTC.pad0(2)}-${dateUTC.pad0(2)}T${hourUTC.pad0(2)}:${minuteUTC.pad0(2)}:${secondUTC.pad0(2)}.${millisecondUTC.pad0(3)}Z"

    fun toUtc() = eUtc(yearUTC, monthUTC, dateUTC, hourUTC, minuteUTC, secondUTC, millisecondUTC)

    fun toLocalString() = "${year.pad0(4)}-${month.pad0(2)}-${date.pad0(2)} ${hour.pad0(2)}:${minute.pad0(2)}:${second.pad0(2)}.${millisecond.pad0(3)}"
    fun yyyy_mm_dd() = "${year.pad0(4)}-${month.pad0(2)}-${date.pad0(2)}"
    fun yyyymmdd() = "${year.pad0(4)}${month.pad0(2)}${date.pad0(2)}"
    fun yyyy_mm() = "${year.pad0(4)}-${month.pad0(2)}"
    fun mm_dd() = "${month.pad0(2)}${date.pad0(2)}"
    fun hh_mm() = "${hour.pad0(2)}:${minute.pad0(2)}"

    fun hh_mm_utc() = "${hourUTC.pad0(2)}:${minuteUTC.pad0(2)}"

    fun yyyy_mm_dd_hh_mm() = "${year.pad0(4)}-${month.pad0(2)}-${date.pad0(2)} ${hour.pad0(2)}:${minute.pad0(2)}"

    fun amOrPm() = if(hour > 11) "오후" else "오전"

    fun withYear(v: Int): eDateTime
    fun withMonth(v: Int): eDateTime
    fun withDate(v: Int): eDateTime
    fun withHour(v: Int): eDateTime
    fun withMinute(v: Int): eDateTime
    fun withSecond(v: Int): eDateTime
}
//expect fun eDateFactory():eDateTime
//expect fun eDateFactory(time:Long):eDateTime
//expect fun eDateFactory(v:String):eDateTime
//expect fun eDateFactory(year:Int, month:Int, date:Int, hour:Int = 0, minute:Int = 0, second:Int = 0, millisecond:Int = 0):eDateTime

abstract class eAbstractDateT{
    abstract fun mylog(s:String)
    abstract fun eDateFactory():eDateTime
    abstract fun eDateFactory(time:Long):eDateTime
    abstract fun eDateFactory(v:String):eDateTime  // UTC 문자열이라는 점에 유의할것!
    abstract fun eDateFactory(year:Int, month:Int, date:Int, hour:Int = 0, minute:Int = 0, second:Int = 0, millisecond:Int = 0):eDateTime

    val zone = eDateFactory().timezoneOffset * 60000
    //'T'가 있으면 UTC라고 가정함
    val rISO = """^(?:\d{1,4})-(?:1[0-2]|0?\d)-(?:3[01]|[12]\d|0?\d)T(?:2[0-3]|1\d|0?\d):(?:[1-5]\d|0?\d)(?::(?:[1-5]\d|0?\d)(?:.\d{1,3})?)?Z?$""".toRegex()
    //val rDate = """^(\d{1,4})-(1[0-2]|0\d|\d)-(30|31|[12]\d|0?\d)(?: (2[0-3]|1\d|0?\d):([1-5]\d|0?\d):([1-5]\d|0?\d)| (2[0-3]|1\d|0?\d):([1-5]\d|0?\d)| (2[0-3]|1\d|0?\d))?$""".toRegex()
    val rDate = """(\d{1,4})-(1[0-2]|0?\d)-(3[01]|[12]\d|0?\d) (2[0-3]|1\d|0?\d):([1-5]\d|0?\d)(?::([1-5]\d|0?\d)(?:.(\d{1,3}))?)?""".toRegex()

    operator fun invoke(d:String, isUTC:Boolean):eDateTime{
        return _get(d, isUTC)
    }

    operator fun invoke(d:eUtc):eDateTime{
        return _get(d.toString(), true)
    }
    private enum class DAY_KIND(val day:Int, val title:String){
        SUN(0, "일"),
        MON(1, "월"),
        TUE(2, "화"),
        WEN(3, "수"),
        THU(4, "목"),
        FRI(5, "금"),
        SAT(6, "토");
        companion object{
            operator fun invoke(day:Int) = values().find{ it.day == (day%7) } ?: err("Wrong DAY_KIND value = $day")
        }
    }

    private fun milliSecParse(s:String) =  when(s.length) {
        1 -> s.toInt()*100
        2 -> s.toInt()*10
        3 -> s.toInt()
        0 -> 0
        else -> s.substring(0,3).toInt()
    }

    private fun microSecParse(s:String) =  when(s.length) {
        1 -> s.toInt()*100000
        2 -> s.toInt()*10000
        3 -> s.toInt()*1000
        4 -> s.toInt()*100
        5 -> s.toInt()*10
        6 -> s.toInt()
        0 -> 0
        else -> s.substring(0,6).toInt()
    }

    private fun _get(d:String, isUTC:Boolean):eDateTime{
        val date = d.trim()
        if(rISO.containsMatchIn(date)) {
            return eDateFactory(date.trim().let{if(it.last().equals('Z',false)) it else "${it}Z"})
        } else{
            rDate.find(date)?.let{
                val m = it.groupValues.drop(1)
                val year = m[0].toInt()
                val month = m[1].toInt()
                val day = m[2].toInt()
                val hourStr = m[3]
                val minStr = m[4]
                val secStr = m[5]
                val milliStr = m[6]

                val i = if(hourStr.isBlank()) eDateFactory(year, month, day)
                else if(minStr.isBlank()) eDateFactory(year, month, day, hourStr.toInt())
                else if(secStr.isBlank()) eDateFactory(year, month, day, hourStr.toInt(), minStr.toInt())
                else if(milliStr.isBlank()) eDateFactory(year, month, day, hourStr.toInt(), minStr.toInt(), secStr.toInt())
                else eDateFactory(year, month, day, hourStr.toInt(), minStr.toInt(), secStr.toInt(), milliSecParse(milliStr))
                return if(isUTC) eDateFactory(i.time - zone) else i
            } ?: err("eDateTime invalid date $d")
        }
    }
    private fun toUTC(edt:eDateTime):String{
        return eDateFactory(edt.time - zone).toString()
    }
    private fun String.right(v:Int):String = this.substring(this.length - v)
    private fun _get(d:eDateTime, isUTC: Boolean) = eDateFactory(d.time)
    private fun _leapYear(v:Int)= (v% 4 == 0 && v % 100 != 0) || v % 400 == 0

    private val _da = hashMapOf<String, (Boolean, eDateTime)->String>(
        "Y" to {UTC, d->"${if(UTC) d.yearUTC else d.year}"},
        "n" to {UTC, d->"${(if(UTC) d.monthUTC else d.month)}"},
        "j" to {UTC, d->"${if(UTC) d.dateUTC else d.date}"},
        "G" to {UTC, d->"${if(UTC) d.hourUTC else d.hour}"},
        "g" to {UTC, d->
            val h = if(UTC) d.hourUTC else d.hour
            var hour = h % 12
            if(h > 11 && hour == 0) hour = 12
            "$hour"
        },
        "i" to {UTC, d->"00${if(UTC) d.minuteUTC else d.minute}".right(2)},
        "s" to {UTC, d->"00${if(UTC) d.secondUTC else d.second}".right(2)},
        "u" to {UTC, d->"${d.millisecond}".right(3)},
        "w" to {UTC, d->DAY_KIND(d.dayOfWeek).title},
        "p" to {UTC, d-> (if(UTC) d.hourUTC else d.hour).let{ if(it >= 12) "오후" else "오전"} },
    )
    @Suppress("UnsafeCastFromDynamic")
    private val addKey = mapOf<String, (Int, eDateTime)->eDateTime>(
        "y" to {v, d->d.withYear(d.year + v)},
        "m" to {v, d->d.withMonth(d.month + v)},
        "d" to {v, d->d.withDate(d.date + v)},
        "h" to {v, d->d.withHour(d.hour + v)},
        "i" to {v, d->d.withMinute(d.minute + v)},
        "s" to {v, d->d.withSecond(d.second + v)},
    )
    init{
        _da += "y" to {UTC, d->"${_da["Y"]?.invoke(UTC, d)?.substring(2)}"}
        _da += "m" to {UTC, d->"00${_da["n"]?.invoke(UTC, d)}".right(2)}
        _da += "d" to {UTC, d->"00${_da["j"]?.invoke(UTC, d)}".right(2)}
        _da += "H" to {UTC, d->"00${_da["G"]?.invoke(UTC, d)}".right(2)}
        _da += "h" to {UTC, d->"00${_da["g"]?.invoke(UTC, d)}".right(2)}
    }
    fun addYear(v:Int, date:eDateTime, isUTC:Boolean= false) = add("y", v, _get(date, isUTC))
    fun addYear(v:Int, date:String, isUTC:Boolean= false) = add("y", v, _get(date, isUTC))
    fun addMonth(v:Int, date:eDateTime, isUTC:Boolean= false) = add("m", v, _get(date, isUTC))
    fun addMonth(v:Int, date:String, isUTC:Boolean= false) = add("m", v, _get(date, isUTC))
    fun addDay(v:Int, date:eDateTime, isUTC:Boolean= false) = add("d", v, _get(date, isUTC))
    fun addDay(v:Int, date:String, isUTC:Boolean= false) = add("d", v, _get(date, isUTC))
    fun add(key:String, v:Int, date:String, isUTC:Boolean= false) = add(key, v, _get(date, isUTC))
    fun add(key:String, v:Int, date:eDateTime, isUTC:Boolean= false) = add(key, v, _get(date, isUTC))
    private fun add(key:String, v:Int, date:eDateTime) = addKey[key]?.invoke(v, date) ?: err("invalid key $key")
    fun part(part:String, date:String, isFromUTC:Boolean=false, isToUTC:Boolean= false):String{
        val d = _get(date, isFromUTC)
        val temp = (if(part.isBlank()) "Y-m-d H:i:s" else part).fold(""){acc, c->
            acc + (_da["$c"]?.invoke(isToUTC, d) ?: c)
        }
        return temp
    }
    fun part(part:String, date:eDateTime, isFromUTC:Boolean=false, isToUTC: Boolean=false):String{
        val d = _get(date, isFromUTC)
        return (if(part.isBlank()) "Y-m-d H:i:s" else part).fold(""){acc, c->
            acc + (_da["$c"]?.invoke(isToUTC, d) ?: c)
        }
    }
    fun part(part:String, date:eUtc, isToUTC:Boolean=false):String = part(part, date.toString(), true, isToUTC)

    fun diff(interval:String, dateOld:eDateTime, isUTCOld:Boolean, dateNew:eDateTime, isUTCNew:Boolean) =
        diff(interval, _get(dateOld, isUTCOld), _get(dateNew, isUTCNew))
    fun diff(interval:String, dateOld:String, isUTCOld:Boolean, dateNew:eDateTime, isUTCNew:Boolean) =
        diff(interval, _get(dateOld, isUTCOld), _get(dateNew, isUTCNew))
    fun diff(interval:String, dateOld:eDateTime, isUTCOld:Boolean, dateNew:String, isUTCNew:Boolean) =
        diff(interval, _get(dateOld, isUTCOld), _get(dateNew, isUTCNew))
    fun diff(interval:String, dateOld:String, isUTCOld:Boolean, dateNew:String, isUTCNew:Boolean) =
        diff(interval, _get(dateOld, isUTCOld), _get(dateNew, isUTCNew))
    fun diff(interval:String, dateOld:String, isUTCOld:Boolean = false) =
        diff(interval, _get(dateOld, isUTCOld), _get(eDateFactory(), isUTCOld))
    fun diff(interval:String, dateOld:eDateTime, isUTCOld:Boolean = false) =
        diff(interval, _get(dateOld, isUTCOld), _get(eDateFactory(), isUTCOld))
    private fun diff(interval:String, d1:eDateTime, d2:eDateTime):Long{
        when(interval.lowercase()){
            "y"->return (d2.year - d1.year).toLong()
            "m"->return ((d2.year - d1.year) * 12 + d2.month - d1.month).toLong()
            "h"->return (d2.time - d1.time) / 3600000L
            "i"->return (d2.time - d1.time) / 60000L
            "s"->return (d2.time - d1.time) / 1000L
            "ms"->return d2.time - d1.time
            "d"-> {
                val order = if (d2.time > d1.time) 1 else -1
                val date1 = if(order == 1) d1 else d2
                val date2 = if(order == 1) d2 else d1
                val d1Year = date1.year
                val d1Month = date1.month
                val d1Date = date1.date
                val d2Year = date2.year
                val d2Month = date2.month
                val d2Date = date2.date
                val j = (d2Year - d1Year).toLong()
                var d = 0L
                if (j > 0) {
                    d += diff("d", eDateFactory(d1Year, d1Month, d1Date), eDateFactory(d1Year, 11, 31))
                    d += diff("d", eDateFactory(d2Year, 1, 1), eDateFactory(d2Year, d2Month, d2Date))
                    var year = d1Year + 1
                    var i = 1L
                    while(i < j){
                        d += if(_leapYear(year)) 366 else 365
                        i++
                        year++
                    }
                } else {
                    val temp = arrayOf(31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31)
                    if(_leapYear(d1Year)) temp[1]++
                    val j = (d2Month - d1Month).toLong()
                    if (j > 0) {
                        d += diff("d", eDateFactory(d1Year, d1Month, d1Date), eDateFactory(d1Year, d1Month, temp[d1Month-1])) + 1
                        d += diff("d", eDateFactory(d2Year, d2Month, 1), eDateFactory(d2Year, d2Month, d2Date))
                        var month = d1Month + 1
                        var i = 1L
                        while(i < j) {
                            d += temp[month++]
                            i++
                        }
                    } else d += d2Date - d1Date
                }
                return d * order
            }
            else->err("invalid interval $interval")
        }
    }
}
