package m42.common

import ein2b.core.entity.eEntity
import ein2b.core.net.*
import ein2b.js.browser.eLocalStorage
import ein2b.js.browser.eSessionStorage
import m42.common.M42Api.midKey
import m42.common.api.EntM42Api
import m42.common.api.EntM42Api.Companion.ENTITY_KEY
import m42.common.api.EntM42Api.Companion.FILE_KEY
import m42.common.api.EntM42ApiResMember
import m42.common.api.EntM42ApiResponse
import kotlin.js.Date

object M42Api{
    lateinit var storageKey:String
    lateinit var domain:String
    var blockingOpen:(()->Unit)? = null
    var blockingClose:(()->Unit)? = null
    var apiMoveToBlock:(()->Unit)? = null
    var loginPage = true
    var sessionUserId:String
        get() = eSessionStorage[storageKey] ?: ""
        set(v){ eSessionStorage[storageKey] = v }
    var userId:String
        get():String  = eLocalStorage[storageKey] ?: ""
        set(v){ eLocalStorage[storageKey] = v }
    var isLogin = false
    var member = EntM42ApiResMember.Login()
    var midKey = M42ApiNet.MID_KEY
    init{
        eApi.sender = FetchSender()
    }
}
abstract class M42ApiNet<REQ: eEntity, RES: eEntity>(private val apiObject:EntM42Api<REQ, RES>){
    companion object{
        val timeOffset = "${Date().getTimezoneOffset()}"
        const val MID_KEY = "mid"
    }
    abstract suspend fun goHome()
    abstract suspend fun responseProcess(result: eApiResult):RES?

    var headers = mutableListOf(midKey)
    private val apiUrl = "${M42Api.domain}${apiObject.url()}"
    private var api:eApi = eApi("", eApi.DEFAULT to eApiInfo{
        method = eApi.POST
        url = apiUrl

        items += headers
        items += ENTITY_KEY
        items += apiObject.apiItems
        //if(FILE_KEY in apiObject.apiItems) requestTask += eRequestTask.BlobFile(FILE_KEY)
        apiObject.apiItems.filter{ it.indexOf(FILE_KEY) == 0 }.let{
            if(it.isNotEmpty()) requestTask += eRequestTask.BlobFile(*it.toTypedArray())
        }

        requestTask += eRequestTask.ReadTimeOut(apiObject.readTimeOut)
        requestTask += eRequestTask.JsonFromEntity(ENTITY_KEY)
        requestTask += eRequestTask.Header(*headers.toTypedArray())

        responseTask += eResponseTask.Text
        responseTask += eResponseTask.Entity{ EntM42ApiResponse{ apiObject.response() } }
    })
    suspend fun net(vararg items:Pair<String,Any>, block:(suspend (req:REQ)->Unit)? = null):RES?{
        val req = apiObject.request()
        M42Api.blockingOpen?.invoke()
        block?.invoke(req)
        if(apiObject.rscKeys.isNotEmpty()) M42ApiRsc.rsc(*apiObject.rscKeys.toTypedArray())

        val argItems = mutableListOf(midKey to M42Api.sessionUserId, ENTITY_KEY to { req })
        items.find{ it.first == ENTITY_KEY }?.also{
            argItems.forEachIndexed{ idx,d->
                if(d.first == ENTITY_KEY){
                    argItems.removeAt(idx)
                    return@forEachIndexed
                }
            }
        }
        items.forEach{ argItems += it }

        return if(checkSession()) responseProcess(api(*argItems.toTypedArray())).also{ M42Api.blockingClose?.invoke() } else null
    }

    private suspend fun checkSession():Boolean{
        val newId = M42Api.userId
        if(newId != M42Api.sessionUserId){
            M42Api.sessionUserId = newId
            if(newId.isNotBlank() && M42Api.loginPage){
                goHome()
                return false
            }
        }
        return true
    }
    fun localStorageRsc(){
        M42ApiRsc.localStorageRsc(*apiObject.rscKeys.toTypedArray())
    }
}