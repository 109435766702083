package ein2b.core.view

import ein2b.core.core.err
import ein2b.core.core.uuid
import ein2b.core.log.log
import ein2b.js.dom.getChildAt
import ein2b.js.dom.indexOfChild
import ein2b.js.dom.toList
import kotlinx.browser.window
import org.w3c.dom.HTMLElement
import org.w3c.dom.asList
class eDomBinder:eBinder<HTMLElement>() {
    companion object{
        const val VIEW = "data-view"
        const val CDATA = "data-cdata"
        private val stop = Stop()
    }
    class Stop{
        internal var isStop = false
        fun stop(){isStop = true}
    }
    private class RenderJob(val list:MutableList<RenderJob>, val term:Double, val block:(Stop)->Unit){
        private var prev = -100000.0
        operator fun invoke(curr:Double){
            if(curr - prev > term){
                prev = curr
                stop.isStop = false
                block(stop)
                if(stop.isStop) list -= this
            }
        }
    }
    private var isStop = true
    private val renderJob = mutableListOf<RenderJob>()
    private fun render(){
        window.requestAnimationFrame {t->
            if(renderQue.isNotEmpty()) {
                renderQue.forEach { Property(it) }
                renderQue.clear()
            }
            if(renderJob.isNotEmpty()) renderJob.forEach { it(t) }
            if(!isStop) render()
        }
    }
    fun addRenderJob(term:Double = 0.0, block:(Stop)->Unit){renderJob += RenderJob(renderJob, term, block)}
    fun start(){
        if(!isStop) return
        isStop = false
        render()
    }
    fun stop(){
        if(!isStop) return
        isStop = true
    }
    override suspend fun bind(root:HTMLElement, view:eView<HTMLElement>):List<eViewItem<HTMLElement>>{
        val items = mutableListOf<eViewItem<HTMLElement>>()
        val keyViews = hashMapOf<String, HTMLElement>()
        root.getAttribute(VIEW)?.let {k->
            items += eViewItem(k, view.subView(k).bindView(keyViews, root), mutableListOf())
        }
        root.getAttribute(CDATA)?.let {key->
            val k = uuid()
            val subView = view.sub(k){it.html = "c@$key"}
            items += eViewItem(k, subView.bindView(keyViews, root), mutableListOf())
        }
        var node = root.querySelectorAll("[$CDATA]")
        var i = 0
        while(i < node.length){
            val el = node.item(i++)!! as HTMLElement
            val key = el.getAttribute(CDATA)!!
            if(key.isBlank()) err("no cdata key")
            val pos = ArrayList<Int>()
            var target = el
            var limit = 30
            while (target !== root && limit-- > 0) {
                (target.parentElement as? HTMLElement)?.let {
                    pos += it.indexOfChild(target)
                    target = it
                } ?: break
            }
            val k = uuid()
            val subView = view.sub(k){it.html = "c@$key"}
            items += eViewItem(k, subView.bindView(keyViews, el), pos, true)
        }
        node = root.querySelectorAll("[$VIEW]")
        i = 0
        while(i < node.length){
            val el = node.item(i++)!! as HTMLElement
            val k = el.getAttribute(VIEW)!!
            if(k.isBlank()) err("blank key el:${describe(el)},root:${describe(root)}")
            val pos = mutableListOf<Int>()
            var target = el
            var limit = 30
            while (target !== root && limit-- > 0) {
                (target.parentElement as? HTMLElement)?.let {
                    pos += it.indexOfChild(target)
                    target = it
                } ?: break
            }
            el.removeAttribute("data-view")
            el.setAttribute("data-v", k)
            val subView = view.subView(k)
            @Suppress("UnsafeCastFromDynamic")
            val tmpl = subView.factory?.let{
                subView.setTemplate()
                val tmpl = subView.template!!
                val attr = el.attributes.asDynamic()
                var i = 0
                val j = attr.length as Int
                while(i < j){
                    val curr = attr[i++]
                    if(curr.name != "data-view") tmpl.setAttribute(curr.name, curr.value)
                }
                swapView(el, tmpl)
                tmpl
            } ?: el
            items += eViewItem(k, subView.bindView(keyViews, tmpl), pos)
        }
        return items
    }
    override suspend fun rebind(root:HTMLElement, view:eView<HTMLElement>){
        val keyViews = hashMapOf<String, HTMLElement>()
        view.items?.forEach {
            var el = root
            var i = it.pos.size
            while (i-- > 0) el = el.getChildAt(it.pos[i]) ?: err("invalid idx:${it.pos[i]} in ${el.outerHTML}, pos ${it.pos}, root ${root.outerHTML}")
            it.target.bindView(keyViews, el, it.k.isNotEmpty())
        }
    }
    override fun changeView(parent: HTMLElement, child: HTMLElement, at: Int) {
        parent.getChildAt(at)?.let {
            parent.replaceChild(child, it)
        } ?: parent.appendChild(child)
    }
    override fun swapView(old:HTMLElement, new:HTMLElement){
        old.parentNode?.replaceChild(new, old)
    }
    override fun addView(parent: HTMLElement, child: HTMLElement) {
        parent.appendChild(child)
    }
    override fun removeView(parent:HTMLElement, child: HTMLElement) {
        if(parent === child.parentNode) parent.removeChild(child)
    }
    override fun removeAll(parent: HTMLElement) {
        parent.innerHTML = ""
    }
    override fun describe(template: HTMLElement): String {
        return template.outerHTML
    }
    private inline fun isWrapperProp(key:String):Boolean {
        return "html".indexOf(key) != -1
    }
    override fun cdataWrapper(key:String, str:String, view: eView<HTMLElement>?):String{
        return if(isWrapperProp(key)) """<span class="cdata-default">$str</span>""" else str
        /*val r = isWrapperProp(key)
        log("===cdataWrapper==${r}=:$key:$str===")
        view?.textDecoration = if(r) "line-through" else ""
        return str*/
    }
}