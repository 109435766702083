package ein2b.core.view.router

import ein2b.core.entity.eEntity


abstract class eRouter<T>(protected val base:eBase<T>){
    protected val factories = hashMapOf<String, suspend (eRouter<T>)-> ePage<T>>()
    operator fun contains(k:String) = factories.containsKey(k)
    fun addFactories(vararg f:Pair<String, suspend (eRouter<T>)-> ePage<T>>) {
        factories += f
    }
    abstract suspend fun prepare(k:String, arg:Map<String,String>? = null):Pair<eBaseOrder, ePage<T>>?
    abstract suspend operator fun invoke(order: eBaseOrder, page: ePage<T>, data: eRouterData)
    suspend fun _restore(){
        restore()
        base.restore()
    }
    suspend fun select(){
        base._select()
    }
    suspend fun deselect(){
        base._deselect()
    }
    suspend fun <RETURN> entityReturn(entity: eEntity, default:RETURN):RETURN{
        return base.entityReturn(entity) ?: default
    }
    protected abstract suspend fun restore()
}