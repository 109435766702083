package app

import comp.CompValueOut
import ein2b.core.core.err
import ein2b.core.view.eView
import org.w3c.dom.HTMLElement

object CompApp{
    fun <T: CompValueOut<*>> checkAll(vararg compList:T?):Boolean = compList.map{ comp -> comp?.check() ?:false }.all{ it }
}
suspend fun eView<HTMLElement>.clearCompValue(vararg subKeys:Any, isThrow:Boolean = true){
    subKeys.forEach {
        val sub = this.sub(it)
        val key = sub.props?.keys?.find{ "_value" in it }
        if(key == null){
            if(isThrow) err("clearCompValue invalid key : $key")
            else return@forEach
        }else{
            (sub.props!![key] as CompValueOut<*>).clear()
        }
    }
}