package view

import app.Factory
import app.eWindow
import ein2b.core.core.uuid
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import kotlinx.browser.sessionStorage
import m42.app.M42App
import m42.domBase.PageDummy
import m42.model.EntDummyOff
import m42.model.EntDummyOn
import org.w3c.dom.HTMLElement

object CompViewLnb{
    enum class K{
        logo, subTitle,
        user, user_info, user_profile, user_role, user_title, user_subTitle, user_menuList,
        user_menu_item, user_menu_icon, user_menu_title,
        menuList, menuWrap, menu_titleWrap, menu_title, menu_urlIcon, menu_foldBtn, menu_cnt, menu_info, menu_subList,
        menu_sub_item, menu_sub_title, menu_sub_new, menu_sub_cnt;
        override fun toString() = if ("_" in name) name.substring(name.lastIndexOf("_") + 1) else name
    }
    // language=html
    private val viewFactory = Factory.html("""
    <aside class="menu">
        <div class="logo-wrap">        
            <div data-view="${K.logo}"></div>
            <div data-view="${K.subTitle}" class="logo-sub-title ellipsis"></div>
        </div>
        <b data-view="${K.user}"></b>
        <ul data-view="${K.menuList}" class="menu-list scroll1"></ul>
    </aside>
    """)
    // language=html
    private val userFactory = Factory.html("""
    <div class="user">
        <div data-view="${K.user_info}" class="user-info flex-center">
            <div class="user-image flex-shrink-0">
                <div data-view="${K.user_profile}" class="user-profile dummy-image"></div>
                <div data-view="${K.user_role}" class="user-id dummy-image"></div>
            </div>
            <div class="margin-left8 flex-grow-1">
                <div data-view="${K.user_title}" class="user-title dummy-title ellipsis"></div>
                <div data-view="${K.user_subTitle}" class="user-sub-title dummy-title ellipsis"></div>
            </div>
            <div class="ic-menu margin-left8 flex-shrink-0"></div>
        </div>
        <div data-view="${K.user_menuList}" class="user-menu"></div>
    </div>
    """)
    // language=html
    private val userMenuFactory = Factory.html("""
    <li data-view="${K.user_menu_item}" class="user-menu-item flex-center">
        <div data-view="${K.user_menu_icon}" class="user-menu-icon margin-right12"></div>
        <div data-view="${K.user_menu_title}"></div>
    </li>
    """)
    // language=html
    private val menuFactory = Factory.html("""
    <li data-view="${K.menuWrap}" class="menu-item">
        <div data-view="${K.menu_titleWrap}" class='flex-between-center'>
            <div data-view="${K.menu_title}" class="menu-item-title dummy-title"></div>
            <div class='flex flex-shrink-0'>
                <div data-view="${K.menu_cnt}" class="sub-item-cnt margin-left12 flex-shrink-0 margin-right4"></div>
                <div data-view="${K.menu_urlIcon}" class="menu-item-icon"></div>
                <div data-view="${K.menu_foldBtn}" class="sub-menu ic-menu"></div>
            </div>
        </div>
        <div data-view="${K.menu_info}" class="menu-info dummy-title"></div>
        <ul data-view="${K.menu_subList}" class="sub-list"></ul>
    </li>
    """)
    // language=html
    private val menuSubFactory = Factory.html("""
        <li data-view="${K.menu_sub_item}" class="sub-item flex">
            <div data-view="${K.menu_sub_title}" class="dummy-title flex-grow-1"></div>
            <div data-view="${K.menu_sub_new}" class="sub-item-new margin-left12 flex-shrink-0"></div>
            <div data-view="${K.menu_sub_cnt}" class="sub-item-cnt margin-left12 flex-shrink-0"></div>
        </li>
    """)

    class MenuUser(val profileUrl:String, val roleUrl:String, val title:String, val subTitle:String){
        class UserMenu(val key:String, val title:String, val icon:String = "", val clickBlock:(()->Unit) = {})
        var menuList = mutableListOf<UserMenu>()
    }
    class MenuItem(val title:String, val key:String = "", val urlIcon:String = "", val info:String = "", val menuWrapClassName:String = "menu-item", val foldable:Boolean = false, val displayTotal:Boolean = false, val clickBlock:(()->Unit)? = null){
        class SubItem(val key:String, val title:String){
            var isSelect:Boolean = false
            var isNew:Boolean = false
            var cnt:String = ""
            var clickBlock:(()->Unit)? = null
        }
        var subList = mutableListOf<SubItem>()
        var unfolded = false
    }
    var menuList = mutableListOf<MenuItem>()
    var isInit = true
    var menuUser:MenuUser? = null

    private var key:String = ""
    lateinit var target:eView<HTMLElement>
    private val compId = uuid("")
    suspend fun init(rootEl:HTMLElement, logo:String, isImage:Boolean=false, subTitle:String="", logoClickBlock:(()->Unit)? = null){
        target = eView(viewFactory){
            it.sub(K.logo){ logoView->
                if(isImage){
                    logoView.className = "logo-image"
                    logoView.lazyBackgroundImage = "" to logo
                } else{
                    logoView.className = "logo-title"
                    logoView.html = logo
                }
                logoView.cursor = "default"
                logoClickBlock?.also{
                    logoView.cursor = "pointer"
                    logoView.click = {_,_-> it() }
                }
            }
            it.sub(K.subTitle).displayNone()
            it.sub(K.menuList)
            it.sub(K.user, userFactory){ user ->
                M42App.emptySub(user, K.user_info, K.user_profile, K.user_role, K.user_title, K.user_subTitle, K.user_menuList)
            }
        }
        target.setTemplate()
        PageDummy.invoke(target, "menu")
        rootEl.appendChild(target.template!!)
    }
    suspend fun addHook(key:String){
        this.key = key
        setUser()
        setList()
    }
    suspend fun setSubTitle(subTitle: String){
        target.sub("subTitle"){ st ->
            st.displayBlock()
            st.html = subTitle
        }
    }
    suspend fun setUserHide(){
        target.sub(K.user).displayNone()
        target.sub(K.menuList).top = "114px"
    }
    suspend fun setUser(){
        eWindow.addClick(compId){ eLaunch{ menuListClose() } }
        menuUser?.also{ userData ->
            target.sub(K.user){ user->
                val menuList = user.sub(K.user_menuList)
                user.sub(K.user_info){
                    it.selected = false
                    it.className = "user-info flex-center"
                    it.click = {e,_->
                        e.stopPropagation()
                        e.stopImmediatePropagation()
                        it.selected = it.selected != true
                        if(it.selected == true){
                            it.className = "user-info flex-center active"
                            menuList.displayBlock()
                        }else{
                            it.className = "user-info flex-center"
                            menuList.displayNone()
                        }
                    }
                }
                user.sub(K.user_profile){ it.lazyBackgroundImage = "" to userData.profileUrl}
                user.sub(K.user_role){
                    it.lazyBackgroundImage = "" to userData.roleUrl
                    if(userData.roleUrl.isBlank()) it.displayNone() else it.displayBlock()
                }
                user.sub(K.user_title){ it.html = userData.title}
                user.sub(K.user_subTitle){ it.html = userData.subTitle }

                menuList.setClearList{ vList ->
                    userData.menuList.forEach{ userMenuData ->
                        vList += eView(userMenuFactory){ menu ->
                            menu.sub(K.user_menu_item).click = { e,_->
                                e.stopPropagation()
                                e.stopImmediatePropagation()
                                eLaunch{
                                    menuListClose()
                                    userMenuData.clickBlock()
                                }
                            }
                            menu.sub(K.user_menu_icon){
                                if(userMenuData.icon.isNotBlank()){
                                    it.displayBlock()
                                    it.image = userMenuData.icon
                                }else{
                                    it.displayNone()
                                }
                            }
                            menu.sub(K.user_menu_title).html = userMenuData.title
                        }
                    }
                }
            }
        }
    }
    private suspend fun menuListClose(){
        target.sub(K.user){ user ->
            user.sub(K.user_info){
                it.selected = false
                it.className = "user-info flex-center"
            }
            user.sub(K.user_menuList).displayNone()
        }
    }
    private var unfoldList = mutableListOf<String>()

    suspend fun setList(){
        sessionStorage.setItem("lnbFold", unfoldList.joinToString(","))
        sessionStorage.getItem("lnbFold")?.also {
            unfoldList = it.split(",").toMutableList()
        }
        menuList.forEach { menuData ->
            val total = menuData.subList.sumOf { it.cnt.toIntOrNull()?:0 }
            if(total != 0 && isInit && menuData.foldable) {
                unfoldList.add(menuData.key)
            }
        }
        unfoldList = unfoldList.distinct().toMutableList()

//        menuList.forEach { menuData ->
//            val total = menuData.subList.sumOf { it.cnt.toIntOrNull()?:0 }
//            if(total != 0 && menuData.foldable) {
//                unfoldList.add(menuData.key)
//                menuData.unfolded = true
//            } else {
//                unfoldList.remove(menuData.key)
//                menuData.unfolded = false
//            }
//        }
//        unfoldList = unfoldList.distinct().toMutableList()
//        sessionStorage.setItem("lnbFold", unfoldList.joinToString(","))

        target.sub(K.menuList).setClearList{ mutableListList->
            menuList.forEach{ menuData ->
                if(menuData.foldable) {
                    menuData.unfolded = unfoldList.contains(menuData.key)
                } else {
                    menuData.unfolded = true
                }
                mutableListList += eView(menuFactory) { menuView ->
                    menuView.sub(K.menuWrap).className = menuData.menuWrapClassName
                    menuView.sub(K.menu_titleWrap){
                        if(menuData.title.isEmpty()) it.displayNone() else it.displayFlex()
                        if(menuData.foldable) {
                            it.className = "flex-between-center cursor"
                            it.click = { _,_->
//                                menuData.unfolded = !menuData.unfolded
                                if(menuData.unfolded) {
                                    unfoldList.remove(menuData.key)
                                } else {
                                    unfoldList.add(menuData.key)
                                }
                                unfoldList = unfoldList.distinct().toMutableList()

                                sessionStorage.setItem("lnbFold", unfoldList.joinToString(","))
                                isInit = false
                                eLaunch {
                                    setList()
                                }
                            }
                        } else {
                            it.className = "flex-between-center"
                        }
                    }
                    menuView.sub(K.menu_title).html = menuData.title
                    menuView.sub(K.menu_cnt) {
                        if(menuData.displayTotal) {
                            val total = menuData.subList.sumOf { it.cnt.toIntOrNull()?:0 }
                            if(total == 0) {
                                it.displayNone()
                            } else {
                                it.displayInlineBlock()
                                it.html = total.toString()
                            }
                        }
                        else it.displayNone()
                    }
                    menuView.sub(K.menu_urlIcon){ urlIconView->
                        menuData.clickBlock?.also{
                            urlIconView.displayBlock()
                            urlIconView.click = {_,_ -> it() }
                            urlIconView.lazyBackgroundImage = "" to menuData.urlIcon
                        } ?:also{
                            urlIconView.displayNone()
                            urlIconView.click = {_,_ ->}
                        }
                    }
                    menuView.sub(K.menu_foldBtn) {
                        if(menuData.foldable) {
                            it.displayBlock()
                            it.className = if(menuData.unfolded) "ic-fold" else "ic-unfold"
                        } else {
                            it.displayNone()
                        }
                    }

                    menuView.sub(K.menu_info){
                        if(menuData.info.isBlank()) it.displayNone() else it.displayBlock()
                        it.html = menuData.info
                    }
                    menuView.sub(K.menu_subList){
                        if(menuData.unfolded) it.displayBlock() else it.displayNone()
                        it.setClearList{
                            menuData.subList.forEach{ subData->
                                it += eView(menuSubFactory){ subView->
                                    subView.sub(K.menu_sub_item){
                                        subData.isSelect = subData.key == key
                                        it.disabled = subData.clickBlock == null
                                        it.className = "flex sub-item${if(it.disabled == true) " disabled" else if(subData.isSelect) " active" else ""}"
                                        if(it.disabled == false) it.click = {_,_ ->
                                            subData.clickBlock?.invoke()
                                        }
                                    }
                                    subView.sub(K.menu_sub_title).html = subData.title
                                    subView.sub(K.menu_sub_new){
                                        if(subData.isNew) it.displayInlineBlock() else it.displayNone()
                                    }
                                    subView.sub(K.menu_sub_cnt){
                                        it.html = subData.cnt
                                        if(subData.cnt.isNotBlank()) it.displayInlineBlock() else it.displayNone()
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    suspend fun dummyOn() = target.entity(EntDummyOn)
    suspend fun dummyOff() = target.entity(EntDummyOff)
}