package ein2b.core.date

import kotlin.js.Date
@JsName("require")
external fun requireJS(path:String):dynamic

object einDayJs {
    val dayjs = requireJS("dayjs")
    val utc = requireJS("dayjs/plugin/utc")
    val timezone = requireJS("dayjs/plugin/timezone")

    val intlZoneList: dynamic = js("""Intl.supportedValuesOf('timeZone')""")

    init {
        dayjs.extend(utc)
        dayjs.extend(timezone)
    }
}

actual fun eLocalDateTime.Companion.now(): eLocalDateTime =
    Date().let { jsDate ->
        eLocalDateTime(
            jsDate.getFullYear(), jsDate.getMonth() + 1, jsDate.getDate(),
            jsDate.getHours(), jsDate.getMinutes(), jsDate.getSeconds(), jsDate.getMilliseconds()
        )
    }

actual fun eLocalDateTime.toUtc(zone: String): eUtc {
    if(!eZonedDateTime.checkZone(zone)) throw IllegalArgumentException("Invalid time zone string: ${zone}")

    val altZone =
        if(!eZonedDateTime.supportedBySystem(zone)) {
            // dayjs가 지원하는 타임존이 아닌 경우 대체 시간으로 변경 후 처리
            if (zone == "Asia/Kathmandu") {
                // localdatetime - 5:45임
                var min = this.minute - 45
                val timediff = if(min < 0) { min+=60; -1 } else { 0 }
                var time = this.hour - 5 + timediff
                val datediff = if(time < 24) { time+=24; -1 } else { 0 }
                if(datediff==-1) {
                    // 시간이 변경되면서 날짜가 바뀌는데 날짜 계산은 복잡하므로
                    // dayjs에 맡기자
                    val u = einDayJs.dayjs.utc("${year}-${month}-${date} ${time}:${min}:${second}").add(-1,"day")
                    return eUtc(
                        u.year() as Int, u.month() as Int + 1, u.date() as Int,
                        time, min, second, millisecond
                    )
                } else {
                    return eUtc(year,month,date,time,min,second,millisecond)
                }
            } else {
                eZonedDateTime.altZone(zone)
            }
        } else {
            zone
        }

    val u = einDayJs.dayjs.tz(this.toString(), altZone).utc()
    return eUtc(
        u.year() as Int, u.month() as Int + 1, u.date() as Int,
        u.hour() as Int, u.minute() as Int, u.second() as Int, u.millisecond() as Int
    )
}

actual fun eUtc.Companion.now(): eUtc =
    Date().let { jsDate ->
        eUtc(
            jsDate.getUTCFullYear(), jsDate.getUTCMonth() + 1, jsDate.getUTCDate(),
            jsDate.getUTCHours(), jsDate.getUTCMinutes(), jsDate.getUTCSeconds(), jsDate.getUTCMilliseconds()
        )
    }

actual fun eUtc.toLocalDateTime(zone: String): eLocalDateTime {
    if(!eZonedDateTime.checkZone(zone)) throw IllegalArgumentException("Invalid time zone string: ${zone}")

    val altZone =
        if(!eZonedDateTime.supportedBySystem(zone)) {
            // dayjs가 지원하는 타임존이 아닌 경우 대체 시간으로 변경 후 처리
            if (zone == "Asia/Kathmandu") {
                // UTC + 5:45임
                var min = this.minuteUTC + 45
                val timediff = if(min >= 60) { min-=60; 1 } else { 0 }
                var time = this.hourUTC + 5 + timediff
                val datediff = if(time >= 24) { time-=24; 1 } else { 0 }
                if(datediff==1) {
                    // 시간이 변경되면서 날짜가 바뀌는데 날짜 계산은 복잡하므로
                    // dayjs에 맡기자
                    val u = einDayJs.dayjs.utc("${yearUTC}-${monthUTC}-${dateUTC} ${time}:${min}:${secondUTC}").add(1,"day")
                    return eLocalDateTime(
                        u.year() as Int, u.month() as Int + 1, u.date() as Int,
                        time, min, secondUTC, millisecondUTC
                    )
                } else {
                    return eLocalDateTime(yearUTC,monthUTC,dateUTC,time,min,secondUTC,millisecondUTC)
                }
            } else {
                eZonedDateTime.altZone(zone)
            }
        } else {
            zone
        }

    val l = einDayJs.dayjs(this.toString()).tz(altZone)
    return eLocalDateTime(
        l.year() as Int, l.month() as Int + 1, l.date() as Int,
        l.hour() as Int, l.minute() as Int, l.second() as Int, l.millisecond() as Int
    )
}

actual fun eUtc.timeInMilli(): Long =
    Date(Date.UTC(
        yearUTC, monthUTC - 1, dateUTC, hourUTC, minuteUTC, secondUTC, millisecondUTC
    )).getTime().toLong()