package ein2b.core.net

import ein2b.core.entity.eEntity
import ein2b.core.log.log
import ein2b.core.net.eApi.CollectType
import kotlinx.coroutines.flow.FlowCollector
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.flow


class eApi(private val key:String = "",  vararg arg:Pair<eApiMode, eApiInfo>){
    private val def = arg.toMap()
    fun interface CollectType {
        operator fun invoke():String
    }
    interface Sender {
        suspend operator fun invoke(request:eRequest):eResponse
    }
    interface Stream {
        suspend operator fun invoke(request:eRequest, flow: FlowCollector<eApiFlowData>, type: CollectType, info: eApiInfo)
    }
    companion object{
        private val EMPTY_SENDER = object:Sender{
            override suspend fun invoke(request: eRequest): eResponse {
                return object :eResponse(request, null){
                    override val state = 0
                    override fun header(k: String) = ""
                    override suspend fun text() = null
                    override suspend fun bytes() = null
                    override suspend fun any(type: String) = null
                }
            }
        }
        var sender = EMPTY_SENDER
        var stream = object :Stream{
            override suspend fun invoke(
                request: eRequest,
                flow: FlowCollector<eApiFlowData>,
                type: CollectType,
                info: eApiInfo
            ) {
            }
        }
        val TEXT = CollectType { "TEXT" }
        val BYTE = CollectType { "BYTE" }
        val GET = eApiMethod{"GET"}
        val POST = eApiMethod{"POST"}
        val HEAD = eApiMethod{"HEAD"}
        val PUT = eApiMethod{"PUT"}
        val DELETE = eApiMethod{"DELETE"}
        val CONNECT = eApiMethod{"CONNECT"}
        val OPTIONS = eApiMethod{"OPTIONS"}
        val TRACE = eApiMethod{"TRACE"}
        val PATCH = eApiMethod{"PATCH"}
        val DEFAULT = eApiMode{"@default"}
        var mode = DEFAULT
        private val prefix = hashMapOf<eApiMode, String>()
        private val suffix = hashMapOf<eApiMode, String>()
        fun prefix(mode: eApiMode, v:String){prefix[mode] = v}
        fun suffix(mode: eApiMode, v:String){suffix[mode] = v}
        internal val EMPTYLIST = listOf<String>()
        private fun fail(err:String) = eApiResult(false, null, err)
        suspend fun getText(url:String, vararg items:Pair<String, Any>):String?{
            return getOnce(url, items, eResponseTask.Text)
        }
        suspend fun <T: eEntity>getEntity(url:String, entity:()->T, vararg items:Pair<String, Any>):T?{
            return getOnce(url, items, eResponseTask.Entity(entity))
        }
        suspend fun getByte(url:String, vararg items:Pair<String, Any>):ByteArray?{
            return getOnce(url, items, eResponseTask.Byte)
        }
        private suspend fun<T> getOnce(url:String, items:Array<out Pair<String, Any>>, task: eResponseTask):T?{
            val result = eApi("", mode to eApiInfo {
                method = GET
                this.url = url
                if (items.isNotEmpty()){
                    this.items += items.map { it.first }
                    requestTask += eRequestTask.Form(*this.items.toTypedArray())
                }
                responseTask += task
            }).invoke(*items)
            //log("getOnce:$url, ${result.isOk}, ${result.err}")
            @Suppress("UNCHECKED_CAST")
            return if(result.isOk) result.response?.result as? T else null
        }
        suspend fun postText(url:String, requestEntity: (()-> eEntity)? = null):String?{
            return postOnce(url, requestEntity, eResponseTask.Text)
        }
        suspend fun <T: eEntity>postEntity(url:String, entity:()->T, requestEntity: (()-> eEntity)? = null):T?{
            return postOnce(url, requestEntity, eResponseTask.Entity(entity))
        }
        suspend fun postByte(url:String, requestEntity: (()-> eEntity)? = null):ByteArray?{
            return postOnce(url, requestEntity, eResponseTask.Byte)
        }
        private suspend fun<T> postOnce(url:String, requestEntity:(()-> eEntity)?, task: eResponseTask):T?{
            val net = eApi("", mode to eApiInfo {
                method = POST
                this.url = url
                requestEntity?.let{
                    this.items += "req"
                    requestTask += eRequestTask.JsonFromEntity("req")
                }
                responseTask += task
            })
            val result = requestEntity?.let{net("req" to it)} ?: net()
            @Suppress("UNCHECKED_CAST")
            return if(result.isOk) result.response?.result as? T else null
        }
    }
    suspend operator fun invoke(vararg items:Pair<String, Any>): eApiResult {
        if(sender === EMPTY_SENDER) return fail("no sender")
        val info = def[mode] ?: return fail("no mode data: $mode in $key")
        val request = eRequest("${prefix[mode] ?: ""}${info.url}${suffix[mode] ?: ""}", info.method())
        request.credentials = info.credentials
        info.items.let{
            if(items.size != it.size){
                items.forEach{ (k,_)-> log("== items:$k") }
                it.forEach{ log("-- info.items:$it") }
                return fail("invalid arg-requestItem count: items(${items.size}), info.items(${it.size})")
            }
            items.forEach{ (k, v)->
                if(k !in it) return fail("no request item:$k")
                request.items[k] = v
            }
        }
        info.requestTask.forEach{
            if(!it.run(request)) return fail("request task stop:${it::class}")
        }
        val response = request.send()
        response.error?.let {return eApiResult(false, response, it) }
        info.responseTask.all{
            if(!it.run(response)) {
                if(!it.isStop) return eApiResult (false, response, "error response task:$it")
                else false
            }else true
        }
        return eApiResult(true, response, "")
    }

//    suspend fun collect(type:CollectType, vararg items:Pair<String, Any>, block:suspend (value: eApiFlowData) -> Unit){
//        flow{
//            val info = def[mode]
//            if(info == null) check(false){"no mode data: $mode in $key"}
//            else{
//                val request = eRequest("${prefix[mode] ?: ""}${info.url}${suffix[mode] ?: ""}", info.method())
//                info.items.let{
//                    if(items.size != it.size){
//                        check(false){"invalid arg-requestItem count:arg:${items.size}, api:${it.size}"}
//                    }else {
//                        items.any { (k, v) ->
//                            if (k !in it){
//                                check(false){"no request item:$k"}
//                                false
//                            }else{
//                                request.items[k] = v
//                                true
//                            }
//                        }
//                    }
//                }
//                info.requestTask.any{
//                    if(!it.run(request)){
//                        check(false){"request task stop:$it"}
//                        false
//                    }else true
//                }
//                request.stream(this, type, info)
//            }
//
//        }.collect(block)
//    }
}