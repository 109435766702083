import app.Factory
import comp.*
import comp.input.*
import ein2b.core.core.err
import ein2b.core.view.*
import org.w3c.dom.HTMLElement


class CompLabelInputSection2<OUT>:Comp, CompValueOut<OUT>{
    companion object{
        //language=html
        private fun factory(className:String) = Factory.html("""
<div class="$className">
    <label data-view="label" class="input-block-label"></label>
    <b data-view="inputSection"></b>
</div>""")
        //language=html
        private fun inLineFactory(className:String) = Factory.html("""
<div class="flex $className vertical-top">
    <label data-view="label" class="input-inline-label flex-shrink-0" style="margin-top:9px"></label>
    <b data-view="inputSection"></b>
</div>""")
        suspend operator fun <OUT> invoke(
            root:eView<HTMLElement>, subKey:Any, label:String, isInline:Boolean = false, width:Int = 100,
            sectionClass:String = "input-section", wrapperClass:String = "margin-top20",
            isOneError:Boolean = false, input:()->List<CompInput<*,*,*>>,
        ):CompLabelInputSection2<OUT>{
            val comp = CompLabelInputSection2<OUT>()
            comp.target = root.sub(subKey, if(isInline) inLineFactory(wrapperClass) else factory(wrapperClass)){ subView->
                subView.sub("label"){
                    it.html = label
                    it.className = if(isInline) "input-inline-label flex-shrink-0" else "input-block-label"
                    if(isInline) it.width = "${width}px"
                }
                comp.inputSection = subView.compInputSectionSet("inputSection", "flex-grow-1", "form-error", sectionClass, isOneError, input)
            }
            comp.baseProp(root.sub(subKey), true)
            return comp
        }
    }
    lateinit var target:eView<HTMLElement>
    lateinit var inputSection:CompInputSection<OUT>
    suspend fun setLabel(label:String){ target.sub("label").html = label }
    override suspend fun clear() = inputSection.clear()
    override fun check() = inputSection.check()
    suspend fun changeError(msg:String, ok:Boolean = false) = inputSection.changeError(ok, msg)
    fun displayBlock(){ target.displayBlock() }
    fun displayNone(){ target.displayNone() }
    override val outs:HashMap<OutType, suspend () -> OUT> = hashMapOf(OutType.DEFAULT to { inputSection.out() })
}

suspend fun <OUT> eView<HTMLElement>.compLabelInputSection2Set(
    subKey:Any, label:String, isInline:Boolean = false, width:Int = 100,
    sectionClass:String = "input-section",
    sectionWrapperClass:String = "margin-top20", isOneError: Boolean = false, input:()->List<CompInput<*, *, *>>,
) = CompLabelInputSection2<OUT>(this, subKey, label, isInline, width, sectionClass, sectionWrapperClass, isOneError, input)
@Suppress("UNCHECKED_CAST")
inline fun <OUT> eView<HTMLElement>.compLabelInputSection2(block:(CompLabelInputSection2<OUT>)->Unit = { }):CompLabelInputSection2<OUT>{
    val comp = this["compLabelInputSection2_value"] as? CompLabelInputSection2<OUT> ?: err("fail to get compLabelInputSection2")
    block(comp)
    return comp
}