package view

import app.Factory
import ein2b.core.view.*
import org.w3c.dom.HTMLElement

object CompViewBlocking{
    lateinit var target:eView<HTMLElement>
    suspend fun init(rootEl:HTMLElement){
        val f = Factory.html("""<section id="blocking" class="fixed-block" style="background:rgba(0,0,0,0)"></section>""")
        target = eView(f){ it.displayNone() }
        target.setTemplate()
        rootEl.appendChild(target.template!!)
    }
    fun open(){ target.displayBlock() }
    fun close(){ target.displayNone() }
}