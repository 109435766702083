package ein2b.core.validation

typealias routeF = (Any, Any?)->Boolean

class eRuleDSL internal constructor(){
    internal val list = mutableListOf<eRuleCase>()
    fun Case(block:eRuleCase.()->Unit) {
        list += eRuleCase().apply(block)
    }
}

class eRuleRouteDSL internal constructor(){
    internal val list = mutableListOf<Pair<routeF, eRuleCase>>()
    fun Case(check:routeF, block:eRuleCase.()->Unit) {
        list += check to eRuleCase().apply(block)
    }
}


