package comp

import ein2b.core.view.eView
import org.w3c.dom.HTMLElement

interface Comp {
    fun compName(isCompValue:Boolean = false):String{
        val propName = "${this::class.simpleName!!}${if(isCompValue)"_value" else ""}"
        return propName[0].lowercase() + propName.substring(1)
    }
    fun baseProp(it: eView<HTMLElement>, isCompValue:Boolean = false){
        val propName = compName(isCompValue)
        //console.log("propName:$propName")
        it[propName] = this
    }
}
enum class OutType{
    DEFAULT,
    WITH_LOG;
}
interface CompValueOut<OUT> {
    val outs:HashMap<OutType, suspend ()->OUT>
    suspend fun out(key:OutType = OutType.DEFAULT):OUT{
        return outs[key]!!()
    }
    suspend fun out(key:OutType = OutType.DEFAULT, block:suspend ()->OUT) {
        outs[key] = block
    }
    suspend fun clear()
    fun check():Boolean
}