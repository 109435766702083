package comp.input

import app.Factory
import ein2b.core.core.err
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import org.w3c.dom.DragEvent
import org.w3c.dom.HTMLElement
import org.w3c.files.FileList
import org.w3c.files.get

//파일 업로드 & 드래그 컴포넌트
class CompUpDownFileDrag:CompInputFile(){
    enum class K{ infoMsg, btnWrap }
    companion object{
        //language=html
        private val FACTORY = Factory.html("""
        <div data-view="${InputFileKey.wrap}">
            <b data-view="${InputFileKey.input}"></b>   
            <a data-view="${InputFileKey.download}" style="display:none"></a> 
            <div data-view="${InputFileKey.uploadSection}" class="view-border flex-center">
                <img style="width:90px" src="//s3.ap-northeast-2.amazonaws.com/m42resource/m42_comp3/2023/05/08/9516949b1ee8b636472a7329af403823.svg" alt="">
                <div class="margin-left30">
                    <div data-view="${K.infoMsg}" class="margin-bottom12"></div>       
                    <div data-view="${K.btnWrap}" class="flex-center btn border">
                        <div data-view="${InputFileKey.btnIcon}" class="ic-upload margin-right6"></div><span data-view="${InputFileKey.btnLabel}"></span>
                    </div>
                </div>
            </div>
            <ul data-view="${InputFileKey.fileList}"></ul>
        </div>""")
        operator fun invoke(infoMsg:String, btnLabel:String, block:(CompUpDownFileDrag)->Unit):CompUpDownFileDrag{
            val comp = CompUpDownFileDrag()
            comp.infoMsg = infoMsg
            comp.btnLabel = btnLabel
            block(comp)
            return comp
        }
    }
    override val factory:suspend () -> HTMLElement = FACTORY

    override suspend fun init(it:eView<HTMLElement>){
        target = it
        (dragView ?: target.sub(InputFileKey.uploadSection)).also{
            it.dragover = {e,_->
                e.preventDefault()
                it.className = uploadSectionClassName("dragover")
            }
            it.dragleave = {e,_->
                e.preventDefault()
                it.className = uploadSectionClassName("")
            }
            it.drop = {e, _->
                it.className = uploadSectionClassName("")
                e.preventDefault()
                (e as? DragEvent)?.also{
                    e.dataTransfer?.files?.also{ v->
                        if(uploadMaxSize == 1) setFile(v[0]!!)
                        else setFileList(v)
                    }
                }
            }
        }
        target.sub(InputFileKey.wrap){
            it.attr("data-filekey", key)
            it.className = wrapperClass.ifBlank{ "" }
        }
        inputSet()
        target.sub(InputFileKey.uploadSection).displayFlex()
        target.sub(K.infoMsg).html = infoMsg

        target.sub(InputFileKey.download)
        target.sub(K.btnWrap){
            it.displayInlineFlex()
            it.click = { _,_-> if(!btnIsDisabled) inputFile.template?.click() }
        }
        target.sub(InputFileKey.btnIcon).className = iconClassName
        target.sub(InputFileKey.btnLabel){
            it.html = btnLabel
        }
    }

    override suspend fun uploadSectionDisplay(v:Boolean){
        target.sub(InputFileKey.uploadSection){
            if(v) it.displayFlex() else it.displayNone()
        }
    }
    override suspend fun changeValue(){
        clear()
        errorListenerClear?.invoke()
        if(!isChangeSubmit){
            target.sub(InputFileKey.fileList).className = if(uploadMaxSize == 1){
                uploadSectionDisplay(false)
                ""
            }else "margin-top15"
            fileSetList()
        }
        if(fileList.isEmpty()){
            value.inputValue("")
            uploadSectionDisplay(true)
        }
        else if(uploadMaxSize == 1) value.inputValue(fileList[0])
        else value.inputValue(fileList)
    }

    var infoMsg:String = ""
    var dragView:eView<HTMLElement>? = null
    private fun uploadSectionClassName(v:String) = "view-border flex-center${if(v.isNotBlank()) " $v" else ""}"
}
// ============================ prop ============================
inline fun eView<HTMLElement>.compUpDownFileDrag(block:(CompUpDownFileDrag)->Unit = {}):CompUpDownFileDrag{
    val comp = this["compUpDownFileDrag_value"] as? CompUpDownFileDrag ?: err("fail to get CompUpDownFileDrag")
    block(comp)
    return comp
}