package comp.input

import app.Factory
import comp.OutType
import comp.input.CompInput.Companion.KEYCODE
import comp.input.CompInput.Companion.KEYCODE_DEFAULT
import comp.input.CompInput.Companion.valueNumber
import ein2b.core.core.err
import ein2b.core.view.eView
import ein2b.core.view.keydown
import ein2b.core.view.keyup
import ein2b.core.view.value
import ein2b.js.dom.eEvent
import ein2b.js.js.numberFormat
import org.w3c.dom.HTMLElement

class CompInputPrice:CompInputSingle<String>(){
    companion object{
        operator fun invoke(block:(CompInputPrice)->Unit):CompInputPrice{
            val comp = CompInputPrice()
            block(comp)
            comp.maxLength = "${comp.maxValue}".length
            comp.afterTargetInited = {
                comp.target.keydown = { e,el->
                    val ev = eEvent(e, el)
                    val keyCode = ev.keycode()
                    val numStr = valueNumber(ev.value)
                    if(keyCode !in KEYCODE || (comp.maxLength > -1 && numStr.length >= comp.maxLength && ev.keycode() !in KEYCODE_DEFAULT)){
                        e.stopImmediatePropagation()
                        ev.prevent()
                    }
                }
                comp.target.keyup = comp.numberKeyUpEvent
                comp.value = CompValue("", "", comp.vali, comp.errorListener, CompInput.CONV){
                    var numStr = valueNumber(it.trim())
                    try{
                        val num = numStr.toLong()
                        numStr = if(num < comp.minValue) "${comp.minValue}"
                            else if(comp.maxValue < num) "${comp.maxValue}"
                            else "$num"
                    }catch(e:Throwable){}
                    comp.target.value = numberFormat(numStr)
                }
            }
            return comp
        }
    }
    override var subKey:String = "CompInputPrice_input"
    override val factory:suspend ()-> HTMLElement = Factory.html("""<input data-view="$subKey" type="text">""")
    var minValue:Long = 0
    var maxValue:Long = Long.MAX_VALUE
    override val outs:HashMap<OutType, suspend () -> String> = hashMapOf(OutType.DEFAULT to { valueNumber(value.value) })
}

// ============================ prop ============================
inline fun eView<HTMLElement>.compInputPrice(block:(CompInputPrice)->Unit = {}):CompInputPrice{
    val comp = this["compInputPrice_value"] as? CompInputPrice ?: err("fail to get CompInputPrice")
    block(comp)
    return comp
}