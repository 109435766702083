package inputData

import app.PageBase
import ein2b.core.cdata.eCdataLoader
import ein2b.core.entity.eEntity
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import org.w3c.dom.HTMLElement

class InputDataP(router:eRouter<HTMLElement>):PageBase(::InputDataView, router){
    override suspend fun shown(data:eRouterData){
        //language=json
        val cdataText = """
{
   "default":{
      "co":"sg",
      "cu":"SGD",
      "ln":"ko"
   },
   "data":{
      "inputData/addMsg":{
         "cat":"ln",         
         "default":"ko",
         "data":{
            "ko":{
               "value":"+ @v0@ 추가"
            }
         }
      }
   }
}
        """.trimIndent()
        eEntity.parse(eCdataLoader(), cdataText)?.load()
    }
}