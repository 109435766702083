package inputData

import app.Factory
import comp.CompPageTitle
import comp.compInputSectionOneSet
import comp.input.*
import ein2b.core.coroutine.eLaunch
import ein2b.core.entity.eEntity
import ein2b.core.validation.eRuleVali
import ein2b.core.view.click
import ein2b.core.view.eEntityHook
import ein2b.core.view.eView
import entity.EntHook
import m42.model.HcInit
import org.w3c.dom.HTMLElement
import prop.compInputSection
import prop.compInputSectionString
import prop.compLabelInputSection
import view.CompViewAlert

private enum class K{
    input, reRender, error, submit,
    view1, view2, view3, view4, view5
}
private val factory = Factory.htmlUrl("inputData")
suspend fun InputDataView() = eView(factory){ rootView ->
    CompPageTitle(rootView){ compTitle ->
        compTitle.title = "검색"
    }
    val vali1 = eRuleVali{
        Case{
            String()
            Trim()
            MinLength(1, "선택해 주세요.")
        }
    }
    class MdlAirport:eEntity(){
        var rowid by string()
        var code by string()
        var name by string()
    }
    val entList = mutableListOf(
        MdlAirport().also {
            it.rowid = "1"
            it.code = "ICN"
            it.name = "Incheon International Airport"
        },
        MdlAirport().also {
            it.rowid = "2"
            it.code = "KAG"
            it.name = "Gangneung"
        },
        MdlAirport().also {
            it.rowid = "3"
            it.code = "CHN"
            it.name = "Jeonju"
        },
        MdlAirport().also {
            it.rowid = "4"
            it.code = "RSU"
            it.name = "Yeosu Airport"
        },
        MdlAirport().also {
            it.rowid = "5"
            it.code = "CJU"
            it.name = "Jeju International Airport"
        },
        MdlAirport().also {
            it.rowid = "6"
            it.code = "KWJ"
            it.name = "Gwangju Airport"
        },
        MdlAirport().also {
            it.rowid = "7"
            it.code = "GMP"
            it.name = "Gimpo International Airport"
        }
    )
    fun airportInputData(airport:MdlAirport) = CompInputDataBase.InputData(
        airport, airport.rowid, listOf(
            airport.code to true,
            "<br>" to false,
            airport.name to true
        ),
        "(${airport.code}) ${airport.name}"
    )

    rootView.sub(K.view1){ viewView->
        var airport:MdlAirport? = null
        CompInputDataMandatory<MdlAirport>{ comp->
            comp.wrapperClass = "width5-1"
            comp.isClickAllDataOpen = true
            comp.maxLength = 5
            comp.emptyMsg = "검색 결과 없음"
            comp.addEvent = { v->
                airportInputData(MdlAirport().also{
                    it.rowid = "${entList.last().rowid.toLong() + 1}"
                    it.code = v
                    it.name = "ADD Event $v"
                })
            }
            comp.addMsg = "c@inputData/addMsg"
            comp.dataList = entList.map{ airportInputData(it) }.toMutableList()
            comp.itemClickBlock = { v->
                airport = v
                console.log("itemClickBlock:", v)
                eLaunch{ viewView.sub(K.error).compError().update(true, "") }
            }
            comp.itemDeleteBlock = { v->
                console.log("itemDeleteBlock", v)
                /*M42ApiRsc.rsc("comp").also{
                    console.log("dataListDelete api end", v)
                }*/
            }
            comp.clearClick = {
                console.log("clearClick")
            }
            comp.vali = vali1
            comp.errorListener = { ok, msg->
                eLaunch{
                    comp.error(ok)
                    viewView.sub(K.error).compError().update(ok, msg)
                }
            }
        }.also{
            it.comp(viewView, K.input)
        }
        CompError("form-error").also{ it.comp(viewView, K.error) }
        viewView.sub(K.reRender).click = {_,_->
            eLaunch{
                viewView.sub(K.input).compInputDataMandatory<MdlAirport>{ comp->
                    airport?.also{
                        comp.mdl.selectedData = airportInputData(it)
                        comp.mdlRender()
                    }
                }
            }
        }
        viewView.sub(K.submit).click = {_,_->
            eLaunch{
                viewView.sub(K.input).compInputDataMandatory<MdlAirport>{ comp->
                    if(comp.check()) console.log(comp.out())
                }
            }
        }
    }
    rootView.sub(K.view2){ viewView->
        var airport:MdlAirport? = null
        viewView.compLabelInputSectionDataMandatorySet<MdlAirport>(K.input, "CompLabelInputSection", vali1, sectionWrapperClass = "", sectionClass = "input-section width5-1"){ comp->
            comp.dataList = entList.map{ airport->
                CompInputDataBase.InputData(
                    airport, airport.rowid, listOf("(${airport.code}) ${airport.name}" to true)
                )
            }.toMutableList()

            comp.sortType = CompInputDataBase.SortType.NONE
            comp.matchType = CompInputDataBase.MatchType.WORD
            comp.isReverse = true
            comp.initValue = comp.dataList.first().value
        }
        viewView.sub(K.reRender).click = {_,_->
            eLaunch{
                viewView.sub(K.input).compLabelInputSection<String>().inputView().compInputDataMandatory<MdlAirport>{ comp->
                    airport?.also{
                        comp.mdl.selectedData = airportInputData(it)
                        comp.mdlRender()
                    }
                }
            }
        }
        viewView.sub(K.submit).click = {_,_->
            eLaunch{
                viewView.sub(K.input).compLabelInputSection<String>{ comp->
                    if(comp.check()) console.log(comp.out())
                }
            }
        }
    }
    rootView.sub(K.view3){ viewView->
        var airport:MdlAirport? = null
        viewView.compInputSectionOneSet<String>(K.input, CompInputDataMandatory<MdlAirport>{ comp->
            comp.sortType = CompInputDataBase.SortType.NONE
            comp.matchType = CompInputDataBase.MatchType.WORD
            comp.emptyMsg = "검색 결과 없음"
            comp.vali = vali1
            comp.dataList = entList.map{ airport->
                CompInputDataBase.InputData(
                    airport, airport.rowid, listOf("(${airport.code}) ${airport.name}" to true)
                )
            }.toMutableList()
        }, wrapperClass = "width5-1")
        viewView.sub(K.reRender).click = {_,_->
            eLaunch{
                viewView.sub(K.input).compInputSectionString().inputView().compInputDataMandatory<MdlAirport>{ comp->
                    airport?.also{
                        comp.mdl.selectedData = airportInputData(it)
                        comp.mdlRender()
                    }
                }
            }
        }
        viewView.sub(K.submit).click = {_,_->
            eLaunch{
                viewView.sub(K.input).compInputSectionString{ comp->
                    if(comp.check()) console.log(comp.out())
                }
            }
        }
    }
    rootView.sub(K.view4){ viewView->
        var airport:MdlAirport? = null
        CompInputDataOptional<MdlAirport>{ comp->
            comp.dataList = entList.map{ airport->
                CompInputDataBase.InputData(
                    airport, airport.code, listOf(airport.code to true)
                )
            }.toMutableList()

            comp.isClickAllDataOpen = true
            comp.maxLength = 3
            comp.emptyMsg = "검색 결과 없음"
            comp.itemClickBlock = { v->
                airport = v
                console.log("itemClickBlock:", v)
                eLaunch{ viewView.sub(K.error).compError().update(true, "") }
            }
            comp.itemDeleteBlock = { v->
                console.log("itemDeleteBlock", v)
                /*M42ApiRsc.rsc("comp").also{
                    console.log("dataListDelete api end", v)
                }*/
            }
            comp.clearClick = {
                console.log("clearClick")
            }
            comp.vali = vali1
            comp.errorListener = { ok, msg->
                eLaunch{
                    comp.error(ok)
                    viewView.sub(K.error).compError().update(ok, msg)
                }
            }
        }.also{
            it.comp(viewView, K.input)
        }
        CompError("form-error").also{ it.comp(viewView, K.error) }
        viewView.sub(K.reRender).click = {_,_->
            eLaunch{
                viewView.sub(K.input).compInputDataOptional<MdlAirport>{ comp->
                    airport?.also{
                        comp.mdl.selectedData = airportInputData(it)
                        comp.mdlRender()
                    }
                }
            }
        }
        viewView.sub(K.submit).click = {_,_->
            eLaunch{
                viewView.sub(K.input).compInputDataOptional<MdlAirport>{ comp->
                    if(comp.check()) console.log(comp.out())
                }
            }
        }
    }
    rootView.sub(K.view5){ viewView->
        var airport:MdlAirport? = null
        viewView.compLabelInputSectionOptionalSet<MdlAirport>(K.input, "CompLabelInputSection", vali1, sectionWrapperClass = "", sectionClass = "input-section width5-1"){ comp->
            comp.dataList = entList.map{ airport->
                CompInputDataBase.InputData(
                    airport, airport.code, listOf(airport.code to true)
                )
            }.toMutableList()

            comp.sortType = CompInputDataBase.SortType.NONE
            comp.matchType = CompInputDataBase.MatchType.WORD
            comp.isReverse = true
            comp.initValue = comp.dataList.first().value
        }
        viewView.sub(K.reRender).click = {_,_->
            eLaunch{
                viewView.sub(K.input).compLabelInputSection<String>().inputView().compInputDataMandatory<MdlAirport>{ comp->
                    airport?.also{
                        comp.mdl.selectedData = airportInputData(it)
                        comp.mdlRender()
                    }
                }
            }
        }
        viewView.sub(K.submit).click = {_,_->
            eLaunch{
                viewView.sub(K.input).compLabelInputSection<String>{ comp->
                    if(comp.check()) console.log(comp.out())
                }
            }
        }
    }

    rootView.addEntityHook(EntHook::class, object:eEntityHook<HTMLElement, EntHook>{
        override suspend fun invoke(view:eView<HTMLElement>, entity:EntHook){

        }
    })
    rootView.addEntityHook(HcInit::class, object:eEntityHook<HTMLElement, HcInit>{
        override suspend fun invoke(view:eView<HTMLElement>, entity: HcInit){

        }
    })
}
